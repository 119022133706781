export const MarketingMediaTypeForDropdown = [
  {
    label: "Meta",
    value: "Meta",
  },
  {
    label: "Instagram",
    value: "Instagram",
  },
  {
    label: "LinkedIn",
    value: "LinkedIn",
  },
  {
    label: "YouTube",
    value: "YouTube",
  },
  {
    label: "Google Ads",
    value: "Google Ads",
  },
];
