/**
 * Main App
 */
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
//css
import "react-notifications/lib/notifications.css";
import "react-dropdown-tree-select/dist/styles.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "./libs/styles/index.css";
import "./libs/styles/debug.css";

//app component
import App from "./container/App";

import { configureStore } from "./store";
import { AppConfig } from "./constants";

const MainApp = () => (
  <Provider store={configureStore()}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </Router>
    </MuiPickersUtilsProvider>
  </Provider>
);

export default MainApp;
