import React from "react";
import { useState, useEffect } from "react";

function ListViewComponent({ oItems = [], singleItemComponent = null }) {
  const [state, setState] = useState({ items: [], page: 1 });
  const handleScroll = () => {
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    const scrollHeight =
      document.documentElement.scrollHeight || document.body.scrollHeight;

    if (window.innerHeight + scrollTop >= scrollHeight - 50) {
      loadMoreItems();
    }
  };
  useEffect(() => {
    console.log("component mounting ...");
    loadMoreItems();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const loadMoreItems = () => {
    const items = [...oItems];
    setState((prevState) => {
      const newItems = items.slice(
        (prevState.page - 1) * 6,
        prevState.page * 6
      );
      if (newItems.length > 0) {
        return {
          items: [...prevState.items, ...newItems],
          page: prevState.page + 1,
        };
      } else {
        return prevState;
      }
    });
  };
  return (
    <div>
      {state.items.map((item, index) => {
        return singleItemComponent({ item, index });
      })}
    </div>
  );
}

export default ListViewComponent;
