import axios from "../axios";

class WhatsappHelper {
  static async getState(deviceId, props) {
    const { setLoading, handleAppError, showMessage } = props;
    try {
      setLoading(true);
      await axios.get("whatsapp/get-state/" + deviceId);
      showMessage({
        type: "success",
        title: window.sys_app_translate("Success", props.user.lang),
        message: window.sys_app_translate("Device is Online!"),
      });
      setLoading(false);
    } catch (e) {
      handleAppError(e);
    }
  }
  static async getContacts(
    userId = null,
    props,
    deviceId = null,
    rawContactsOnly = false
  ) {
    const { setLoading, handleAppError } = props;
    try {
      setLoading(true);
      const response = await axios.get("whatsapp/get-contacts", {
        params: {
          userid: userId,
          deviceid: deviceId,
          returnrawcontacts: rawContactsOnly,
        },
      });
      setLoading(false);
      if (response.data.data.result) {
        return response.data.data.data;
      }
      return [];
    } catch (e) {
      handleAppError(e);
    }
  }
  static async checkContacts(props, deviceId = null) {
    const { setLoading, handleAppError } = props;
    try {
      setLoading(true);
      const response = await axios.get("whatsapp/check-contacts", {
        params: {
          deviceid: deviceId,
        },
      });
      setLoading(false);
      if (response.data.data.result) {
        return response.data.data.data;
      }
      return [];
    } catch (e) {
      handleAppError(e);
    }
  }
  static async getChats(
    userId = null,
    props,
    deviceId = null,
    wpdevicecontactid = null
  ) {
    const { setLoading, handleAppError } = props;
    try {
      setLoading(true);
      const response = await axios.get("whatsapp/get-contacts", {
        params: {
          userid: userId,
          deviceid: deviceId,
          contactid: wpdevicecontactid,
        },
      });
      setLoading(false);
      if (response.data.data.result) {
        console.log("getting only selected lead contact ...");
        return response.data.data.data;
      }
      return [];
    } catch (e) {
      handleAppError(e);
    }
  }
  static async getChat(
    contactId,
    userId = null,
    props,
    deviceId = null,
    rawChatOnly = false
  ) {
    const { setLoading, handleAppError } = props;
    try {
      setLoading(true);
      const response = await axios.post("whatsapp/get-chat-by-contact", {
        contactid: contactId,
        userid: userId,
        deviceid: deviceId,
        returnrawchat: rawChatOnly,
      });
      setLoading(false);
      if (response.data.data.result) {
        return response.data.data.data;
      }
      return [];
    } catch (e) {
      handleAppError(e);
    }
  }
}

export default WhatsappHelper;
