import GeneralHelper from "../libs/GeneralHelper";

const PusherConfig = {
  key: "f2bfcba08a4b57c01be1",
  cluster: "eu",
  channels: {
    kanbanChannel: {
      name: "kanban",
      eventName: "kanban_event",
    },
    whatsappChannel: {
      name: "whatsapp-in",
      eventName: "new_message",
    },
  },
};
const PusherTestConfig = {
  key: "32039b6f753508f48718",
  cluster: "eu",
  channels: {
    kanbanChannel: {
      name: "kanban",
      eventName: "kanban_event",
    },
    whatsappChannel: {
      name: "whatsapp-in",
      eventName: "new_message",
    },
  },
};
export default GeneralHelper.isOnline() ? PusherConfig : PusherTestConfig;
