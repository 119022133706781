export const KanbanEventType = {
  PhaseNew: "new-phase",
  PhaseOrderNoChanged: "phase-order-no-change",
  PhaseUpdated: "phase-updated",
  PhaseDeleted: "phase-deleted",
  LeadNew: "new-lead",
  LeadPhaseChanged: "lead-phase-changed",
  LeadOrderNoChanged: "lead-order-no-change",
  LeadResponsibleUserChanged: "lead-responsible-user-changed",
  LeadUpdated: "lead-updated",
  LeadDeleted: "lead-deleted",
  LeadLabelCreated: "lead-label-created",
  LeadLabelUpdated: "lead-label-updated",
  LeadLabelDeleted: "lead-label-deleted",
};
