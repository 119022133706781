import React, { useState, useEffect, useRef } from "react";
import { ContactItem, MessageElement, MessagingBox } from "./chat-components";
import { ChatSearchIcon, EmptyAvatar } from "../../constants/SVGIcons";
import Pusher from "pusher-js";
import axios from "../../axios";
import { GeneralHelper } from "../../libs";
import { PusherConfig } from "../../constants";
import { set } from "lodash";
import { Riple } from "react-loading-indicators";
import { Skeleton } from "@material-ui/lab";

const ChatComponent = ({
  leadName,
  currentUser,
  chatPhoneNo,
  getContacts = async () => {},
  getChats = async () => {},
  getChat = (contactId, userId) => {},
  sendMessage = (message) => {},
  userId = null,
  showMessage = (message) => {},
  lang,
}) => {
  const [loading, setLoading] = useState(false);
  const [activeContactIndex, setActiveContactIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [contacts, setContacts] = useState([]);
  const channel = useRef(null);
  const [messages, setMessages] = useState([]);
  const [replyToMessage, setReplyToMessage] = useState(null);
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const chatMessagesContainerRef = useRef(null);
  useEffect(() => {
    if (chatMessagesContainerRef.current) {
      chatMessagesContainerRef.current.scrollTop =
        chatMessagesContainerRef.current.scrollHeight;
    }
  }, [messages]);
  useEffect(() => {
    setLoading(true);
    getChats(userId).then((contacts) => {
      console.log("getting chat by user id: ", userId);
      if (contacts.length === 0) {
        showMessage({
          type: "warning",
          title: window.sys_app_translate("No contact(s) found", lang),
          message: window.sys_app_translate("No contact(s) found!", lang),
        });
        setLoading(false);
        return;
      }
      setContacts(contacts);
      getChat(contacts[activeContactIndex].wpcontactid, userId).then(
        (messages) => {
          setMessages(messages);
          setLoading(false);
        }
      );
    });
  }, []);
  const newMessageReceived = (data) => {
    // Update messages state with new message
    const message = data.message.message;
    if (
      message.wpchatid === contacts[activeContactIndex].wpcontactid &&
      message.userid === userId
    ) {
      setMessages((prevMessages) => [...prevMessages, message]);
    }
  };
  useEffect(() => {
    const pusher = new Pusher(PusherConfig.key, {
      cluster: PusherConfig.cluster,
    });
    pusher.connection.bind("connected", () =>
      console.log("subscribed to pusher")
    );
    pusher.connection.bind("connecting", () =>
      console.log("connecting to pusher")
    );
    pusher.connection.bind("connecting_in", () =>
      console.log("connecting_in pusher")
    );
    pusher.connection.bind("disconnected", () =>
      console.log("disconnected from pusher")
    );
    pusher.connection.bind("unavailable", () =>
      console.log("pusher unavailable")
    );
    pusher.connection.bind("error", (err) =>
      console.log({ err }, "pusher error")
    );
    pusher.connection.bind("failed", (err) =>
      console.log({ err }, "pusher failed")
    );
    channel.current = pusher.subscribe("whatsapp-in");
    channel.current.bind("new_message", (data) => {
      newMessageReceived(data);
    });
    return () => {
      console.log("unsubscribing from pusher");
      channel.current.unbind_all();
      channel.current.unsubscribe();
    };
  }, [contacts, activeContactIndex]);
  useEffect(() => {
    // getChat(contacts[activeContactIndex].id).then((messages) => {
    //   setMessages(messages);
    // });
  }, [contacts]);
  useEffect(() => {
    //subscribe to pusher
    //listen to new messages
    //update contacts messages
  }, [messages]);
  const getContactsList = () => {
    const filteredContacts = contacts.filter(
      (contact) =>
        contact.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        contact.phone.includes(searchQuery.toLowerCase())
    );
    return (
      <>
        {/* contacts list */}
        <div className="row">
          <div
            className="col-12 px-1"
            style={{ height: "500px", overflowY: "auto" }}
          >
            <div className="container-fluid">
              {filteredContacts.map((contact, index) => {
                return (
                  <ContactItem
                    contact={contact}
                    isActive={index === activeContactIndex}
                    key={contact.id + "contact"}
                    onClick={() => {
                      const contactIndex = contacts.findIndex(
                        (c) => c.id === contact.id
                      );
                      setActiveContactIndex(contactIndex);
                      getChat(contact.wpcontactid, userId).then((messages) => {
                        setMessages(messages);
                      });
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  };
  const getMessagesList = () => {
    return (
      <>
        <div className="chat-messages-container" ref={chatMessagesContainerRef}>
          {messages.map((message, index) => {
            if (message.replytomessageid) {
              message.repliedMessage = messages.find(
                (m) => m.messageid === message.replytomessageid
              );
            }
            return (
              <MessageElement
                key={message.id + "message"}
                type={message.chateventtype}
                content={message.messagebody}
                from={message.from}
                isFromUser={message.isfromuser}
                attachment={message.attachment}
                attachmentType={message.attachmenttype}
                timestamp={message.timestamp}
                chatPhoneNo={chatPhoneNo}
                contactPhone={contacts[activeContactIndex].phone}
                repliedMessage={message.repliedMessage}
                isThisTheRepliedMessage={false}
                isParentReplySet={replyToMessage}
                isSelected={message.wpmessageid === replyToMessage}
                setParentReply={() => {
                  setReplyToMessage(message.wpmessageid);
                }}
                unsetParentReply={() => {
                  setReplyToMessage(null);
                }}
              />
            );
          })}
        </div>
      </>
    );
  };
  const getSkeletonMessagesList = () => {
    return [0, 1, 2, 3].map((message, index) => {
      return (
        <div
          key={index}
          className={`py-2 d-flex align-items-start ${
            index % 2 === 0 ? "justify-content-start" : "justify-content-end"
          }`}
        >
          {index % 2 === 0 && (
            <>
              <Skeleton
                variant="rect"
                width={"3rem"}
                height={"3rem"}
                style={{ borderRadius: "50%", backgroundColor: "#e0e0e0" }}
              />
              <div className="mx-1"></div>
            </>
          )}

          <Skeleton
            variant="rect"
            width={"20rem"}
            height={"5rem"}
            animation="wave"
            style={{
              borderRadius: "1rem",
              marginBottom: "0.5rem",
              backgroundColor: "#e0e0e0",
            }}
          />
          {index % 2 !== 0 && (
            <>
              <div className="mx-1"></div>
              <Skeleton
                variant="rect"
                width={"3rem"}
                height={"3rem"}
                style={{ borderRadius: "50%", backgroundColor: "#e0e0e0" }}
              />
              <div className="mx-1"></div>
            </>
          )}
        </div>
      );
    });
  };
  return (
    <>
      <div className="row" style={{ background: "white" }}>
        <div className="col-3 px-0">
          <div className="container-fluid">
            <div className="row chat-searchbox-row">
              <div className="col-12 d-flex align-items-center">
                <ChatSearchIcon
                  style={{ marginLeft: "0.5rem", position: "absolute" }}
                />
                <input
                  type="text"
                  className="form-control chat-search-input"
                  placeholder={window.sys_app_translate("Search", "en")}
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
            {getContactsList()}
          </div>
        </div>
        <div className="col-9 px-0">
          <div className="row current-user-bar mb-1">
            <div className="col-12 px-0">
              <div className="container-fluid">
                <div
                  className="row py-2"
                  style={{
                    minHeight: "50px",
                  }}
                >
                  <div className="col-1">
                    <EmptyAvatar style={{ width: "3rem", height: "100%" }} />
                  </div>
                  <div className="col-8 px-0 d-flex align-items-center">
                    <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                      {leadName}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 px-0">
              <div className="container chat-content">
                {loading && <>{getSkeletonMessagesList()}</>}
                {getMessagesList()}
                <MessagingBox
                  sendMessage={(
                    message,
                    file = null,
                    isVoiceRecording = false
                  ) => {
                    if (file) {
                      const formData = new FormData();
                      if (file instanceof Blob) {
                        formData.append("file", file, "recording.wav");
                      } else {
                        formData.append("file", file);
                      }
                      formData.append("userid", userId);
                      formData.append(
                        "contactid",
                        contacts[activeContactIndex].id
                      );
                      formData.append("type", "file");
                      formData.append(
                        "isreply",
                        replyToMessage ? "true" : "false"
                      );
                      formData.append("replyto", replyToMessage);
                      formData.append("message", message);
                      formData.append("isvoicerecording", isVoiceRecording);
                      axios.post("whatsapp/send-message", formData);
                      setReplyToMessage(null);
                    } else {
                      axios.post(
                        "whatsapp/send-message",
                        GeneralHelper.getFormDataFromModelObject({
                          userid: userId,
                          contactid: contacts[activeContactIndex].id,
                          message,
                          type: "text",
                          relatedfile: file,
                          isreply: replyToMessage ? "true" : "false",
                          replyto: replyToMessage,
                          isvoicerecording: isVoiceRecording,
                        })
                      );
                      setReplyToMessage(null);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatComponent;
