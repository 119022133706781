import TabButton from "./TabButton";

export default function LeadTabs({
  children,
  activeTab,
  setActiveTab,
  tabs,
  backgroundColor = "bg-white",
  leadId,
  fbid,
}) {
  return (
    <div className="tabs">
      <div
        className={`tabs-header d-flex align-content-center gap-5  p-4 rounded-2 ${backgroundColor}`}
      >
        {tabs.map((tabTitle, index) => (
          <TabButton
            tabTitle={tabTitle}
            index={index}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            leadId={leadId}
            fbid={fbid}
            key={index}
          />
        ))}
      </div>
      <div>{children}</div>
    </div>
  );
}
