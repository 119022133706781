export default function PathNames(lang) {
  return {
    "/app/home": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Dashboard", lang),
    ],
    "/app/kanban": [
      window.sys_app_translate("Kanban", lang),
      ">",
      window.sys_app_translate("Dashboard", lang),
    ],
    "/app/kanban-list": [
      window.sys_app_translate("Kanban List", lang),
      ">",
      window.sys_app_translate("Dashboard", lang),
    ],
    "/app/chat": [
      window.sys_app_translate("Kanban", lang),
      ">",
      window.sys_app_translate("Dashboard", lang),
    ],
    //released profits
    "/app/released-profits/view-all": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Released Profits", lang),
    ],
    //agreements
    // "/app/agreements/view-all": [
    //   window.sys_app_translate("Home", lang),
    //   ">",
    //   window.sys_app_translate("Agreement Terms", lang),
    // ],
    //scholarships
    "/app/agreements/scholarships": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Scholarships", lang),
    ],
    //agreements
    "/app/agreement": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Agreement", lang),
    ],
    //agreement profits
    "/app/agreement-profits/view-all": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Agreement Profits", lang),
    ],
    //users
    "/app/users/create": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Add User", lang),
    ],
    "/app/users/modify": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Modify User", lang),
    ],
    "/app/users/view-all": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Users List", lang),
    ],
    //roles
    "/app/roles/create": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Add Role", lang),
    ],
    "/app/roles/modify": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Modify Role", lang),
    ],
    "/app/roles/view-all": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Roles List", lang),
    ],
    //agencies
    "/app/agencies/create": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Add Agency", lang),
    ],
    "/app/agencies/modify": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Modify Agency", lang),
    ],
    "/app/agencies/view-all": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Agencies List", lang),
    ],
    //translations
    "/app/translations": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Translations", lang),
    ],
    //universities
    "/app/universities/view-all": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Universities", lang),
    ],
    "/app/universities/view": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("View University", lang),
    ],
    "/app/universities/create": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Add University", lang),
    ],
    "/app/universities/modify": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Modify University", lang),
    ],
    //students
    "/app/students/view-all": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Students", lang),
    ],
    "/app/students/create": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Add Student", lang),
    ],
    "/app/students/modify": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Modify Student", lang),
    ],
    //applications
    "/app/applications/view-all": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Applications", lang),
    ],
    "/app/applications/deadlines": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("DeadLines", lang),
    ],
    "/app/applications/view": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("View Application", lang),
    ],
    "/app/applications/create": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Apply Now", lang),
    ],
    "/app/applications/modify": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Modify Application", lang),
    ],
    //announcements
    "/app/announcements/view-all": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Announcements", lang),
    ],
    "/app/announcements/create": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Create Announcement", lang),
    ],
    "/app/announcements/modify": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Modify Announcement", lang),
    ],
    //expenses
    "/app/expenses/view-all": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Expenses", lang),
    ],
    "/app/expenses/create": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Add Expense", lang),
    ],
    "/app/expenses/modify": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Modify Expense", lang),
    ],
    //whatsapp devices
    "/app/whatsapp-devices/view-all": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("WhatsApp Devices", lang),
    ],
    //specializations
    "/app/specializations/view-all": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Programs", lang),
    ],
    "/app/specializations/create": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Add Specialization", lang),
    ],
    "/app/specializations/modify": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Modify Specialization", lang),
    ],
    //suppliers
    "/app/suppliers/view-all": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Suppliers", lang),
    ],
    "/app/suppliers/create": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Add Supplier", lang),
    ],
    "/app/suppliers/modify": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Modify Supplier", lang),
    ],
    //notifications
    "/app/notifications/view-all": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Notifications", lang),
    ],
    "/app/notifications/create": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Add Notification", lang),
    ],
    //options
    "/app/options": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Options", lang),
    ],
    //calendar
    "/app/calendar": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Calendar", lang),
    ],
    //messages
    "/app/messaging": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Messaging", lang),
    ],
    //message-requests
    "/app/message-requests/view-all": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Message Requests", lang),
    ],
    //lead-sources
    "/app/lead-sources/view-all": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Lead Sources", lang),
    ],
    "/app/lead-sources/create": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Add Lead Source", lang),
    ],
    "/app/lead-sources/modify": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Modify Lead Source", lang),
    ],
    //departments
    "/app/departments/view-all": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("View Departments", lang),
    ],
    "/app/departments/create": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Add Department", lang),
    ],
    "/app/departments/modify": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Modify Department", lang),
    ],
    //message-templates
    "/app/message-templates/view-all": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Message Templates", lang),
    ],
    "/app/message-templates/create": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Add Message Template", lang),
    ],
    "/app/message-templates/modify": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Modify Message Template", lang),
    ],
    //knowledgebase
    //message-templates
    "/app/knowledgebase/view-all": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Knowledgebase", lang),
    ],
    "/app/knowledgebase/create": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Add Item", lang),
    ],
    "/app/knowledgebase/modify": [
      window.sys_app_translate("Home", lang),
      ">",
      window.sys_app_translate("Modify Item", lang),
    ],
  };
}
