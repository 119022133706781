import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ITQModal } from "../../libs/ITQModal";
import { MenuBurgerIcon, LogoutIcon } from "../../constants";
import { Button, LinearProgress } from "@material-ui/core";
import {
  logoutUser,
  handleAppError,
  setLoading,
  setAgencyInfo,
} from "../../actions";
import { ErrorBoundary } from "react-error-boundary";
import { LanguageSelector, ITQNotifications, GeneralHelper } from "../../libs";
import { database } from "../../libs/estrylib/Firebase";
import Menu from "../Sidebar/components/Menu";
import ForFallBack from "../../libs/ForFallBack";
import AppConfig from "../../constants/AppConfig";
import $ from "jquery";
import MainMenu from "../../libs/MainMenu";
import SetupChatra from "../../libs/SetupChatra";
import PathNames from "../../libs/PathNames";

class MainApp extends Component {
  state = {
    activeMenuItemIndex: 1, //starts from 0
    menu: null,
    adminMenu: null,
    showSignalsPopup: false,
    sidebarMenuHeight: "70vh",
    sidebarMenuWidth: 0,
    fullContentWidth: 0,
  };
  pathNames = PathNames(this.props.user.lang);
  firebaseListener = null;
  signalsFirebaseListener = null;
  ref = null;
  signalsRef = null;

  handleHeight() {
    console.log("document loaded: setting main menu height ...");
    const pageHeaderElement = document.getElementsByClassName("header")[0];
    let sidebarMenuHeight = window.innerHeight - pageHeaderElement.clientHeight;
    $(".logo-container").css("height", pageHeaderElement.clientHeight + "px");
    this.setState({
      sidebarMenuHeight: sidebarMenuHeight + "px",
    });
  }
  componentDidMount() {
    const indexObj = this;
    if (
      document.readyState === "complete" ||
      document.readyState === "interactive"
    ) {
      console.log("RctAppLayout: document is already loaded ...");
      indexObj.handleHeight();
    } else {
      console.log(
        "RctAppLayout: document is not loaded yet ... adding 'documentloaded' event listener"
      );
      window.addEventListener("DOMContentLoaded", function () {
        console.log("document loaded: setting main menu height ...");
        indexObj.handleHeight();
      });
    }
    window.addEventListener("resize", function () {
      console.log("window resized: setting main menu height ...");
      indexObj.handleHeight();
    });

    window.burgerButtonClicked = this.burgerButtonClicked;
    window.toggleTableFilterMenu = this.toggleTableFilterMenu;
    const {
      lang,
      permissions,
      agency_id,
      user_id,
      name,
      live_chat_id,
      top_parent_agency_id,
      is_super_admin,
    } = this.props.user;
    this.setState(
      MainMenu(
        permissions,
        lang,
        is_super_admin,
        agency_id,
        top_parent_agency_id
      )
    );
    if (!this.ref) {
      const ref = database.ref().child("angs").child(agency_id);
      ref.onDisconnect().set("none");
      this.ref = ref;
      const signalsRef = database.ref().child("signals").child(user_id);
      this.signalsRef = signalsRef;
      this.registerFirebaseEvent();
    }
    SetupChatra(name, live_chat_id);
  }
  componentWillUnmount() {
    this.unRegisterFirebaseEvent();
  }
  firebaseListenerFunction(snapshot) {
    console.log("agency related values changed ...");
    const { setAgencyInfo } = this.props;
    const recordValue = snapshot.val();
    if (this.props.user.is_super_admin) {
    }
    setAgencyInfo(recordValue);
  }
  signalsFirebaseListenerFunction(snapshot) {
    console.log("new signal reached ...");
    const signalType = snapshot.val().split(";")[0];
    if (signalType === "RolesUpdated") {
      this.setState({
        showSignalsPopup: true,
      });
    }
    if (signalType === "BrowserRefresh") {
    }
  }
  registerFirebaseEvent() {
    console.log("registering firebase event ...");
    this.firebaseListener = this.ref.on(
      "value",
      this.firebaseListenerFunction.bind(this)
    );
    this.signalsFirebaseListener = this.signalsRef.on(
      "child_changed",
      this.signalsFirebaseListenerFunction.bind(this)
    );
    this.props.setLoading(false);
  }
  unRegisterFirebaseEvent() {
    console.log("unregistering firebase event ...");
    this.firstLoad = true;
    this.ref.off("child_changed");
    this.signalsRef.off("child_changed");
  }
  renderPage() {
    const { children } = this.props;
    return <div className="container-fluid page-container">{children}</div>;
  }
  burgerButtonClicked(onlyIfClosed = false) {
    console.log("burger button clicked fired ...");
    document
      .getElementsByClassName("sidebar-container")[0]
      .classList.toggle("sidebar-container-show");
    if (window.innerWidth <= 576) {
      window.setTimeout(function () {
        $(window).click(function (e) {
          console.log("window clicked ...");
          if ($(".sidebar-container").hasClass("sidebar-container-show")) {
            console.log("hiding sidebar ...");
            const specifiedElement =
              document.getElementsByClassName("sidebar-container")[0];
            const isClickInside = specifiedElement.contains(e.target);
            if (!isClickInside) {
              $(".sidebar-container").removeClass("sidebar-container-show");
              $(window).unbind("click");
            }
          }
        });
      }, 300);
    } else {
      if (onlyIfClosed && !$(".sidebar-bg").hasClass("closed")) {
        return;
      }
      if (!$(".sidebar-bg").hasClass("closed")) {
        $(".menu-item-n .angle").removeClass("active");
        $(".menu-item-n .sub-menu").removeClass("show");
      }
      $(".sidebar-bg").toggleClass("closed");
      //remove classes
      $(".s-top-bar").toggleClass("offset-md-3");
      $(".s-top-bar").toggleClass("offset-lg-3");
      $(".s-top-bar").toggleClass("offset-xl-2");

      $(".s-main-content").toggleClass("offset-md-3");
      $(".s-main-content").toggleClass("offset-lg-3");
      $(".s-main-content").toggleClass("offset-xl-2");
      //add classes
      $(".s-top-bar").toggleClass("offset-md-2");
      $(".s-top-bar").toggleClass("offset-lg-1");
      $(".s-top-bar").toggleClass("offset-xl-1");

      $(".s-main-content").toggleClass("offset-md-2");
      $(".s-main-content").toggleClass("offset-lg-1");
      $(".s-main-content").toggleClass("offset-xl-1");
    }
  }
  toggleTableFilterMenu() {
    $("#itqan_table_filters_top_container").toggleClass("itqan-mobile-hide");
    $(".itqan-overlay").toggleClass("show");
    window.setTimeout(function () {
      $(window).click(function (e) {
        console.log("window clicked ...");
        if (
          !$("#itqan_table_filters_top_container").hasClass("itqan-mobile-hide")
        ) {
          console.log("hiding sidebar ...");
          const specifiedElement = document.getElementById(
            "itqan_table_filters_top_container"
          );
          const isClickInside = specifiedElement.contains(e.target);
          if (!isClickInside) {
            $("#itqan_table_filters_top_container").toggleClass(
              "itqan-mobile-hide"
            );
            $(".itqan-overlay").toggleClass("show");
            $(window).unbind("click");
          }
        }
      });
    }, 300);
  }
  renderHeader() {
    const {
      user_id,
      name,
      lang,
      is_root_agency,
      agency_id,
      agency_logo,
      root_agency_logo,
      profile_picture,
    } = this.props.user;
    const { history, handleAppError, setLoading } = this.props;
    //change html lang attribute
    document.documentElement.setAttribute("lang", lang);
    return (
      <div
        className="container-fluid header"
        style={{ position: "fixed", width: "100%", zIndex: "1000" }}
      >
        <div className="row justify-content-between">
          <div className="col-5 col-md-3 offset-md-0 col-lg-2 offset-lg-0 col-xl-2 offset-xl-0 d-flex align-items-center px-0 s-top-bar">
            <div className="col-10 text-center d-none d-sm-block">
              {!is_root_agency && (
                <img
                  src={
                    agency_logo
                      ? AppConfig.storagePath + agency_logo
                      : require("../../assets/education-logo.png")
                  }
                  alt="Education System Logo"
                  style={{ height: "4rem", cursor: "pointer" }}
                  onClick={() => {
                    this.props.history.replace("/app/home");
                  }}
                />
              )}
              {is_root_agency && (
                <img
                  alt="Education System Logo"
                  src={require("../../assets/education-logo.png")}
                  style={{ height: "4rem", cursor: "pointer" }}
                  onClick={() => {
                    this.props.history.replace("/app/home");
                  }}
                />
              )}
            </div>
            <div className="col-2 offset-1 offset-sm-0">
              <div
                className="burger-button-container"
                onClick={() => {
                  window.burgerButtonClicked();
                }}
              >
                <MenuBurgerIcon className="clickable" />
              </div>
            </div>
            {this.pathNames[history.location.pathname] && (
              <div className="col-9 d-flex">
                <span
                  className="px-3 inter inter-bold estry-page-header-title"
                  style={{ fontSize: "1.5rem" }}
                >
                  {
                    this.pathNames[history.location.pathname][
                      this.pathNames[history.location.pathname].length - 1
                    ]
                  }
                </span>
              </div>
            )}
          </div>

          <div className="col-7 col-md-6 col-lg-5 col-xl-4 d-flex justify-content-between header-right-section align-items-center">
            <LanguageSelector
              lang={lang}
              handleAppError={handleAppError}
              setLoading={setLoading}
            />
            <div className="notifications d-flex align-items-center">
              <ITQNotifications
                userId={user_id}
                agencyId={agency_id}
                lang={lang}
              />
            </div>
            <div className="header-user">
              <img
                className="profile-picture"
                src={
                  profile_picture
                    ? AppConfig.storagePath + profile_picture
                    : require("../../assets/default-profile-picture.png")
                }
              />
              <div className="name">{this.props.user.name}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderSidebar() {
    const menu = this.props.user.is_root_agency
      ? this.state.adminMenu
      : this.state.menu;
    const { sidebarMenuHeight } = this.state;
    return (
      <div
        className="container-fluid sidebar-bg px-0 mx-0"
        style={{ position: "fixed" }}
      >
        <div className="row">
          <div className="col-12 px-0">
            <div className="logo-container d-flex justify-content-center"></div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-12 d-flex justify-content-center sidebar-menu-container"
            style={{ height: sidebarMenuHeight }}
          >
            <div className="sidebar-menu">
              {menu && (
                <Menu
                  menuItems={menu}
                  handler={() => {}}
                  logout={this.props.logoutUser}
                  lang={this.props.user.lang}
                  fbid={this.props.user.fbid}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  calculateSidebarMenuWidth() {
    const sidebarMenu = document.getElementsByClassName("sidebar-bg")[0];
    const sidebarMenuWidth = sidebarMenu.offsetWidth;
    return sidebarMenuWidth;
  }
  errorFallback(props) {
    return <ForFallBack {...props} />;
    // return (
    //   <div role="alert">
    //     <p>Something went wrong:</p>
    //     <pre>{error.message}</pre>
    //     <button onClick={resetErrorBoundary}>Try again</button>
    //   </div>
    // );
  }
  renderSignalsPopup() {
    const { showSignalsPopup } = this.state;
    const { lang } = this.props.user;
    return (
      <ITQModal
        isOpen={showSignalsPopup}
        viewMode={true}
        isCenterd={true}
        size="sm"
        title={window.sys_app_translate("Important Update!", lang)}
        bodyContent={
          <>
            <div className="text-center">
              {window.sys_app_translate(
                "Your user is affected by new changes, you must logout"
              )}
            </div>
            <div className="text-center" style={{ marginTop: "35px" }}>
              <Button
                variant="contained"
                className="sidebar-logout-btn"
                onClick={() => {
                  this.props.setLoading(true);
                  this.props.logoutUser();
                }}
              >
                <LogoutIcon
                  style={{
                    marginRight: "5px",
                    width: "10px",
                  }}
                />
                {window.sys_app_translate("Logout", lang)}
              </Button>
            </div>
          </>
        }
        showFooter={true}
      />
    );
  }
  render() {
    const { loading, history } = this.props;
    console.log("rendering content ...");
    let lockLoadingPage = false;
    switch (history.location.pathname) {
      case "/app/insurance-requests/create":
      //case "/app/insurance-requests/view-all":
      case "/app/expenses/view-all":
      case "/app/announcements/view-all":
      case "/app/expenses/create":
        lockLoadingPage = false;
        break;
    }
    return (
      <ErrorBoundary FallbackComponent={this.errorFallback} onReset={() => {}}>
        {loading && (
          <div
            className="container-fluid"
            style={{ position: "fixed", zIndex: "100000" }}
          >
            <LinearProgress color="primary" />
            <LinearProgress color="primary" />
          </div>
        )}
        <div className="container-fluid" style={{ position: "relative" }}>
          {loading && lockLoadingPage ? (
            <div
              style={{
                background: "white",
                position: "absolute",
                opacity: "0.4",
                width: "100%",
                height: "100vh",
                zIndex: "9998",
              }}
            ></div>
          ) : null}
          <div className="row">
            <div className="col-sm-12 px-0 sidebar-container">
              {this.renderSidebar()}
            </div>
            <div className="col-12 px-0">
              <div className="row">
                <div className="col-12 px-0">{this.renderHeader()}</div>
              </div>
            </div>
            <div
              className={`col-12 col-md-8 offset-md-3 col-lg-8 offset-lg-3 col-xl-10 offset-xl-2 px-0 ${
                GeneralHelper.isFullWidthContent(this.props.history)
                  ? " full-width-top-container"
                  : " zoom-out-main"
              } s-main-content`}
            >
              <div className="container-fluid">
                <div
                  className="row justify-content-center"
                  style={{
                    marginTop: GeneralHelper.isFullWidthContent(
                      this.props.history
                    )
                      ? "6.0rem"
                      : "8.6rem",
                  }}
                >
                  <div
                    className={`${
                      GeneralHelper.isFullWidthContent(this.props.history) ||
                      history.location.pathname === "/app/home"
                        ? "col-12"
                        : "col-11"
                    } px-0`}
                  >
                    <div className="container-fluid">
                      <div className="row justify-content-center">
                        <div
                          className={`col-12 ${
                            GeneralHelper.isFullWidthContent(this.props.history)
                              ? "px-0"
                              : ""
                          }`}
                        >
                          {this.renderPage()}
                          {this.renderSignalsPopup()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade itqan-overlay"></div>
      </ErrorBoundary>
    );
  }
}

//map state to props
const mapStateToProps = ({ settings, authUser, appReducer }) => {
  const { loading } = appReducer;
  const { user } = authUser;
  return { user, loading };
};

export default withRouter(
  connect(mapStateToProps, {
    logoutUser,
    handleAppError,
    setLoading,
    setAgencyInfo,
  })(MainApp)
);
