/**
 * Auth Actions
 */
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  SET_USER_UI_STATE_DATA,
  SET_USER_UI_STATE_SECTION_COLUMNS,
} from "./types";

/**
 * Redux Action To Sigin User
 */
export const signinUserIn = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
});

/**
 * Redux Action Signin User Success
 */
export const signinUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});
/**
 * Redux Action Signin User Fail
 */
export const signinUserFailure = (user) => ({
  type: LOGIN_USER_FAILURE,
  payload: user,
});

/**
 * Redux Action To Signout User
 */
export const logoutUser = () => ({
  type: LOGOUT_USER,
});

/**
 * Redux Action Signout User Success
 */
export const logoutUserSuccess = () => ({
  type: LOGOUT_USER_SUCCESS,
});

/**
 * Redux Action Signout User Failure
 */
export const logoutUserFailure = () => ({
  type: LOGOUT_USER_FAILURE,
});

export const setUserUIStateData = (data) => ({
  type: SET_USER_UI_STATE_DATA,
  payload: data,
});

export const setUserUIStateSectionColumns = (data) => ({
  type: SET_USER_UI_STATE_SECTION_COLUMNS,
  payload: data,
});
