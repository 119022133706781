import { Button } from "@material-ui/core";
import { Icon } from "@iconify/react";
import NavigationBar from "./NavigationBar";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "../../axios";
import {
  PLeadsBulkChangePhase,
  PLeadsBulkChangeResponsibleUser,
  PLeadsBulkDelete,
  PSectionLeads,
} from "../../constants";

export default function ActionsBar({
  selectedLeads,
  setSelectedLeads,
  props,
  refresh,
  changeLeadsPhase,
  changeResponsibleUser,
  activeTabId = 1,
}) {
  const removeLeads = async () => {
    const { handleAppError, showMessage, setLoading } = props,
      { user } = props;
    try {
      setLoading(true);
      await axios.post(`leads/bulk-delete-leads?leadsids=${selectedLeads}`);
      refresh();
      setSelectedLeads([]);
      showMessage({
        type: "warning",
        title: window.sys_app_translate("Leads Deleted", user.lang),
        message: window.sys_app_translate(
          "Selected leads have been deleted",
          user.lang
        ),
      });
      setLoading(false);
    } catch (error) {
      handleAppError(error);
    }
  };
  const getTabs = () => {
    const { permissions } = props.user,
      tabs = [];
    if (permissions[PSectionLeads][PLeadsBulkDelete]) {
      tabs.push({
        title: "Delete",
        action: () => {
          // Delete selected leads
          confirmAlert({
            title: window.sys_app_translate(
              "Confirm Deletion",
              props.user.lang
            ),
            message: window.sys_app_translate(
              "Are you sure to delete these leads?",
              props.user.lang
            ),
            buttons: [
              {
                label: window.sys_app_translate("Yes", props.user.lang),
                onClick: () => {
                  removeLeads();
                },
              },
              {
                label: window.sys_app_translate("No", props.user.lang),
                onClick: () => {},
              },
            ],
          });
        },
      });
    }
    if (permissions[PSectionLeads][PLeadsBulkChangePhase]) {
      tabs.push({
        title: "Change Phase",
        action: () => {
          // Change selected leads phase
          changeLeadsPhase();
        },
      });
    }
    if (permissions[PSectionLeads][PLeadsBulkChangeResponsibleUser]) {
      tabs.push({
        title: "Change Responsible User",
        action: () => {
          // Change selected leads owner
          changeResponsibleUser();
        },
      });
    }
    return tabs;
  };

  return (
    <div className="tabs my-3 d-flex gap-3 kanban-header-2">
      {selectedLeads.length > 0 && (
        <div
          className="py-2 px-4 text-white rounded-2 d-flex gap-3 align-items-center"
          style={{
            backgroundColor: "#ED4C16",
            fontWeight: 500,
            fontSize: "1.11rem",
          }}
        >
          <span className="pb-1">
            {selectedLeads.length}{" "}
            {window.sys_app_translate("selected", props.user.lang)}
          </span>
          <Icon
            icon={"carbon:close-filled"}
            role="button"
            onClick={() => setSelectedLeads([])}
          />
        </div>
      )}
      <NavigationBar activeTabId={activeTabId} />
      {selectedLeads.length > 0 && (
        <div className="tabs-header d-flex align-content-center gap-5 bg-white px-4 rounded-2">
          {getTabs().map((tab, index) => (
            <Button
              className="tab-t inter inter-medium"
              style={{ fontSize: "1.2rem" }}
              key={index}
              onClick={() => {
                tab.action();
              }}
            >
              {window.sys_app_translate(tab.title, props.user.lang)}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
}
