import { useEffect } from "react";
import $ from "jquery";
import { MenuItemElement } from "./MenuItemElement";
export default function Menu({ menuItems, handler, logout, fbid }) {
  useEffect(() => {
    $(".nav-item a").removeClass("active");
    return () => {};
  }, []);

  return (
    <>
      <ul className="nav">
        {menuItems.map((menuItem, index) => {
          if (!menuItem) {
            return null;
          }
          return (
            <MenuItemElement
              path={menuItem.path}
              icon={menuItem.icon}
              title={menuItem.title}
              subMenu={menuItem.subMenu}
              index={index}
              fbid={fbid}
              key={index}
              logout={logout}
              enTitle={menuItem.enTitle}
            />
          );
        })}
      </ul>
    </>
  );
}
