import { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function IconsSelector({ icon, selectIcon, iconsCollection }) {
  const [panelIsVisible, setPanelIsVisible] = useState(false);
  const panelRef = useRef();

  useEffect(() => {
    function handleClickOutsidePanel(event) {
      if (panelRef.current && !panelRef.current.contains(event.target)) {
        setPanelIsVisible(false);
      }
    }

    if (panelIsVisible) {
      document.addEventListener("mousedown", handleClickOutsidePanel);
    } else {
      document.removeEventListener("mousedown", handleClickOutsidePanel);
    }

    return () =>
      document.removeEventListener("mousedown", handleClickOutsidePanel);
  }, [panelIsVisible]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: ".6rem 1.25rem",
        width: "100%",
        background: "#FFFFFF",
        border: "1px solid #AAAAAA",
        borderRadius: ".5rem",
      }}
    >
      <p style={{ flex: 1 }}>{icon}</p>

      <Icon
        icon={icon}
        width={32}
        height={32}
        style={{ cursor: "pointer" }}
        onClick={() => setPanelIsVisible(true)}
      />

      {panelIsVisible && (
        <div
          ref={panelRef}
          style={{
            zIndex: 5,
            position: "absolute",
            top: "4.5rem",
            left: "0",
            width: "fit-content",
            height: "fit-content",
            maxWidth: "30rem",
            maxHeight: "200px",
            overflowY: "auto",
            background: "white",
            border: "1px solid #AAAAAA",
            borderRadius: ".5rem",
            padding: "1rem",
            display: "flex",
            gap: ".35rem",
            flexWrap: "wrap",
            rowGap: ".35rem",
          }}
        >
          {iconsCollection.map((ic, i) => (
            <Icon
              key={`${ic}-${i}`}
              icon={ic}
              width={32}
              height={32}
              style={{ cursor: "pointer" }}
              onMouseDown={() => selectIcon(ic)}
              onMouseUp={() => setPanelIsVisible(false)}
            />
          ))}
        </div>
      )}
    </div>
  );
}
