import React, { useState, useEffect } from "react";
import axios from "../../../axios";
import { _getNormalInput } from "../../../libs";
import { NoteFormComponent } from "./NoteFormComponent";
import { Skeleton } from "@material-ui/lab";
import { ChatEmptyAvatar } from "../../../constants";

const NotesComponent = ({
  handleAppError,
  lang,
  leadId,
  errorObj,
  scrollableHeight,
}) => {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const getNotes = async () => {
    try {
      const response = await axios.get(`leads/${leadId}/notes/get-all`);
      setNotes(response.data.data);
      setLoading(false);
    } catch (error) {
      handleAppError(error);
    }
  };
  useEffect(() => {
    getNotes();
  }, []);
  return (
    <>
      <div className="py-2" style={{ backgroundColor: "white" }}>
        <NoteFormComponent
          errorObj={errorObj}
          lang={lang}
          leadId={leadId}
          handleAppError={handleAppError}
          refresh={getNotes}
        />
      </div>
      <div
        className="rounded-2"
        style={{
          height: (scrollableHeight - 220) / 16 + "rem",
          overflowY: "auto",
        }}
      >
        <div className="container-fluid my-2">
          <div className="row">
            <div className="col-12 px-0">
              <div className="notes-list">
                {!loading &&
                  notes.map((note, index) => (
                    <div key={index} className="note-item">
                      <div className="note-header">
                        <ChatEmptyAvatar className="avatar" />
                        <div className="note-info">
                          <h4>{note.createdby}</h4>
                          <span>
                            {new Date(note.created_at).toLocaleString()}
                          </span>
                        </div>
                      </div>
                      <div className="note-content">
                        <p>{note.note}</p>
                      </div>
                    </div>
                  ))}
                {loading && (
                  <>
                    <Skeleton variant="rect" height={120} />
                    <Skeleton variant="rect" height={120} className="mt-4" />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotesComponent;
