/**
 * Auth Sagas
 */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { OfflineStorage } from "../libs/estrylib";
import axios from "../axios";
import {
  LOGIN_USER,
  LOGOUT_USER,
  SET_USER_UI_STATE_DATA,
  SET_USER_UI_STATE_SECTION_COLUMNS,
} from "../actions/types";
import { auth, database } from "../libs/estrylib/Firebase";

import {
  signinUserSuccess,
  logoutUserSuccess,
  logoutUserFailure,
  handleAppError,
} from "../actions";
import { SubdomainChecker } from "../libs";
/**
 * Sigin User With Username and Password Request
 */
const signinRequest = async (email, password) => {
  try {
    const response = await axios.post("/auth/login", {
      email: email,
      username: email,
      password: password,
      ddfweb: "yes",
    });
    //SubdomainChecker.check(response.data, null, null);
    await auth.signInWithEmailAndPassword(response.data.email, password);

    return { type: "response", response: response };
  } catch (error) {
    return { type: "error", error: error };
  }
};

/**
 * Signout Request
 */
const signOutRequest = async () => {
  try {
    const response = await axios.post("/auth/logout");
    auth.signOut();
    return { type: "response", response: response };
  } catch (error) {
    return { type: "error", error: error };
  }
};

const setUIStateDataColumnsRequest = async (section, columns) => {
  try {
    console.log("sending ui state request ...");
    let uiStateData = OfflineStorage.instance.get("esitqappedu-uistatedata");
    uiStateData[section]["columns"] = columns;
    const response = await axios.post("users/set-ui-state-data", {
      uistatedata: uiStateData,
    });
    return { type: "response", response: response };
  } catch (error) {
    return { type: "error", error: error };
  }
};

const setUIStateDataRequest = async (uiStateData) => {
  try {
    console.log("sending ui state request ...");
    const response = await axios.post("users/set-ui-state-data", {
      uistatedata: uiStateData,
    });
    return { type: "response", response: response };
  } catch (error) {
    return { type: "error", error: error };
  }
};

/**
 * Signin User With Email & Password
 */
function* signin({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const result = yield call(signinRequest, email, password);
    if (result.type === "error") {
      yield put(handleAppError(result.error));
    } else {
      const user = result.response.data;
      // localStorage.setItem("storagetime", new Date().getTime());
      yield put(signinUserSuccess(user));
      history.push("/");
    }
  } catch (error) {
    yield put(handleAppError(error));
  }
}

/**
 * Signout User
 */
function* signOut() {
  try {
    console.log("sign out ...");
    const result = yield call(signOutRequest);
    if (result.type === "error") {
      yield put(handleAppError(result.error));
    } else {
      yield put(logoutUserSuccess());
    }
  } catch (error) {
    yield put(handleAppError(error));
  }
}
function* setUIStateDataColumns({ payload }) {
  try {
    console.log("sending user ui state data ...");
    const result = yield call(
      setUIStateDataColumnsRequest,
      payload.section,
      payload.columns
    );
  } catch (error) {
    yield put(handleAppError(error));
  }
}
function* setUIStateData({ payload }) {
  try {
    console.log("sending user ui state data ...");
    const result = yield call(setUIStateDataRequest, payload);
  } catch (error) {
    yield put(handleAppError(error));
  }
}

/**
 * Set UI state columns
 */

/**
 * Signin User
 */
export function* signinUserIn() {
  yield takeEvery(LOGIN_USER, signin);
}

/**
 * Signout User
 */
export function* signOutUser() {
  yield takeEvery(LOGOUT_USER, signOut);
}
/**
 * catch set ui state columns
 */
export function* catchSetUIStateDataColumns() {
  yield takeEvery(SET_USER_UI_STATE_SECTION_COLUMNS, setUIStateDataColumns);
}
export function* catchSetUIStateData() {
  yield takeEvery(SET_USER_UI_STATE_DATA, setUIStateData);
}

/**
 * Auth Root Saga
 */
export default function* rootSaga() {
  yield all([
    fork(signinUserIn),
    fork(signOutUser),
    fork(catchSetUIStateDataColumns),
    fork(catchSetUIStateData),
  ]);
}
