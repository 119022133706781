import {
  ApplyNowIcon,
  PApplicationsCreate,
  PSectionApplications,
} from "../constants";

export const _getApplyNow = (props) => {
  const { lang, permissions } = props.user;
  return permissions[PSectionApplications][PApplicationsCreate] ? (
    <div
      className={"apply-now clickable"}
      onClick={() => {
        props.history.replace("/app/applications/create");
      }}
    >
      <ApplyNowIcon className="apply-now-icon" />
      <span className="inter inter-medium mx-2">
        {window.sys_app_translate("Apply Now", lang)}
      </span>
    </div>
  ) : null;
};
