import React, { useState, useEffect } from "react";
import axios from "../../../axios";
import { AppConfig } from "../../../constants";
import { Skeleton } from "@material-ui/lab";

const KnowledgebaseListComponent = ({ lang, handleAppError }) => {
  const [knowledgebaseListItems, setKnowledgebaseListItems] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getKnowledgebaseList();
  }, []);
  //get data
  const getKnowledgebaseList = async () => {
    try {
      const response = await axios.get("knowledgebase-items/get-all", {
        params: {
          all: true,
        },
      });
      setKnowledgebaseListItems(response.data.data);
      setLoading(false);
    } catch (error) {
      handleAppError(error);
    }
  };
  return (
    <div className="container">
      {!loading &&
        knowledgebaseListItems.map((item) => (
          <div className="row">
            <div className="col-12">
              <div key={item.id} className="card my-2">
                <div
                  className="card-header inter inter-medium"
                  style={{
                    backgroundColor: AppConfig.themeColors.primary,
                    color: "white",
                  }}
                >
                  {item.title}
                </div>
                <div className="card-body inter inter-medium">
                  {item.description}
                </div>
              </div>
            </div>
          </div>
        ))}
      {loading && (
        <>
          <div className="row my-2">
            <div className="col-12">
              <Skeleton variant="rect" height={44} />
              <div className="my-1"></div>
              <Skeleton variant="rect" height={59} />
            </div>
          </div>
          <div className="row my-2">
            <div className="col-12">
              <Skeleton variant="rect" height={44} />
              <div className="my-1"></div>
              <Skeleton variant="rect" height={59} />
            </div>
          </div>
          <div className="row my-2">
            <div className="col-12">
              <Skeleton variant="rect" height={44} />
              <div className="my-1"></div>
              <Skeleton variant="rect" height={59} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default KnowledgebaseListComponent;
