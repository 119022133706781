export const IconsCollection = [
  "fa6-regular:address-book",
  "fa6-regular:address-card",
  "fa6-regular:bell",
  "fa6-regular:bookmark",
  "fa6-regular:building",
  "fa6-regular:calendar",
  "fa6-regular:chess-knight",
  "fa6-regular:clock",
  "fa6-regular:closed-captioning",
  "fa6-regular:comment",
  "fa6-regular:face-angry",
  "fa6-regular:face-dizzy",
  "fa6-regular:face-flushed",
  "fa6-regular:face-frown",
  "fa6-regular:face-frown-open",
  "fa6-regular:face-grimace",
  "fa6-regular:face-grin",
  "fa6-regular:face-grin-beam",
  "fa6-regular:face-grin-beam-sweat",
  "fa6-regular:face-grin-hearts",
  "fa6-regular:face-grin-squint",
  "fa6-regular:chess-rook",
  "fa6-regular:chess-queen",
  "fa6-regular:chess-pawn",
  "fa6-regular:chess-king",
  "fa6-regular:chess-bishop",
];
