import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { NotificationsIcon2, RingIcon } from "../constants";
import { database } from "../libs/estrylib/Firebase";

import createActivityDetector from "activity-detector";
import axios from "../axios";
import { logoutUser, showMessage, handleAppError } from "../actions";
import toast, { Toaster } from "react-hot-toast";
import { ITQModal } from "../libs/ITQModal";
import { Button, CircularProgress } from "@material-ui/core";
import { High, Low } from "../libs/AnnouncementPriorities";
import { NotificationsFirebaseKey } from "../libs/FirebaseKeys";
import AppConfig from "../constants/AppConfig";
import ReactPlayer from "react-player";
import ITQNotificationItem from "./ITQNotificationItem";

class ITQNotifications extends Component {
  state = {
    notifications: [],
    highPriorityNotifications: [],
    isNotify: false,
    showImportantNoteModal: false,
    notificationItem: null,
    showRedNum: false,
    newNotificationsCount: 0,
    page: 1,
    perpage: 10,
    loadingMore: false,
  };
  firebaseRef = null;
  ref = null;
  btn = null;
  img =
    "https://media.istockphoto.com/photos/beautiful-woman-blonde-hair-portrait-close-up-studio-on-white-picture-id498894204?k=20&m=498894204&s=612x612&w=0&h=yXPpUEyz27_Q-xqcypuRa3n5VdsF6tNvKKwyYUhCBLA=";
  newItems = false;
  activityDetector = null;
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.btn = React.createRef();
    this.firebaseRef = database
      .ref()
      .child(NotificationsFirebaseKey)
      .endAt()
      .limitToLast(1);
  }
  async handler() {
    const { handleAppError } = this.props;
    try {
      const { isNotify, showRedNum } = this.state;
      await this.setState({
        isNotify: !isNotify,
      });
      if (showRedNum) {
        await axios.post("/notifications/set-latest-low-as-seen");
        await this.setState({
          showRedNum: false,
          newNotificationsCount: 0,
        });
      }
    } catch (error) {
      handleAppError(error);
    }
  }
  logoutIfInactive() {
    const { max_inactivity_duration, user_id, is_root_agency } =
      this.props.user;
    this.activityDetector = createActivityDetector({
      timeToIdle: parseInt(max_inactivity_duration),
      inactivityEvents: [""],
      autoInit: false,
    });
    const isRootAgency = is_root_agency;
    if (user_id) {
      if (!isRootAgency) {
        // const obj = this;
        // this.activityDetector.on("idle", function () {
        //   obj.props.logoutUser();
        // });
        // this.activityDetector.init();
      }
      const timer = window.setInterval(function () {
        if (
          !localStorage.getItem("esitqappedu-agency-id") &&
          !localStorage.getItem("esitqappedu-nocheck")
        ) {
          window.clearInterval(timer);
          window.location.reload();
        }
      }, 100);
    }
  }
  componentDidMount() {
    const { isNotify } = this.state,
      { lang } = this.props.user;
    document.addEventListener("click", (e) => {
      if (!isNotify) {
        if (this.ref.current) {
          let isClick = this.ref.current.contains(e.target),
            isBtn = this.btn.current.contains(e.target);
          if (!isClick && !isBtn) {
            this.setState({
              isNotify: false,
            });
          }
        }
      }
    });
    const cpt = this;
    this.firebaseRef.on("child_added", (snapshot) => {
      console.log("child added ...");
      if (cpt.newItems) {
        const notifications = [...this.state.notifications],
          { userId, agencyId } = this.props,
          notification = snapshot.val(),
          notificationItem = {
            id: notification.id,
            text: notification.text,
            time: notification.time,
            relateditemid: notification.relateditemid,
            relateditemtype: notification.relateditemtype,
          };
        if (notification.toagencyid && notification.toagencyid === agencyId) {
          if (notification.priority === Low) {
            if (notification.usersids.includes(userId)) {
              toast(window.sys_app_translate(notification.text, lang), {
                duration: 5000,
              });
              notifications.unshift(notificationItem);
              this.setState({
                notifications: notifications,
                time: notification.time,
                showRedNum: true,
                newNotificationsCount: this.state.newNotificationsCount + 1,
              });
            }
          } else {
            this.setState({
              notificationItem: notification,
              showImportantNoteModal: true,
            });
          }
          return;
        }
        if (notification.touserid && notification.touserid === userId) {
          if (notification.priority === Low) {
            toast(window.sys_app_translate(notification.text, lang), {
              duration: 5000,
            });
            notifications.unshift(notificationItem);
            this.setState({
              notifications: notifications,
              time: notification.time,
              showRedNum: true,
              newNotificationsCount: this.state.newNotificationsCount + 1,
            });
          } else {
            this.setState({
              notificationItem: notification,
              showImportantNoteModal: true,
            });
          }
          return;
        }
        if (!notification.touserid && !notification.toagencyid) {
          if (
            notification.priority === Low &&
            notification.usersids.includes(userId)
          ) {
            toast(window.sys_app_translate(notification.text, lang), {
              duration: 5000,
            });
            notifications.unshift(notificationItem);
            this.setState({
              notifications: notifications,
              showRedNum: true,
              time: notification.time,
              newNotificationsCount: this.state.newNotificationsCount + 1,
            });
          } else {
            this.setState({
              notificationItem: notification,
              showImportantNoteModal: true,
            });
          }
          return;
        }
      }
    });
    this.firebaseRef.once("value", function (snapshot) {
      console.log("once value ...");
      cpt.newItems = true;
    });
    this.logoutIfInactive();
    if (["ar", "fa"].includes(this.props.user.lang)) {
      document.documentElement.setAttribute("lang", this.props.user.lang);
      document.documentElement.setAttribute("dir", "rtl");
      var link = document.getElementById("btcss");
      link.setAttribute("href", "/bootstrap-ar.css");
      document.body.dir = "rtl";
    }
    // Add scroll event listener
    const notifyCardNotification = document.querySelector(
      ".notify-card-notification"
    );
    if (notifyCardNotification) {
      notifyCardNotification.addEventListener("scroll", this.handleScroll);
    }
    this.getNotifications(this.state.page);
  }
  handleScroll = async (event) => {
    const { loadingMore, page } = this.state;
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight - 50 && !loadingMore) {
      this.setState({ loadingMore: true });
      await this.getNotifications(page + 1);
      this.setState({ loadingMore: false });
    }
  };
  componentWillUnmount() {
    if (this.activityDetector) {
      this.activityDetector.stop();
    }
  }
  decideLink(text) {
    return null;
  }
  getImportantNoteModal() {
    const { showImportantNoteModal, notificationItem } = this.state;
    const { lang } = this.props.user;
    return (
      showImportantNoteModal && (
        <ITQModal
          isOpen={showImportantNoteModal}
          isCenterd={true}
          cancelButtonText="x"
          cancelButtonClicked={async () => {
            await this.setNotificationAsSeen(notificationItem.id);
            await this.setState({
              showImportantNoteModal: false,
            });
          }}
          title={window.sys_app_translate("Notification", lang)}
          bodyContent={
            <>
              {notificationItem.link && notificationItem.image && (
                <div className="row justify-content-center">
                  <div className="col-8 text-center">
                    <a href={notificationItem.link}>
                      <img
                        src={AppConfig.storagePath + notificationItem.image}
                        style={{ height: "400px" }}
                      />
                    </a>
                  </div>
                </div>
              )}
              {!notificationItem.link && notificationItem.image && (
                <div className="row justify-content-center">
                  <div className="col-8 text-center">
                    <img
                      src={AppConfig.storagePath + notificationItem.image}
                      style={{ height: "400px" }}
                    />
                  </div>
                </div>
              )}
              {["reel", "video"].includes(notificationItem.mediafiletype) &&
                notificationItem.mediafile && (
                  <div className="row justify-content-center">
                    <div className="col-12 px-0 d-flex justify-content-center">
                      <ReactPlayer
                        url={AppConfig.storagePath + notificationItem.mediafile}
                      />
                    </div>
                  </div>
                )}

              <div
                className="row justify-content-center"
                style={{ marginTop: "1rem" }}
              >
                <div
                  className="col-8 text-center"
                  style={{ fontSize: "1.2rem" }}
                >
                  {notificationItem.text}
                </div>
              </div>
              {notificationItem.link && (
                <div
                  className="row justify-content-center"
                  style={{ marginTop: "1rem" }}
                >
                  <div className="col-8 text-center">
                    <a href={notificationItem.link}>
                      {window.sys_app_translate("Click Here", lang)}
                    </a>
                  </div>
                </div>
              )}
              <div
                className="row justify-content-center"
                style={{ marginTop: "1rem" }}
              >
                <div className="col-8 text-center">
                  <Button
                    variant="contained"
                    style={{ display: "inline" }}
                    className="table-add-button estry-accept-button inter inter-bold"
                    onClick={async () => {
                      console.log("confirming notification ...");
                      await this.setNotificationAsSeen(notificationItem.id);
                      await this.setState({
                        showImportantNoteModal: false,
                      });
                    }}
                  >
                    {window.sys_app_translate("Confirm", lang)}
                  </Button>
                </div>
              </div>
            </>
          }
          size="lg"
        />
      )
    );
  }
  async setNotificationAsSeen(notificationId) {
    const { handleAppError } = this.props;
    try {
      await axios.post("/notifications/set-as-seen/" + notificationId);
    } catch (error) {
      handleAppError(error);
    }
  }
  async getNotifications(page) {
    console.log("getting notifications ..." + page);
    const { handleAppError } = this.props,
      { notifications } = this.state;
    try {
      const response = await axios.get("/notifications/get-for-dropdown", {
        params: {
          perpage: 10,
          page: page,
        },
      });
      await this.setState({
        notifications: notifications.concat(response.data.data.data),
        page: page,
      });
    } catch (error) {
      handleAppError(error);
    }
  }
  render() {
    const {
        notifications,
        isNotify,
        showRedNum,
        newNotificationsCount,
        loadingMore,
      } = this.state,
      { lang, history } = this.props,
      { fbid } = this.props.user;
    return (
      <div className="notify">
        {this.getImportantNoteModal()}
        <Toaster position="bottom-center" reverseOrder={false} />
        <div className="container">
          <button
            ref={this.btn}
            onClick={this.handler.bind(this)}
            className="notify-btn"
          >
            <span
              style={{ display: "inline-block" }}
              className={
                newNotificationsCount > 0
                  ? "img animate__animated animate__headShake animate__delay-2s"
                  : "img "
              }
            >
              <RingIcon className="d-none d-sm-block" />
              <NotificationsIcon2 className="d-block d-sm-none" />
            </span>
            {notifications.length > 0 && showRedNum && (
              <span className="num">{newNotificationsCount}</span>
            )}
          </button>
          <div
            ref={this.ref}
            className={`notify-card-wrp ${(isNotify && " active") || ""}`}
          >
            <div className="notify-card">
              <div className="notify-card-intigator"></div>
              <div className="notify-card-head">
                <h3>{window.sys_app_translate("Notifications", lang)}</h3>
              </div>
              <ul className="notify-card-notification px-0">
                {notifications.map((notification, i) => {
                  return (
                    <ITQNotificationItem
                      key={"notificationlistitem" + notification.id}
                      notification={notification}
                      lang={lang}
                      fbid={fbid}
                    />
                  );
                })}
                <li
                  className="notify-card-notification-item d-flex justify-content-center"
                  style={{ borderBottom: "none" }}
                >
                  {loadingMore && <CircularProgress size={"2rem"} />}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// map state to props
const mapStateToProps = ({ settings, authUser, appReducer }) => {
  const { loading } = appReducer;
  const { user } = authUser;
  return { user, loading };
};

export default withRouter(
  connect(mapStateToProps, { logoutUser, showMessage, handleAppError })(
    ITQNotifications
  )
);
