import { useState, useRef, useEffect } from "react";

export default function ColorSelector({
  colorPalette = [],
  color,
  selectColor,
}) {
  const [panelIsVisible, setPanelIsVisible] = useState(false);
  const panelRef = useRef();

  useEffect(() => {
    function handleClickOutsidePanel(event) {
      if (panelRef.current && !panelRef.current.contains(event.target)) {
        setPanelIsVisible(false);
      }
    }

    if (panelIsVisible) {
      document.addEventListener("mousedown", handleClickOutsidePanel);
    } else {
      document.removeEventListener("mousedown", handleClickOutsidePanel);
    }

    return () =>
      document.removeEventListener("mousedown", handleClickOutsidePanel);
  }, [panelIsVisible]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: ".6rem 1.25rem",
        width: "100%",
        background: "#FFFFFF",
        border: "1px solid #AAAAAA",
        borderRadius: ".5rem",
      }}
    >
      <p style={{ flex: 1, alignItems: "center", marginBottom: "0" }}>
        {color}
      </p>

      <div
        style={{
          cursor: "pointer",
          width: "2rem",
          height: "2rem",
          maxWidth: "2rem",
          maxHeight: "2rem",
          backgroundColor: color,
          borderRadius: ".25rem",
        }}
        onClick={() => setPanelIsVisible(true)}
      ></div>

      {panelIsVisible && (
        <div
          ref={panelRef}
          style={{
            zIndex: 5,
            position: "absolute",
            top: "4.5rem",
            left: "0",
            width: "fit-content",
            height: "fit-content",
            maxWidth: "30rem",
            maxHeight: "200px",
            overflowY: "auto",
            background: "rgb(143, 143, 143)",
            border: "none",
            borderRadius: ".5rem",
            padding: "1rem",
            display: "flex",
            gap: ".35rem",
            flexWrap: "wrap",
            rowGap: ".35rem",
          }}
        >
          {colorPalette.map((c, i) => (
            <div
              key={`${c}-${i}`}
              style={{
                cursor: "pointer",
                width: "1.8rem",
                height: "1.8rem",
                maxWidth: "2rem",
                maxHeight: "2rem",
                backgroundColor: c,
                borderRadius: ".25rem",
              }}
              onMouseDown={() => selectColor(c)}
              onMouseUp={() => setPanelIsVisible(false)}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
}
