import React, { useEffect, useState } from "react";
import { database } from "../libs/estrylib/Firebase";
import { SeenNotificationsFirebaseKey } from "../libs";
import friendlyTime from "friendly-time";

const ITQNotificationItem = ({ key, notification, fbid, lang, history }) => {
  const [seen, setSeen] = useState(false);
  const seenNotifcationsFirebaseRef = database
    .ref()
    .child(SeenNotificationsFirebaseKey);
  seenNotifcationsFirebaseRef
    .child(fbid)
    .child(notification.id)
    .on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        if (!seen) {
          setSeen(true);
        }
      }
    });
  const calculateElapsedTime = (time) => {
    const date = new Date(time);
    return friendlyTime(date);
  };
  return (
    <li
      className="notify-card-notification-item"
      style={{ backgroundColor: seen ? "white" : "#f5f5f5" }}
      onClick={() => {
        seenNotifcationsFirebaseRef
          .child(fbid)
          .child(notification.id)
          .set(true);
      }}
    >
      <div
        className="notify-card-notification-item-text"
        style={{ marginLeft: "10px" }}
      >
        <h2 className="inter inter-medium" style={{ fontSize: "16px" }}>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              switch (notification.relateditemtype) {
                case "Lead":
                  window.open(
                    `/app/kanban?leadid=${notification.relateditemid}`,
                    "_blank"
                  );
                  break;
                case "Application":
                  history.pushReplace("/app/applications/view-all");
                  break;
              }
            }}
          >
            {window.sys_app_translate(notification.text, lang)}
          </a>
        </h2>
        <span>{calculateElapsedTime(notification.time)}</span>
      </div>
    </li>
  );
};

export default ITQNotificationItem;
