/**
 * Rct Theme Provider
 */
import React, { Component, Fragment } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { connect } from "react-redux";

// themes
import primaryTheme from "./themes/primaryTheme";

class RctThemeProvider extends Component {
  render() {
    const { rtlLayout, children } = this.props;
    let theme = primaryTheme;
    if (rtlLayout) {
      theme.direction = "rtl";
    } else {
      theme.direction = "ltr";
    }
    return (
      <MuiThemeProvider theme={theme}>
        <Fragment>{children}</Fragment>
      </MuiThemeProvider>
    );
  }
}

// map state to props
const mapStateToProps = ({ settings }) => {
  return settings;
};

export default connect(mapStateToProps)(RctThemeProvider);
