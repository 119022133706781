import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  _getCheckbox,
  _getNormalInput,
  _getSelectInput,
} from "../../../libs/FormFields";
import Button from "@material-ui/core/Button";
import { AppConfig, PhaseID, PLeadsBulkChangeResponsibleUser, PLeadsChangeResponsibleUser, PSectionLeads } from "../../../constants";
import axios from "../../../axios";
import { DropdownDataFetcher } from "../../../libs";
// import Block from "@uiw/react-color-block";

const LabelFormComponent = ({
  showMessage,
  handleAppError,
  lang,
  submit,
  errorObj,
  toPhaseId,
  props,
  usersForDropdown,
  permissions,
}) => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: {},
  });
  const [labelsForDropdown, setLabelsForDropdown] = useState(null);
  useEffect(() => {
    console.log("getting labels for phase ..." + toPhaseId);
    DropdownDataFetcher.getPhasesLabelsForDropdown(props, toPhaseId).then(
      (labels) => {
        setLabelsForDropdown(labels.get);
      }
    );
  }, []);
  useEffect(() => {
    if (errorObj) {
      console.log("setting errors ...");
      Object.keys(errorObj).forEach((key) => {
        setError(key, { type: "custom", message: errorObj[key][0] });
      });
    }
  }, [errorObj]);

  const onSubmit = (values) => {
    submit(values.label.value, values.note, values.responsibleuserid ?
      values.responsibleuserid.value : null);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="container-fluid"
          style={{ backgroundColor: "white", borderRadius: "25px" }}
        >
          {labelsForDropdown && (
            <div className="row d-flex justify-content-start form-row">
              {_getSelectInput({
                control: control,
                inputName: "label",
                options: labelsForDropdown,
                errors: errors,
                inputTitle: "Label",
                width: "col-12",
                lang,
              })}
            </div>
          )}
          {permissions[PSectionLeads][PLeadsChangeResponsibleUser] && usersForDropdown && (
            <div className="row d-flex justify-content-start form-row">
              {_getSelectInput({
                control: control,
                inputName: "responsibleuserid",
                options: usersForDropdown,
                errors: errors,
                inputTitle: "Responsible User",
                width: "col-12",
                lang,
                required: false
              })}
            </div>
          )}
          {PhaseID.Qualified === toPhaseId && (
            <div className="row d-flex justify-content-center form-row">
              {_getNormalInput({
                control,
                inputName: "note",
                errors,
                inputTitle: "Note",
                lang,
                type: "textarea",
                preventNonLatinLetters: false,
                capitalize: false,
                width: "col-12",
                bottomAlign: true,
                prefix: "clarity:note-solid",
                required: true,
              })}
            </div>
          )}
          <div
            className="row d-flex justify-content-center form-row"
            style={{ marginTop: "1rem" }}
          >
            <div className="col-10 offset-1 col-sm-6 offset-sm-0 d-flex justify-content-center">
              <Button
                variant="contained"
                style={{
                  backgroundColor: AppConfig.themeColors.secondary,
                  color: "white",
                  display: "inline",
                  width: "13rem",
                }}
                className="inter inter-bold"
                type="submit"
                onClick={(e) => { }}
              >
                {window.sys_app_translate("Confirm", lang)}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default LabelFormComponent;
