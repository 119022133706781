import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  _getCheckbox,
  _getNormalInput,
  _getPhoneInput,
  _getPhoneInputV2,
  _getSelectInput,
} from "../../../libs/FormFields";
import Button from "@material-ui/core/Button";
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "../../../axios";
import { GeneralHelper } from "../../../libs";
import { AppConfig } from "../../../constants";

const QuickLeadCard = ({
  showMessage,
  handleAppError,
  lang,
  submit,
  isRootAgency,
  setLoading,
  record,
  errorObj,
  phase,
  setShowQuickLeadForm,
}) => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: {
      quickname: record.name,
      phone: record.phone,
    },
  });
  useEffect(() => {
    if (errorObj) {
      console.log("setting errors ...");
      Object.keys(errorObj).forEach((key) => {
        setError(key, { type: "custom", message: errorObj[key][0] });
      });
    }
  }, [errorObj]);
  const [state, setState] = useState({
    phone: record.phone,
  });
  const onSubmit = async (values) => {
    try {
      const record = {};
      record.quickname = values.quickname;
      //record.phone = values.phone;
      record.phone = state.phone;
      record.phaseid = phase.id;
      await axios.post(
        "leads/create",
        GeneralHelper.getFormDataFromModelObject(record, false)
      );
      setShowQuickLeadForm(false);
    } catch (error) {
      handleAppError(error);
    }
  };

  return (
    <React.Fragment>
      <div className="leads-sec">
        <div className="lead-card lead-card-no-hover">
          <div className="card-header">
            <div>
              <Icon icon={"mdi:account-plus-outline"} />
              {window.sys_app_translate("Quick Lead", lang)}
            </div>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className="container-fluid"
                style={{ backgroundColor: "white", borderRadius: "25px" }}
              >
                <div className="row d-flex justify-content-center form-row">
                  {_getNormalInput({
                    control: control,
                    inputName: "quickname",
                    inputTitle: "Quick Name",
                    inputId: "QuickName",
                    errors: errors,
                    lang: lang,
                    width: "col-12",
                    inputSize: "medium",
                    required: true,
                    type: "text",
                    preventNonLatinLetters: false,
                  })}
                </div>
                <div className="row d-flex justify-content-center form-row">
                  {_getPhoneInputV2({
                    international: true,
                    defaultCountry: "TR",
                    value: state.phone,
                    onChange: (phone) => {
                      setState({
                        ...state,
                        phone: phone,
                      });
                    },
                    lang: lang,
                    width: "col-12",
                    errors: errors,
                    register: register,
                    inputName: "phone",
                  })}
                </div>

                <div
                  className="row d-flex justify-content-center form-row"
                  style={{ marginTop: "1rem" }}
                >
                  <div className="col-10 offset-1 col-sm-6 offset-sm-0 d-flex justify-content-center">
                    <Button
                      variant="contained"
                      fullWidth
                      className="text-white"
                      style={{ backgroundColor: "#ED4C16" }}
                      onClick={() => {
                        setShowQuickLeadForm(false);
                      }}
                    >
                      {window.sys_app_translate("Cancel", lang)}
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      fullWidth
                      className="text-white mx-2"
                      style={{ backgroundColor: AppConfig.themeColors.primary }}
                    >
                      {window.sys_app_translate("Save", lang)}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default QuickLeadCard;
