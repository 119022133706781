/* eslint-disable jsx-a11y/anchor-is-valid */
import "../libs/styles/pagination.scss";

export default function Pagination({
  totalPages,
  currentPage,
  onPageChange,
  lang = "en",
}) {
  const pageNumbers = [currentPage - 1, currentPage, currentPage + 1].filter(
    (page) => page >= 1 && page <= totalPages
  );

  return (
    <ul
      className="pagination pagination-lg justify-content-center"
      style={{ marginBottom: "0px" }}
    >
      <li className={`page-item ${currentPage <= 1 ? "disabled" : ""}`}>
        <a
          className="page-link"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onPageChange(1);
          }}
        >
          {window.sys_app_translate("First", lang)}
        </a>
      </li>
      <li className={`page-item ${currentPage <= 1 ? "disabled" : ""}`}>
        <a
          className="page-link"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onPageChange(currentPage - 1);
          }}
        >
          {window.sys_app_translate("Previous", lang)}
        </a>
      </li>
      {pageNumbers.map((page) => (
        <li
          key={page}
          className={currentPage === page ? "page-item active" : "page-item"}
        >
          <a
            className="page-link"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onPageChange(page);
            }}
          >
            {page}
          </a>
        </li>
      ))}
      <li
        className={`page-item ${currentPage >= totalPages ? "disabled" : ""}`}
      >
        <a
          className="page-link"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onPageChange(currentPage + 1);
          }}
        >
          {window.sys_app_translate("Next", lang)}
        </a>
      </li>
      <li
        className={`page-item ${currentPage >= totalPages ? "disabled" : ""}`}
      >
        <a
          className="page-link"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onPageChange(totalPages);
          }}
        >
          {window.sys_app_translate("Last", lang)}
        </a>
      </li>
    </ul>
  );
}
