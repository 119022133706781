import { OfflineStorage } from "../../libs/estrylib";
import { FirebaseError } from "firebase/app";
class ErrorsHandler {
  static handleAppError(error) {
    let result = {
      fixed: true,
      title: "",
      message: "",
      logout: false,
    };
    console.log("errors handler point ...");
    console.log(error);
    const lang = OfflineStorage.instance.get("esitqappedu-lang");
    switch (error.name) {
      case "FirebaseError":
        console.log(error.code);
        switch (error.code) {
          case "auth/session-cookie-expired":
          case "auth/id-token-expired":
            OfflineStorage.instance.clear();
            break;
        }
        result.fixed = false;
        result.type = "danger";
        result.title = window.sys_app_translate("Warning", lang);
        result.message = window.sys_app_translate("Livestream Error", lang);
        break;
      case "AxiosError":
        console.log(error.response);
        if (error.response) {
          if (error.response.status) {
            switch (error.response.status) {
              case 400:
                console.log("we are here ...");
                if (
                  window.sys_app_translate(error.response.data.error, lang)
                    .ratios_errors
                ) {
                  result.fixed = false;
                  result.type = "danger";
                  result.title = window.sys_app_translate("Warning", lang);
                  let messageArray = window
                    .sys_app_translate(error.response.data.error, lang)
                    .ratios_errors[0].split(":");
                  result.message =
                    messageArray[1] +
                    " percentage: " +
                    messageArray[2] +
                    "%" +
                    " is larger than parent agency percentage: " +
                    messageArray[3] +
                    "% in " +
                    messageArray[0] +
                    " section";
                } else {
                  result.fixed = false;
                  result.type = "danger";
                  result.title = window.sys_app_translate("Warning", lang);
                  result.message = window.sys_app_translate(
                    error.response.data.error,
                    lang
                  );
                }
                break;
              //unauthorized
              case 401:
                result.logout = true;
                if (window.location.pathname === "/signin") {
                  result.logout = false;
                }
                result.fixed = false;
                result.type = "danger";
                result.title = window.sys_app_translate("Warning", lang);
                result.message = window.sys_app_translate(
                  error.response.data.error
                    ? error.response.data.error
                    : error.response.data.message,
                  lang
                );
                break;
              //data entered wrong
              case 422:
                result.fixed = false;
                result.type = "warning";
                result.title = window.sys_app_translate("Warning", lang);
                if (error.response.data.error) {
                  result.message = window.sys_app_translate(
                    error.response.data.error,
                    lang
                  );
                }
                if (error.response.data.errors) {
                  result.isFormErrors = true;
                  result.errors = error.response.data.errors;
                  result.message = window.sys_app_translate(
                    error.response.data.message,
                    lang
                  );
                }
                break;
              case 403:
                result.fixed = false;
                result.type = "warning";
                result.title = "Warning";
                result.isFormErrors = false;
                result.message = window.sys_app_translate(
                  error.response.data.message,
                  lang
                );
                break;
              //internal server error
              case 500:
                break;
              default:
                break;
            }
          } else {
            switch (error.code) {
              case "ERR_NETWORK":
                result.fixed = false;
                result.title = window.sys_app_translate(
                  "Connection Unavailable",
                  lang
                );
                result.message = window.sys_app_translate(
                  "Please check your network connection.",
                  lang
                );
                result.type = "danger";
                result.logout = false;
                break;
              default:
                result.fixed = false;
                result.type = "danger";
                result.title = window.sys_app_translate(
                  "Temporary Error",
                  lang
                );
                result.message = error.message;
                break;
            }
          }
        }
        break;
    }

    return result;
  }
}
export default ErrorsHandler;
