import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import {
  setLoading,
  showMessage,
  setCurrentRecord,
  handleAppError,
  clearFormErrors,
} from "../../actions";
import MyCalendar from "./components/Calendar";
import "./index.scss";
import axios from "../../axios";
import { ActionsBar, Header, NavigationBar } from "../../components/CRM";
import { DropdownDataFetcher } from "../../libs";

const Calendar = (props) => {
  const [searchNeedle, setSearchNeedle] = useState("");
  const [usersForDropDown, setUsersForDropDown] = useState([]);
  const [queryResponsibleUserId, setQueryResponsibleUserId] = useState(null);
  const [events, setEvents] = useState();
  const [refreshCount, setRefreshCount] = useState(0);
  const [calendarContainerHeight, setCalendarContainerHeight] = useState(0);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [state, setState] = useState({
    showPhaseFormModal: false,
    phaseRecord: null,
    isPhaseUpdate: false,
    //related to lead phase change
    showLeadsPhaseChangeModal: false,
    selectedPhaseId: null,
    //filters
    showFiltersModal: false,
    filters: {},
  });
  // Function to fetch leads by stage
  const fetchTasks = async () => {
    console.log("fetching tasks ...");
    const { handleAppError } = props;
    try {
      const response = await axios.get("tasks/get-all");
      const _events = response.data.data.map((item) => ({
        ...item,
        title: item.leadname ? item.leadname + "-" + item.title : item.title,
        start: new Date(item.date),
        end: new Date(item.date),
        created_at: new Date(item.created_at),
        updated_at: new Date(item.updated_at),
      }));
      setEvents(_events);
    } catch (error) {
      handleAppError(error);
    }
  };
  const refresh = () => {
    setRefreshCount((val) => val + 1);
  };
  useEffect(() => {
    fetchTasks();
  }, [refreshCount]);
  useEffect(() => {
    refresh();
  }, [queryResponsibleUserId, searchNeedle]);
  const calculateScrollHeight = () => {
    console.log("calculating calendar scroll height ...");
    const pageHeaderElement = document.getElementsByClassName("header")[0];
    const calendarHeader1Element =
      document.getElementsByClassName("calendar-header-1")[0];
    const calendarHeader2Element =
      document.getElementsByClassName("calendar-header-2")[0];
    let totalPageHeaderHeight = 0;
    if (pageHeaderElement) {
      const pageHeaderStyles = window.getComputedStyle(pageHeaderElement);
      const pageHeaderHeight = pageHeaderElement.clientHeight;
      totalPageHeaderHeight = pageHeaderHeight;
    }
    let totalCalendarHeader1Height = 0;
    if (calendarHeader1Element) {
      const calendarHeader1Styles = window.getComputedStyle(
        calendarHeader1Element
      );
      const calendarHeader1Height = calendarHeader1Element.clientHeight;
      const calendarHeader1MarginTop = parseInt(
        calendarHeader1Styles.marginTop,
        10
      );
      const calendarHeader1MarginBottom = parseInt(
        calendarHeader1Styles.marginBottom,
        10
      );
      totalCalendarHeader1Height =
        calendarHeader1Height +
        calendarHeader1MarginTop +
        calendarHeader1MarginBottom;
    }
    let totalCalendarHeader2Height = 0;
    if (calendarHeader2Element) {
      const calendarHeader2Styles = window.getComputedStyle(
        calendarHeader2Element
      );
      const calendarHeader2Height = calendarHeader2Element.clientHeight;
      const calendarHeader2MarginTop = parseInt(
        calendarHeader2Styles.marginTop,
        10
      );
      const calendarHeader2MarginBottom = parseInt(
        calendarHeader2Styles.marginBottom,
        10
      );
      totalCalendarHeader2Height =
        calendarHeader2Height +
        calendarHeader2MarginTop +
        calendarHeader2MarginBottom;
    }
    let calendarBoardElement =
      document.getElementsByClassName("calendar-con")[0];
    let totalCalendarBoardMargins = 0;
    if (calendarBoardElement) {
      const calendarBoardStyles = window.getComputedStyle(calendarBoardElement);
      const calendarBoardPaddingTop = parseInt(
        calendarBoardStyles.paddingTop,
        10
      );
      const calendarBoardPaddingBottom = parseInt(
        calendarBoardStyles.paddingBottom,
        10
      );
      const calendarBoardMarginTop = parseInt(
        calendarBoardStyles.marginTop,
        10
      );
      totalCalendarBoardMargins +=
        calendarBoardPaddingTop + calendarBoardPaddingBottom;
    }
    setCalendarContainerHeight(window.innerHeight - totalPageHeaderHeight);
  };
  useEffect(() => {
    DropdownDataFetcher.getUsersForDropdown(props).then((data) => {
      setUsersForDropDown(data);
    });
    if (
      document.readyState === "complete" ||
      document.readyState === "interactive"
    ) {
      calculateScrollHeight();
      window.addEventListener("resize", calculateScrollHeight);
    } else {
      document.addEventListener("DOMContentLoaded", function () {
        calculateScrollHeight();
        window.addEventListener("resize", calculateScrollHeight);
      });
    }
    return () => {
      window.removeEventListener("resize", calculateScrollHeight);
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{window.sys_app_translate("Calendar", props.user.lang)}</title>
      </Helmet>
      <div
        className="calendar-con"
        style={{
          paddingBottom: "1rem",
          height: calendarContainerHeight + "px",
        }}
      >
        {usersForDropDown && (
          <Header
            props={props}
            usersForDropDown={usersForDropDown.get}
            setQueryResponsibleUserId={setQueryResponsibleUserId}
            setSearchNeedle={setSearchNeedle}
            isSuperAdmin={props.user.is_super_admin}
            showFiltersModal={() => {
              setState({
                ...state,
                showFiltersModal: true,
              });
            }}
          />
        )}
        <ActionsBar
          selectedLeads={selectedLeads}
          setSelectedLeads={setSelectedLeads}
          refresh={refresh}
          props={props}
          changeLeadsPhase={() => {
            setState({
              ...state,
              showLeadsPhaseChangeModal: true,
            });
          }}
          activeTabId={2}
        />
        <div style={{ width: "97%" }}>
          <MyCalendar
            events={events}
            setEvents={setEvents}
            refresh={refresh}
            props={props}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ authUser, appReducer }) => {
  const { user } = authUser;
  const { formErrors, currentRecord, loading } = appReducer;
  return { user, formErrors, currentRecord, loading };
};

export default connect(mapStateToProps, {
  setCurrentRecord,
  setLoading,
  showMessage,
  handleAppError,
  clearFormErrors,
})(Calendar);
