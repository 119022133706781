import { param } from "jquery";
import React, { useState, useEffect } from "react";
import axios from "../../../axios";
import { AppConfig } from "../../../constants";
import moment from "moment";
import { Button } from "@material-ui/core";
import { GeneralHelper, ITQModal } from "../../../libs";
import TaskFormComponent from "./TaskFormComponent";
import { Task } from "../../../models/CRM";

export default function LeadTasks({ handleAppError, leadId, lang, props }) {
  const [tasks, setTasks] = useState([]);
  const [state, setState] = useState({
    showTaskFormModal: false,
  });
  const getTasks = async () => {
    try {
      console.log("getting tasks ..." + leadId);
      const response = await axios.get("tasks/get-all", {
        params: {
          leadid: leadId,
        },
      });
      setTasks(response.data.data);
    } catch (error) {
      handleAppError(error);
    }
  };
  const getTaskFormPopup = () => {
    const { showMessage, setLoading, handleAppError } = props;
    const { formErrors, user } = props;
    return (
      <ITQModal
        isOpen={state.showTaskFormModal}
        isCenterd={true}
        cancelButtonText="x"
        cancelButtonClicked={() => {
          setState({
            ...state,
            showTaskFormModal: false,
          });
        }}
        title={window.sys_app_translate("Task Info", props.user.lang)}
        bodyContent={
          <TaskFormComponent
            showMessage={showMessage}
            handleAppError={handleAppError}
            lang={user.lang}
            submit={async (record) => {
              const { setLoading, showMessage, handleAppError } = props;
              try {
                setLoading(true);
                record.leadid = leadId;
                await axios.post(
                  "tasks/create",
                  GeneralHelper.getFormDataFromModelObject(record, false)
                );
                showMessage({
                  type: "success",
                  title: window.sys_app_translate(
                    "Successful Operation",
                    props.user.lang
                  ),
                  message: window.sys_app_translate(
                    "Task Added Successfully",
                    props.user.lang
                  ),
                });
                setState({
                  ...state,
                  showTaskFormModal: false,
                });
              } catch (error) {
                handleAppError(error);
              }
            }}
            isRootAgency={user.isRootAgency}
            setLoading={setLoading}
            record={new Task()}
            errorObj={formErrors}
          />
        }
        size="md"
      />
    );
  };
  useEffect(() => {
    getTasks();
  }, []);
  return (
    <>
      <div className="row justify-content-start mb-4">
        <div className="col-4 px-0">
          <Button
            variant="contained"
            type="submit"
            fullWidthF
            className="text-white"
            style={{ backgroundColor: AppConfig.themeColors.primary }}
            onClick={() => {
              setState({
                ...state,
                showTaskFormModal: true,
              });
            }}
          >
            {window.sys_app_translate("Create Task", lang)}
          </Button>
        </div>
      </div>
      {tasks.map((task) => {
        return (
          <div key={task.id} className="card my-2">
            <div
              className="card-header inter inter-medium"
              style={{
                backgroundColor: AppConfig.themeColors.primary,
                color: "white",
              }}
            >
              {`${task.title} - ${moment(task.date).format(
                "DD/MM/YYYY hh:mm A"
              )}`}
            </div>
            <div className="card-body inter inter-medium">
              {task.description}
            </div>
          </div>
        );
      })}
      {getTaskFormPopup()}
    </>
  );
}
