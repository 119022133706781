import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  _getCheckbox,
  _getDatePicker,
  _getNormalInput,
  _getSelectInput,
} from "../../../libs/FormFields";
import Button from "@material-ui/core/Button";
import { AppConfig } from "../../../constants";
import moment from "moment";

const FiltersFormComponent = ({
  showMessage,
  handleAppError,
  lang,
  submit,
  isRootAgency,
  setLoading,
  record,
  props,
  filters,
  leadSourcesForDropdownGet,
  labelsForDropdownGet,
  leadLostLabelsForDropdownGet,
  dealLostLabelsForDropdownGet,
}) => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      leadsourceid:
        leadSourcesForDropdownGet && filters && filters.leadsourceid
          ? leadSourcesForDropdownGet.find(
              (x) => x.value === filters.leadsourceid
            )
          : null,
      startdate: filters && filters.startdate ? filters.startdate : null,
      enddate: filters && filters.enddate ? filters.enddate : null,
      modificationstartdate:
        filters && filters.modificationstartdate
          ? filters.modificationstartdate
          : null,
      modificationenddate:
        filters && filters.modificationenddate
          ? filters.modificationenddate
          : null,
    },
  });

  const onSubmit = (values) => {
    const filters = {};
    if (values.startdate) {
      filters.startdate = moment(values.startdate).format("YYYY-MM-DD");
    }
    if (values.enddate) {
      filters.enddate = moment(values.enddate).format("YYYY-MM-DD");
    }
    if (values.modificationstartdate) {
      filters.modificationstartdate = moment(
        values.modificationstartdate
      ).format("YYYY-MM-DD");
    }
    if (values.modificationenddate) {
      filters.modificationenddate = moment(values.modificationenddate).format(
        "YYYY-MM-DD"
      );
    }
    filters.labels = [];
    if (values.label) {
      filters.labels.push(values.label.value);
    }
    if (values.leadlostlabel) {
      filters.labels.push(values.leadlostlabel.value);
    }
    if (values.deallostlabel) {
      filters.labels.push(values.deallostlabel.value);
    }
    if (values.leadsourceid) {
      filters.leadsourceid = values.leadsourceid.value;
    }
    if (filters.labels.length > 0) {
      filters.labels = filters.labels.join(",");
    } else {
      delete filters.labels;
    }
    if (values.hastasks) {
      filters.hastasks = values.hastasks;
    }
    submit(filters);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="container-fluid"
          style={{ backgroundColor: "white", borderRadius: "25px" }}
        >
          <div
            className="row justify-content-center form-row p-4"
            style={{
              border: "1px solid #e0e0e0",
              borderRadius: "25px",
              position: "relative",
            }}
          >
            <span
              style={{
                position: "absolute",
                top: "-1rem",
                left: "2rem",
                backgroundColor: "white",
                width: "fit-content",
              }}
              className="inter inter-medium"
            >
              {window.sys_app_translate("Lead Created On")}
            </span>
            {_getDatePicker({
              control: control,
              inputName: "startdate",
              inputTitle: "Start Date",
              errors: errors,
              lang: lang,
              width: "col-12 col-xl-12 mt-4 my-xl-2",
              prefix: "fa6-regular:calendar-days",
              required: false,
              onOpen: () => {},
              onClose: () => {},
            })}
            {_getDatePicker({
              control: control,
              inputName: "enddate",
              inputTitle: "End Date",
              errors: errors,
              lang: lang,
              width: "col-12 col-xl-12 mt-4 my-xl-2",
              prefix: "fa6-regular:calendar-days",
              required: false,
            })}
          </div>
          <div
            className="row justify-content-center form-row p-4"
            style={{
              border: "1px solid #e0e0e0",
              borderRadius: "25px",
              position: "relative",
            }}
          >
            <span
              style={{
                position: "absolute",
                top: "-1rem",
                left: "2rem",
                backgroundColor: "white",
                width: "fit-content",
              }}
              className="inter inter-medium"
            >
              {window.sys_app_translate("Lead Modified On")}
            </span>
            {_getDatePicker({
              control: control,
              inputName: "modificationstartdate",
              inputTitle: "Start Date",
              errors: errors,
              lang: lang,
              width: "col-12 col-xl-12 mt-4 my-xl-2",
              prefix: "fa6-regular:calendar-days",
              required: false,
            })}
            {_getDatePicker({
              control: control,
              inputName: "modificationenddate",
              inputTitle: "End Date",
              errors: errors,
              lang: lang,
              width: "col-12 col-xl-12 mt-4 my-xl-2",
              prefix: "fa6-regular:calendar-days",
              required: false,
            })}
          </div>
          <div className="row my-4">
            {_getSelectInput({
              control,
              inputName: "leadsourceid",
              options: leadSourcesForDropdownGet,
              errors,
              inputTitle: "Lead Source",
              lang,
              required: false,
              width: "col-12 col-xl-12 mt-4 my-xl-2",
              startIcon: "fa6-regular:bookmark",
              isClearable: true,
            })}
            {_getSelectInput({
              control,
              inputName: "label",
              options: labelsForDropdownGet,
              errors,
              inputTitle: "Leads with Labels",
              lang,
              required: false,
              width: "col-12 col-xl-12 mt-4 my-xl-2",
              startIcon: "fa6-regular:bookmark",
              isClearable: true,
            })}
            {_getSelectInput({
              control,
              inputName: "leadlostlabel",
              options: leadLostLabelsForDropdownGet,
              errors,
              inputTitle: "Lead Lost Reasons",
              lang,
              required: false,
              width: "col-12 col-xl-12 mt-4 my-xl-2",
              startIcon: "fa6-regular:bookmark",
              isClearable: true,
            })}
            {_getSelectInput({
              control,
              inputName: "deallostlabel",
              options: dealLostLabelsForDropdownGet,
              errors,
              inputTitle: "Deal Lost Reasons",
              lang,
              required: false,
              width: "col-12 col-xl-12 mt-4 my-xl-2",
              startIcon: "fa6-regular:bookmark",
              isClearable: true,
            })}
          </div>
          <div className="row my-4">
            {_getCheckbox({
              inputName: "hastasks",
              inputTitle: "Only Tasks",
              inputId: "HasTasks",
              lang: lang,
              register: register,
              width: "col-6 inter inter-medium",
            })}
          </div>
          <div
            className="row d-flex justify-content-end"
            style={{ marginTop: "1rem" }}
          >
            <div className="col-12 offset-1 col-sm-6 offset-sm-0 d-flex justify-content-end">
              <Button
                variant="contained"
                style={{
                  backgroundColor: AppConfig.themeColors.secondary,
                  color: "white",
                  display: "inline",
                  width: "6rem",
                }}
                className="inter inter-bold"
                type="reset"
                onClick={(e) => {
                  submit({});
                }}
              >
                {window.sys_app_translate("Reset", lang)}
              </Button>
              <div className="mx-1"></div>
              <Button
                variant="contained"
                style={{
                  backgroundColor: AppConfig.themeColors.secondary,
                  color: "white",
                  display: "inline",
                  width: "6rem",
                }}
                className="inter inter-bold"
                type="submit"
                onClick={(e) => {}}
              >
                {window.sys_app_translate("Search", lang)}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default FiltersFormComponent;
