import {
  PAgenciesCreate,
  PAgenciesViewAll,
  PAgreementProfitsViewAll,
  PApplicationsCreate,
  PApplicationsViewAll,
  PExpensesCreate,
  PExpensesViewAll,
  PMessageRequestsCreate,
  PMessageRequestsViewAll,
  PReleasedProfitsViewAll,
  PSectionAgencies,
  PSectionAgreementProfits,
  PSectionAnnouncements,
  PSectionApplications,
  PSectionExpenses,
  PSectionMessageRequests,
  PSectionReleasedProfits,
  PSectionSpecializations,
  PSectionStudents,
  PSectionSuppliers,
  PSectionUniversities,
  PSectionUsers,
  PSpecializationsViewAll,
  PStudentsViewAll,
  PSuppliersViewAll,
  PUniversitiesCreate,
  PUniversitiesViewAll,
  PUsersCreate,
  PUsersViewAll,
  PAgreementView,
  PSectionAgreement,
  PApplicationsViewDeadlines,
  PAnnouncementsViewAll,
  PSectionTranslations,
  PTranslationsModify,
  PSectionWhatsAppDevices,
  PWhatsappDevicesViewAll,
  PSectionLeadSources,
  PLeadSourcesViewAll,
  PSectionKnowledgeBase,
  PKnowledgebaseItemsViewAll,
  PKnowledgebaseItemsCreate,
  PSectionMessageTemplates,
  PMessageTemplatesViewAll,
  PSectionRoles,
  PRolesCreate,
  PRolesViewAll,
  PLeadSourcesCreate,
  PSectionHR,
  PHRViewLog,
  PSectionLeads,
  PLeadsViewAll,
} from "../constants";

export default function MainMenu(
  permissions,
  lang,
  isSuperAdmin,
  agencyId,
  topParentAgencyId
) {
  return {
    menu: [
      {
        path: "/home",
        title: window.sys_app_translate("Home", lang),
        subMenu: null,
        icon: "home",
      },
      //specializations
      permissions[PSectionSpecializations][PSpecializationsViewAll]
        ? {
            path: "/specializations/view-all",
            title: window.sys_app_translate("Programs", lang),
            subMenu: null,
            icon: "specializations",
          }
        : null,
      //students
      permissions[PSectionStudents][PStudentsViewAll]
        ? {
            path: "/students/view-all",
            title: window.sys_app_translate("Students", lang),
            subMenu: null,
            icon: "students",
          }
        : null,
      //applications
      permissions[PSectionApplications][PApplicationsViewAll] &&
      permissions[PSectionApplications][PApplicationsCreate]
        ? {
            path: "applications",
            title: window.sys_app_translate("Applications", lang),
            subMenu: null,
            icon: "applications",
            subMenu: [
              permissions[PSectionApplications][PApplicationsCreate] && {
                title: window.sys_app_translate("Apply Now", lang),
                path: "/applications/create",
              },
              permissions[PSectionApplications][PApplicationsViewAll] && {
                title: window.sys_app_translate("Applications List", lang),
                path: "/applications/view-all",
              },
            ],
          }
        : null,
      //universities
      permissions[PSectionUniversities][PUniversitiesViewAll]
        ? {
            path: "universities",
            title: window.sys_app_translate("Universities", lang),
            subMenu: null,
            icon: "universities",
            subMenu: [
              permissions[PSectionUniversities][PUniversitiesCreate] && {
                title: window.sys_app_translate("Add University", lang),
                path: "/universities/create",
              },
              permissions[PSectionUniversities][PUniversitiesViewAll] && {
                title: window.sys_app_translate("Universities List", lang),
                path: "/universities/view-all",
              },
            ],
          }
        : null,
      //released-profits
      topParentAgencyId === agencyId && topParentAgencyId
        ? permissions[PSectionReleasedProfits][PReleasedProfitsViewAll]
          ? {
              path: "agreement-profits",
              title: window.sys_app_translate("Profits", lang),
              subMenu: null,
              icon: "agreement-profits",
              subMenu: [
                permissions[PSectionReleasedProfits][
                  PReleasedProfitsViewAll
                ] && {
                  title: window.sys_app_translate("Released Profits", lang),
                  path: "/released-profits/view-all",
                },
              ],
            }
          : null
        : null,
      //agreement
      topParentAgencyId === agencyId &&
      topParentAgencyId &&
      permissions[PSectionAgreement] &&
      permissions[PSectionAgreement][PAgreementView]
        ? {
            path: "/agreement",
            title: window.sys_app_translate("Agreement", lang),
            subMenu: null,
            icon: "specializations",
          }
        : null,
      //users
      permissions[PSectionUsers][PUsersViewAll] ||
      permissions[PSectionUsers][PUsersCreate]
        ? {
            path: "users",
            title: window.sys_app_translate("Users", lang),
            subMenu: null,
            icon: "users",
            subMenu: [
              permissions[PSectionUsers][PUsersCreate] && {
                title: window.sys_app_translate("Add User", lang),
                path: "/users/create",
              },
              permissions[PSectionUsers][PUsersViewAll] && {
                title: window.sys_app_translate("Users List", lang),
                path: "/users/view-all",
              },
            ],
          }
        : null,
      //agencies
      permissions[PSectionAgencies][PAgenciesViewAll] ||
      permissions[PSectionAgencies][PAgenciesCreate]
        ? {
            path: "agencies",
            title: window.sys_app_translate("Agencies", lang),
            subMenu: null,
            icon: "agencies",
            subMenu: [
              permissions[PSectionAgencies][PAgenciesCreate] && {
                title: window.sys_app_translate("Add Agency", lang),
                path: "/agencies/create",
              },
              permissions[PSectionAgencies][PAgenciesViewAll] && {
                title: window.sys_app_translate("Agencies List", lang),
                path: "/agencies/view-all",
              },
            ],
          }
        : null,
      {
        path: "/logout",
        title: window.sys_app_translate("Logout", lang),
        subMenu: null,
        icon: "logout",
      },
    ],
    adminMenu: [
      {
        path: "/home",
        title: window.sys_app_translate("Home", lang),
        subMenu: null,
        icon: "home",
      },
      //specializations
      permissions[PSectionSpecializations][PSpecializationsViewAll] && {
        path: "/specializations/view-all",
        title: window.sys_app_translate("Programs", lang),
        subMenu: null,
        icon: "specializations",
      },
      //students
      permissions[PSectionStudents][PStudentsViewAll] && {
        path: "/students/view-all",
        title: window.sys_app_translate("Students", lang),
        subMenu: null,
        icon: "students",
      },
      //applications
      permissions[PSectionApplications] &&
        permissions[PSectionApplications][PApplicationsViewAll] && {
          path: "/applications/view-all",
          title: window.sys_app_translate("Applications", lang),
          subMenu: null,
          icon: "applications",
        },
      //deadlines
      permissions[PSectionApplications] &&
        permissions[PSectionApplications][PApplicationsViewDeadlines] && {
          path: "/applications/deadlines",
          title: window.sys_app_translate("DeadLines", lang),
          subMenu: null,
          icon: "deadlines",
        },
      //universities
      permissions[PSectionStudents][PStudentsViewAll] && {
        path: "/universities/view-all",
        title: window.sys_app_translate("Universities", lang),
        subMenu: null,
        icon: "universities",
      },
      //announcements
      permissions[PSectionAnnouncements] &&
      permissions[PSectionAnnouncements][PAnnouncementsViewAll]
        ? {
            path: "/announcements/view-all",
            title: window.sys_app_translate("Announcements", lang),
            subMenu: null,
            icon: "announcements",
          }
        : null,
      //agreement-profits and released-profits
      permissions[PSectionAgreementProfits][PAgreementProfitsViewAll] ||
      permissions[PSectionReleasedProfits][PReleasedProfitsViewAll]
        ? {
            path: "agreement-profits",
            title: window.sys_app_translate("Agreement Profits", lang),
            subMenu: [
              permissions[PSectionAgreementProfits][
                PAgreementProfitsViewAll
              ] && {
                title: window.sys_app_translate("Agreement Profits", lang),
                path: "/agreement-profits/view-all",
              },
              permissions[PSectionAgreementProfits][
                PAgreementProfitsViewAll
              ] && {
                title: window.sys_app_translate("Payments", lang),
                path: "/agreement-profits/payments",
              },
            ],
            icon: "agreement-profits",
          }
        : null,
      //agreement
      permissions[PSectionAgreement] &&
      permissions[PSectionAgreement][PAgreementView]
        ? {
            path: "/agreement",
            title: window.sys_app_translate("Agreement", lang),
            subMenu: null,
            icon: "specializations",
          }
        : null,
      //suppliers
      permissions[PSectionSuppliers][PSuppliersViewAll] && {
        path: "/suppliers/view-all",
        title: window.sys_app_translate("Suppliers", lang),
        subMenu: null,
        icon: "suppliers",
      },
      //users
      permissions[PSectionUsers][PUsersViewAll] && {
        path: "users",
        title: window.sys_app_translate("Users", lang),
        subMenu: null,
        icon: "users",
        subMenu: [
          permissions[PSectionUsers][PUsersCreate] && {
            title: window.sys_app_translate("Add User", lang),
            path: "/users/create",
          },
          permissions[PSectionUsers][PUsersViewAll] && {
            title: window.sys_app_translate("Users List", lang),
            path: "/users/view-all",
          },
          permissions[PSectionRoles][PRolesCreate] && {
            title: window.sys_app_translate("Add User Role", lang),
            path: "/roles/create",
          },
          permissions[PSectionRoles][PRolesViewAll] && {
            title: window.sys_app_translate("User Roles", lang),
            path: "/roles/view-all",
          },
        ],
      },
      //agencies
      //students
      permissions[PSectionAgencies][PAgenciesViewAll] && {
        path: "/agencies/view-all",
        title: window.sys_app_translate("Agencies", lang),
        subMenu: null,
        icon: "agencies",
      },
      //expenses
      permissions[PSectionExpenses] &&
      permissions[PSectionExpenses][PExpensesViewAll]
        ? {
            path: "expenses",
            title: window.sys_app_translate("Expenses", lang),
            icon: "expenses",
            subMenu: [
              permissions[PSectionExpenses][PExpensesCreate] && {
                title: window.sys_app_translate("Add Expense", lang),
                path: "/expenses/create",
              },
              permissions[PSectionExpenses][PExpensesViewAll] && {
                title: window.sys_app_translate("Expenses List", lang),
                path: "/expenses/view-all",
              },
            ],
          }
        : null,
      //translations
      permissions[PSectionTranslations] &&
      permissions[PSectionTranslations][PTranslationsModify]
        ? {
            path: "/translations",
            title: window.sys_app_translate("Translations", lang),
            subMenu: null,
            icon: "translations",
          }
        : null,
      //messages
      permissions[PSectionMessageRequests][PMessageRequestsViewAll] ||
      permissions[PSectionMessageRequests][PMessageRequestsCreate]
        ? {
            path: "message-requests",
            title: window.sys_app_translate("Message Requests", lang),
            subMenu: null,
            icon: "message-requests",
            subMenu: [
              permissions[PSectionMessageRequests][PMessageRequestsCreate] && {
                title: window.sys_app_translate("Add Message Request", lang),
                path: "/messaging",
              },
              permissions[PSectionMessageRequests][PMessageRequestsViewAll] && {
                title: window.sys_app_translate("Message Requests List", lang),
                path: "/message-requests/view-all",
              },
            ],
          }
        : null,
      //kanban
      permissions[PSectionLeads][PLeadsViewAll] && {
        path: "/kanban",
        title: window.sys_app_translate("CRM", lang),
        subMenu: null,
        icon: "kanban",
        enTitle: "CRM",
      },
      //hr
      permissions[PSectionHR] &&
        permissions[PSectionHR][PHRViewLog] && {
          path: "/hr/log",
          title: window.sys_app_translate("HR", lang),
          icon: "hr",
          subMenu: [
            permissions[PSectionHR][PHRViewLog] && {
              title: window.sys_app_translate("View Log", lang),
              path: "/hr/log",
            },
          ],
        },
      //lead-sources
      permissions[PSectionLeadSources] &&
      permissions[PSectionLeadSources][PLeadSourcesViewAll]
        ? {
            path: "lead-sources",
            title: window.sys_app_translate("Lead Sources", lang),
            subMenu: null,
            icon: "lead-sources",
            subMenu: [
              permissions[PSectionLeadSources][PLeadSourcesCreate] && {
                title: window.sys_app_translate("Add Lead Source", lang),
                path: "/lead-sources/create",
              },
              permissions[PSectionLeadSources][PLeadSourcesViewAll] && {
                title: window.sys_app_translate("Lead Sources List", lang),
                path: "/lead-sources/view-all",
              },
            ],
          }
        : null,
      //knowledgebase
      permissions[PSectionKnowledgeBase] &&
      permissions[PSectionKnowledgeBase][PKnowledgebaseItemsViewAll]
        ? {
            path: "knowledgebase",
            title: window.sys_app_translate("Knowledgebase", lang),
            subMenu: null,
            icon: "knowledgebase",
            subMenu: [
              permissions[PSectionKnowledgeBase][PKnowledgebaseItemsCreate] && {
                title: window.sys_app_translate("Add Knowledgebase Item", lang),
                path: "/knowledgebase/create",
              },
              permissions[PSectionKnowledgeBase][
                PKnowledgebaseItemsViewAll
              ] && {
                title: window.sys_app_translate(
                  "Knowledgebase Items List",
                  lang
                ),
                path: "/knowledgebase/view-all",
              },
            ],
          }
        : null,
      //whatsapp devices
      permissions[PSectionWhatsAppDevices] &&
      permissions[PSectionWhatsAppDevices][PWhatsappDevicesViewAll]
        ? {
            path: "whatsapp-devices",
            title: window.sys_app_translate("WP Devices", lang),
            subMenu: null,
            icon: "whatsapp-devices",
            subMenu: [
              permissions[PSectionWhatsAppDevices][PWhatsappDevicesViewAll] && {
                title: window.sys_app_translate("Whatsapp Devices List", lang),
                path: "/whatsapp-devices/view-all",
              },
            ],
          }
        : null,
      //message templates
      permissions[PSectionMessageTemplates] &&
      permissions[PSectionMessageTemplates][PMessageTemplatesViewAll]
        ? {
            path: "message-templates",
            title: window.sys_app_translate("Message Templates", lang),
            subMenu: null,
            icon: "message-templates",
            subMenu: [
              permissions[PSectionKnowledgeBase][PKnowledgebaseItemsCreate] && {
                title: window.sys_app_translate("Add Message Template", lang),
                path: "/message-templates/create",
              },
              permissions[PSectionKnowledgeBase][
                PKnowledgebaseItemsViewAll
              ] && {
                title: window.sys_app_translate("Message Templates List", lang),
                path: "/message-templates/view-all",
              },
            ],
          }
        : null,
      //options
      isSuperAdmin
        ? {
            path: "/options",
            title: window.sys_app_translate("Settings", lang),
            subMenu: null,
            icon: "settings",
          }
        : null,
      {
        path: "/logout",
        title: window.sys_app_translate("Logout", lang),
        subMenu: null,
        icon: "logout",
      },
    ],
  };
}
