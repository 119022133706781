import React from "react";
import { TextField } from "@material-ui/core";

export const TranslationElement = ({ id, str, value }) => {
  return (
    <div className="col-12 col-sm-6 my-2">
      <div className="row">
        <div className="col-6">
          <TextField
            className="itqan-text-field"
            variant="outlined"
            value={str}
            type="text"
            disabled={true}
          />
        </div>
        <div className="col-6">
          <TextField
            className="itqan-text-field"
            variant="outlined"
            name={"i" + id}
            defaultValue={value}
            type="text"
          />
        </div>
      </div>
    </div>
  );
};
