import Home from "../routes/home";
import Agencies from "../routes/agencies";
import Roles from "../routes/roles";
import Users from "../routes/users";
import Translations from "../routes/translations";

import DeveloperTools from "../routes/developer-tools";
import Options from "../routes/options";
import Messaging from "../routes/messaging";
import MessageRequests from "../routes/message-requests";

import Announcements from "../routes/announcements";
import Suppliers from "../routes/suppliers";
import Expenses from "../routes/expenses";
import Universities from "../routes/universities";
import Applications from "../routes/applications";
import AgreementProfits from "../routes/agreement-profits";
import ReleasedProfits from "../routes/released-profits";
import Students from "../routes/students";
import Notifications from "../routes/notifications";
import Specialization from "../routes/specializations";
import Agreement from "../routes/agreement";
import Kanban from "../routes/kanban";
import KanbanList from "../routes/kanban-list";
import Chat from "../routes/chat";
import Calendar from "../routes/calendar";
import WhatsAppDevices from "../routes/whatsapp-devices";
import LeadSources from "../routes/lead-sources";
import MessageTemplates from "../routes/message-templates";
import Departments from "../routes/departments";
import Knowledgebase from "../routes/knowledgebase";
import HR from "../routes/hr";

export default [
  {
    path: "home",
    component: Home,
  },
  {
    path: "expenses",
    component: Expenses,
  },
  {
    path: "agencies",
    component: Agencies,
  },
  {
    path: "roles",
    component: Roles,
  },
  {
    path: "users",
    component: Users,
  },
  {
    path: "announcements",
    component: Announcements,
  },
  {
    path: "notifications",
    component: Notifications,
  },
  {
    path: "suppliers",
    component: Suppliers,
  },
  {
    path: "specializations",
    component: Specialization,
  },
  {
    path: "universities",
    component: Universities,
  },
  {
    path: "applications",
    component: Applications,
  },
  {
    path: "agreement-profits",
    component: AgreementProfits,
  },
  {
    path: "released-profits",
    component: ReleasedProfits,
  },
  {
    path: "students",
    component: Students,
  },
  {
    path: "kanban",
    component: Kanban,
  },
  {
    path: "kanban-list",
    component: KanbanList,
  },
  {
    path: "chat",
    component: Chat,
  },
  {
    path: "calendar",
    component: Calendar,
  },
  {
    path: "translations",
    component: Translations,
  },
  {
    path: "developer-tools",
    component: DeveloperTools,
  },
  {
    path: "options",
    component: Options,
  },
  {
    path: "messaging",
    component: Messaging,
  },
  {
    path: "message-requests",
    component: MessageRequests,
  },
  {
    path: "agreement",
    component: Agreement,
  },
  {
    path: "whatsapp-devices",
    component: WhatsAppDevices,
  },
  {
    path: "lead-sources",
    component: LeadSources,
  },
  {
    path: "message-templates",
    component: MessageTemplates,
  },
  {
    path: "departments",
    component: Departments,
  },
  {
    path: "knowledgebase",
    component: Knowledgebase,
  },
  {
    path: "hr",
    component: HR,
  },
];
