import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button } from "@material-ui/core";
import axios from "../../../axios";
import { _getNormalInput } from "../../../libs";

export const NoteFormComponent = ({
  leadId,
  errorObj,
  lang,
  handleAppError,
  refresh,
}) => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
  } = useForm({
    defaultValues: {},
  });
  useEffect(() => {
    if (errorObj) {
      console.log("setting errors ...");
      Object.keys(errorObj).forEach((key) => {
        setError(key, { type: "custom", message: errorObj[key][0] });
      });
    }
  }, [errorObj]);
  const validationMessages = (error, fieldName = "This field") => {
    if (error.type === "required") {
      return (
        window.sys_app_translate(fieldName, lang) +
        " " +
        window.sys_app_translate("is required", lang)
      );
    }
    if (error.type === "pattern") {
      switch (fieldName) {
        default:
          return (
            window.sys_app_translate(fieldName, lang) +
            " " +
            window.sys_app_translate("is not correct", lang)
          );
      }
    }
    if (error.type === "custom") {
      return error.message;
    }
  };
  const onSubmit = async (values) => {
    console.log("submitting note ...");
    const record = {};
    record.note = values.note;
    try {
      await axios.post(`leads/${leadId}/notes/create`, record);
      refresh();
    } catch (error) {
      handleAppError(error);
    }
    return;
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/*note*/}
        <div className="row d-flex justify-content-center form-row">
          {_getNormalInput({
            control,
            inputName: "note",
            errors,
            inputTitle: "Note",
            lang,
            type: "textarea",
            preventNonLatinLetters: false,
            capitalize: false,
            width: "col-12",
            bottomAlign: true,
            prefix: "clarity:note-solid",
            required: false,
          })}
        </div>
        {/*Submit button*/}
        <div
          className="row d-flex justify-content-start form-row"
          style={{ marginTop: "1rem" }}
        >
          <div className="col-10 offset-1 col-sm-6 offset-sm-0 d-flex justify-content-start">
            <Button
              variant="contained"
              type="submit"
              className="text-white"
              style={{ backgroundColor: "#21827F", width: "100px" }}
            >
              {window.sys_app_translate("Save", lang)}
            </Button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};
