import axios from "axios";
import AppConfig from "../constants/AppConfig";
import OfflineStorage from "../libs/estrylib/OfflineStorage";

const instance = axios.create({
  baseURL: AppConfig.apiLink,
});

instance.interceptors.request.use((request) => {
  if (request.url !== "/auth/login") {
    request.headers.Authorization = OfflineStorage.instance.get(
      "esitqappedu-access-token"
    );
  }
  return request;
});

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    console.log("log headers");
    //console.log(response.headers);
    return response;
  },
  function (error) {
    // if (error.code.status === 400) {
    //   return Promise.reject({
    //     message: "You've recieved an error!"
    //   })
    // }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
window.axiosI = instance;
export default instance;
