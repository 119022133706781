import React, { useState, useEffect } from "react";
import {
  MenuItemBalanceRequestsIcon,
  MenuItemHomeIcon,
  MenuItemSettingsIcon,
  MenuItemReportsIcon,
  MenuItemPaperReqesutsIcon,
  MenuItemInsurancesIcon,
  MenuItemUsersIcon,
  MenuItemInsuranceCompaniesIcon,
  MenuItemAgenciesIcon,
  MenuItemTranslationsIcon,
  MenuItemReceivedInsurancesIcon,
  MenuItemLeadsIcon,
  MenuItemMessageRequestsIcon,
  MenuItemResidenceAppointments,
  MenuItemApplicationsIcon,
  MenuItemSuppliersIcon,
  MenuItemAgreementsIcon,
  MenuItemHRIcon,
  AppConfig,
} from "../../../constants";
import { NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import { MdKeyboardArrowDown } from "react-icons/md";
import $ from "jquery";
import { database } from "../../../libs/estrylib/Firebase";
import { FirebaseNotificationsBySectionKey } from "../../../libs/FirebaseKeys";

export const MenuItemElement = ({
  path,
  title,
  subMenu = null,
  icon,
  index,
  fbid,
  logout,
  enTitle = null,
}) => {
  const firebaseMenusRef = database
    .ref()
    .child(FirebaseNotificationsBySectionKey)
    .child("users")
    .child(fbid)
    .child("menus");
  const [menuItemNotificationsCount, setMenuItemNotificationsCount] =
    useState(0);
  const getIcon = (iconTitle) => {
    let iconElement = null;
    switch (iconTitle) {
      case "home":
        iconElement = <MenuItemHomeIcon />;
        break;
      case "insurances":
        iconElement = <MenuItemInsurancesIcon />;
        break;
      case "received-insurances":
        iconElement = <MenuItemReceivedInsurancesIcon />;
        break;
      case "balance-requests":
        iconElement = <MenuItemBalanceRequestsIcon />;
        break;
      case "paper-requests":
        iconElement = <MenuItemPaperReqesutsIcon />;
        break;
      case "reports":
        iconElement = <MenuItemReportsIcon />;
        break;
      case "users":
        iconElement = <MenuItemUsersIcon />;
        break;
      case "settings":
        iconElement = <MenuItemSettingsIcon />;
        break;
      case "insurance-companies":
        iconElement = <MenuItemInsuranceCompaniesIcon />;
        break;
      case "deadlines":
        iconElement = <Icon icon="ion:calendar" fontSize={"1.4rem"} />;
        break;
      case "profits":
        iconElement = (
          <Icon
            icon={"fa6-solid:sack-dollar"}
            color={"white"}
            fontSize={"1.33rem"}
            role="button"
          />
        );
        break;
      case "financial-requests":
        iconElement = <MenuItemInsurancesIcon />;
        break;
      case "agencies":
        iconElement = <MenuItemAgenciesIcon />;
        break;
      case "translations":
        iconElement = <MenuItemTranslationsIcon />;
        break;
      case "expenses":
        iconElement = <MenuItemInsurancesIcon />;
        break;
      case "announcements":
        iconElement = (
          <Icon
            icon={"fa6-solid:bullhorn"}
            color={"white"}
            fontSize={"1.33rem"}
            role="button"
          />
        );
        break;
      case "leads":
        iconElement = <MenuItemLeadsIcon />;
        break;
      case "message-requests":
        iconElement = <MenuItemMessageRequestsIcon />;
        break;
      case "residence-appointments":
        iconElement = <MenuItemResidenceAppointments />;
        break;
      case "hr":
        iconElement = <MenuItemHRIcon />;
        break;
      case "students":
        iconElement = (
          <Icon
            icon={"fa6-solid:user-graduate"}
            color={"white"}
            fontSize={"1.33rem"}
            role="button"
          />
        );
        break;
      case "applications":
        iconElement = <MenuItemApplicationsIcon />;
        break;
      case "agreement-profits":
        iconElement = (
          <Icon
            icon={"fa6-solid:sack-dollar"}
            color={"white"}
            fontSize={"1.33rem"}
            role="button"
          />
        );
        break;
      case "specializations":
        iconElement = (
          <Icon
            icon={"fa6-solid:file-contract"}
            color={"white"}
            fontSize={"1.33rem"}
            role="button"
          />
        );
        break;
      case "Agreement":
        iconElement = (
          <Icon
            icon={"fa6-solid:book-open-reader"}
            color={"white"}
            fontSize={"1.33rem"}
            role="button"
          />
        );
        break;
      case "universities":
        iconElement = (
          <Icon
            icon={"bi:building-fill"}
            color={"white"}
            fontSize={"1.33rem"}
            role="button"
          />
        );
        break;
      case "suppliers":
        iconElement = <MenuItemSuppliersIcon />;
        break;
      case "agreements":
        iconElement = <MenuItemAgreementsIcon />;
        break;
      case "lead-sources":
        iconElement = (
          <Icon
            icon={"cib:campaign-monitor"}
            color={"white"}
            fontSize={"1.33rem"}
            role="button"
          />
        );
        break;
      case "whatsapp-devices":
        iconElement = (
          <Icon
            icon={"clarity:mobile-solid"}
            color={"white"}
            fontSize={"1.33rem"}
            role="button"
          />
        );
        break;
      case "knowledgebase":
        iconElement = (
          <Icon
            icon={"game-icons:gift-of-knowledge"}
            color={"white"}
            fontSize={"1.44rem"}
            role="button"
          />
        );
        break;
      case "message-templates":
        iconElement = (
          <Icon
            icon={"fluent:calendar-template-24-filled"}
            color={"white"}
            fontSize={"1.33rem"}
            role="button"
          />
        );
        break;
      case "kanban":
        iconElement = (
          <Icon
            icon={"bi:kanban-fill"}
            color={"white"}
            fontSize={"1.33rem"}
            role="button"
          />
        );
        break;
      default:
        iconElement = null;
    }
    return iconElement;
  };
  const getNotificationsCount = () => {
    try {
      console.log("getting notifications count ...");
      if (enTitle) {
        const lowerCaseMenuItem = enTitle.toLowerCase(),
          res = firebaseMenusRef.child(lowerCaseMenuItem);
        //get value at this node
        res.on("value", (snapshot) => {
          const obj = snapshot.val();
          //get children count
          let count = 0;
          for (let key in obj) {
            count++;
          }
          setMenuItemNotificationsCount(count > 0 ? count : null);
        });
      }
    } catch (error) {
      //handle error
      console.log(error);
    }
  };
  useEffect(() => {
    getNotificationsCount();
    return () => {
      console.log("uloading menu item ...");
      firebaseMenusRef.off("value");
    };
  }, []);
  return (
    <li className="nav-item" key={title}>
      {!subMenu ? (
        <li
          className="nav-item"
          key={"MenuItem" + index}
          onClick={() => {
            if (path === "/logout") {
              logout();
            }
          }}
        >
          {path === "/logout" ? (
            <>
              <a
                onClick={() => {
                  logout();
                }}
                className="logout-btn"
              >
                <Icon
                  icon={"ic:baseline-logout"}
                  color={"white"}
                  fontSize={"1.33rem"}
                  role="button"
                />
                {getIcon(icon)}
                <span className="title">{title}</span>
              </a>
            </>
          ) : (
            <>
              <NavLink
                id={"MenuItemN" + index}
                to={"/app" + path}
                onClick={() => {
                  console.log("menu item clicked  ...");
                  if (enTitle) {
                    firebaseMenusRef.child(enTitle.toLowerCase()).set({});
                  }
                }}
              >
                {getIcon(icon)}
                <span className="title">{title}</span>
                {menuItemNotificationsCount ? (
                  <span
                    style={{
                      backgroundColor: AppConfig.themeColors.danger,
                      padding: "2px 8px",
                      borderRadius: "50%",
                      fontSize: ".9rem",
                    }}
                    className="inter inter-medium"
                  >
                    {menuItemNotificationsCount}
                  </span>
                ) : null}
              </NavLink>
            </>
          )}
        </li>
      ) : (
        <>
          <li
            className="nav-item menu-item-n"
            key={"MenuItem" + index}
            id={"MenuItemC" + index}
          >
            <NavLink
              id={"MenuItemN" + index}
              to={path}
              onClick={(e) => {
                e.preventDefault();
                if (window.innerWidth >= 576) {
                  window.burgerButtonClicked(true);
                }
                $(".menu-item-n .angle")
                  .not("#MenuItemC" + index + " .angle")
                  .removeClass("active");
                $(".menu-item-n .sub-menu")
                  .not("#MenuItemC" + index + " .sub-menu")
                  .removeClass("show");
                //toggle submenu
                $("#MenuItemN" + index + " .angle").toggleClass("active");
                $(".sub-menu" + index).toggleClass("show");
              }}
            >
              {getIcon(icon)}
              <span className="title">{title}</span>
              <span className="angle">
                <MdKeyboardArrowDown />
              </span>
            </NavLink>
            {/* submenu */}
            <ul className={"sub-menu sub-menu" + index}>
              {subMenu.map((submenuItem, index) => {
                if (!submenuItem) {
                  return null;
                }
                return (
                  <li className="nav-item" key={"SubMenuItem" + index}>
                    <NavLink
                      id={"SubMenuItemN" + index}
                      to={"/app" + submenuItem.path}
                      onClick={() => {
                        $(".sidebar-container").removeClass(
                          "sidebar-container-show"
                        );
                        $(window).unbind("click");
                      }}
                    >
                      <span className="title">{submenuItem.title}</span>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </li>
        </>
      )}
    </li>
  );
};
