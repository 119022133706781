import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import axios from "../../../axios";
import { GeneralHelper, ITQModal } from "../../../libs";
import LabelFormComponent from "./LabelFormComponent";

export default function PhaseSteps({
  selectedPhaseId,
  setSelectedPhaseId,
  lead_id,
  phases,
  props,
}) {
  const [state, setState] = useState({
    showLabelFormModal: false,
    leadId: null,
    label: null,
    note: null,
  });
  useEffect(() => {
    if (state.label) {
      updateLead(selectedPhaseId);
    }
  }, [state.label]);
  const updateLead = async (new_phase_id) => {
    const { handleAppError } = props;
    try {
      await axios.post(
        `leads/change-phase/${lead_id}`,
        GeneralHelper.getFormDataFromModelObject(
          {
            phaseid: new_phase_id,
            orderno: 1,
            insideeditleadui: true,
            label: state.label,
            note: state.note,
          },
          false
        )
      );
    } catch (error) {
      handleAppError(error);
    }
  };
  const getLabelFormPopup = () => {
    const { showMessage, handleAppError, formErrors, user } = props;
    const { showLabelFormModal } = state;
    const { lang } = user;
    return (
      <ITQModal
        isOpen={showLabelFormModal}
        isCenterd={true}
        cancelButtonText="x"
        cancelButtonClicked={() => {
          setState({
            ...state,
            showLabelFormModal: false,
          });
        }}
        bodyContent={
          <LabelFormComponent
            showMessage={showMessage}
            handleAppError={handleAppError}
            lang={lang}
            submit={(label, note) => {
              setState({
                ...state,
                label: label,
                note: note,
                showLabelFormModal: false,
              });
            }}
            errorObj={formErrors}
            leadId={lead_id}
            toPhaseId={selectedPhaseId}
            props={props}
            permissions={props.user.permissions}
          />
        }
        size="md"
      />
    );
  };
  return (
    <div className="phase-steps">
      {phases &&
        phases.map((phase) => {
          return (
            <div
              key={phase.id + "phase-step"}
              className={`step phase-step ${
                phase.id === selectedPhaseId ? "active-phase-step" : ""
              }`}
              role="button"
              onClick={() => {
                console.log("changing phase");
                setSelectedPhaseId(phase.id);
                if (lead_id) {
                  setState({
                    ...state,
                    showLabelFormModal: true,
                    newOrderId: 1,
                  });
                }
              }}
            >
              <Icon
                icon={phase.icon}
                style={{
                  color: phase.color,
                }}
              />
              <div
                style={{
                  color: phase.color,
                }}
                className="inter inter-medium"
              >
                {phase.name.toUpperCase()}
              </div>
            </div>
          );
        })}
      {getLabelFormPopup()}
    </div>
  );
}
