class Task {
  id = null;

  title = "";
  type = "";
  priority = "";
  description = "";
  date = "";
  leadid = null;
  attachment = null;
  status = "";

  createdby = null;
  updatedby = null;
}
export default Task;
