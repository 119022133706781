import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import LeadTabs from "./Tabs";
import Tab1Component from "./Tab1Component";
import PhaseSteps from "./PhaseSteps";
import KnowledgebaseListComponent from "./KnowledgebaseListComponent";
import HistoryComponent from "./HistoryComponent";
import NotesComponent from "./NotesComponent";
import { DropdownDataFetcher, ITQModal } from "../../../libs";
import { AppConfig } from "../../../constants";
import { Button } from "@material-ui/core";
import LeadApplications from "./LeadApplications";
import ChatComponent from "../../chat/ChatComponent";
import WhatsappHelper from "../../../libs/WhatsappHelper";
import axios from "../../../axios";
import CommentsComponent from "./CommentsComponent";
import LeadTasks from "./LeadTasks";
import InternalChatComponent from "./InternalChatComponent";

const firstTabs = ["Student Info", "Knowledgebase", "Applications", "Tasks"],
  secondTabs = ["History", "Notes", "Comments", "Chats"];

const EditLeadComponent = ({
  currentLead,
  setCurrentLead,
  phases,
  props,
  errorObj,
  handleAppError,
  setLoading,
  showMessage,
  lang,
  isRootAgency,
  setCurrentRecord,
  usersForDropDown,
}) => {
  const [wpcontactid, setWpcontactid] = useState(0);
  const [wpdeviceUserId, setWpdeviceUserId] = useState(0);
  const [wpDevicePhoneNo, setWpDevicePhoneNo] = useState("");
  const [scrollableHeight, setScrollableHeight] = useState(0);
  const [leadSourcesForDropdown, setLeadSourcesForDropdown] = useState([]);
  const [wpcontactsForDropdown, setWpcontactsForDropdown] = useState([]);
  const [studentsForDropdown, setStudentsForDropdown] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState(currentLead.phaseid);
  const [activeTab, setActiveTab] = useState({
    firstTabs: 0,
    secondTabs: 0,
  });
  const [mainWhatsappDevices, setMainWhatsappDevices] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const toggleDialog = () => {
    setIsOpen(false);
    setCurrentLead(undefined);
  };
  const getMainDevices = async () => {
    try {
      const response = await axios.get("whatsapp/get-main-devices", {
        params: {
          leadid: currentLead.id,
        },
      });
      setMainWhatsappDevices(response.data.data);
    } catch (error) {
      handleAppError(error);
    }
  };
  useEffect(() => {
    if (currentLead) {
      setIsOpen(true);
      setSelectedPhase(currentLead.phaseid);
    }
    DropdownDataFetcher.getLeadSourcesForDropdown(props).then((data) => {
      setLeadSourcesForDropdown(data);
    });
    DropdownDataFetcher.getWPContactsForDropdown(props).then((data) => {
      setWpcontactsForDropdown(data);
    });
    DropdownDataFetcher.getStudentsForDropdown(props, true).then((data) => {
      setStudentsForDropdown(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLead]);
  const calculateScrollHeight = () => {
    //1
    const popupHeader = document.getElementsByClassName("dialog-header")[0];
    const popupHeaderHeight = popupHeader.clientHeight;
    //2
    const popupPhasesRow =
      document.getElementsByClassName("popup-phases-row")[0];
    const popupPhasesRowHeight = popupPhasesRow.clientHeight;
    //3
    const popupTabsHeader = document.getElementsByClassName("tabs-header")[0];
    const popupTabsHeaderHeight = popupTabsHeader.clientHeight;
    //calculate window height
    const windowHeight = window.innerHeight;
    const result =
      windowHeight -
      (popupHeaderHeight + popupPhasesRowHeight + popupTabsHeaderHeight);
    setScrollableHeight(result - 50);
  };
  useEffect(() => {
    getMainDevices();
    calculateScrollHeight();
    window.addEventListener("resize", calculateScrollHeight);
    return () => {
      window.removeEventListener("resize", calculateScrollHeight);
    };
  }, []);

  const tabContent = (whichPart) => {
    switch (whichPart) {
      //section: 1
      case 0:
        switch (activeTab.firstTabs) {
          case 0:
            return leadSourcesForDropdown &&
              usersForDropDown &&
              wpcontactsForDropdown &&
              studentsForDropdown
              ? Tab1Component({
                  history: props.history,
                  handleAppError: handleAppError,
                  lang: lang,
                  record: currentLead,
                  errorObj: errorObj,
                  showMessage: showMessage,
                  setLoading: setLoading,
                  leadSourcesForDropdownGet: leadSourcesForDropdown.get,
                  leadSourcesForDropdownSet: leadSourcesForDropdown.set,
                  usersForDropDownGet: usersForDropDown.get,
                  usersForDropDownSet: usersForDropDown.set,
                  wpcontactsForDropdownGet: wpcontactsForDropdown.get,
                  wpcontactsForDropdownSet: wpcontactsForDropdown.set,
                  studentsForDropdownGet: studentsForDropdown.get,
                  studentsForDropdownSet: studentsForDropdown.set,
                  permissions: props.user.permissions,
                  setCurrentRecord: setCurrentRecord,
                })
              : null;
          case 1:
            return (
              <KnowledgebaseListComponent
                lang={lang}
                handleAppError={handleAppError}
              />
            );
          case 2:
            return (
              <LeadApplications
                handleAppError={handleAppError}
                studentId={currentLead.studentid}
                lang={lang}
              />
            );
          case 3:
            return <LeadTasks leadId={currentLead.id} props={props} />;
          default:
            return Tab1Component();
        }
      //section: 2
      case 1:
        switch (activeTab.secondTabs) {
          case 0:
            return (
              <>
                <div className="d-flex justify-content-center gap-4">
                  {mainWhatsappDevices &&
                    mainWhatsappDevices.map((device) => {
                      return (
                        <>
                          <Button
                            variant="contained"
                            type="submit"
                            fullWidth
                            className="text-white"
                            style={{
                              backgroundColor: AppConfig.themeColors.primary,
                            }}
                            onClick={async () => {
                              const {
                                handleAppError,
                                setLoading,
                                showMessage,
                              } = props;
                              const { lang } = props.user;
                              if (currentLead.wpdevicecontactid) {
                                try {
                                  setLoading(true);
                                  //get default device
                                  await axios.get(
                                    "whatsapp/get-state/" + device.id
                                  );
                                  setWpdeviceUserId(device.userid);
                                  setWpcontactid(currentLead.wpdevicecontactid);
                                  setWpDevicePhoneNo(device.phone);
                                  setTimeout(() => {
                                    setShowChatModal(true);
                                  }, 300);
                                  setLoading(false);
                                } catch (error) {
                                  handleAppError(error);
                                }
                              } else {
                                showMessage({
                                  type: "warning",
                                  message: window.sys_app_translate(
                                    "This lead has no Whatsapp contact",
                                    lang
                                  ),
                                  title: window.sys_app_translate(
                                    "No Whatsapp Contact",
                                    lang
                                  ),
                                });
                              }
                            }}
                          >
                            {window.sys_app_translate(
                              device.name + " WB",
                              lang
                            )}
                          </Button>
                        </>
                      );
                    })}
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    className="text-white"
                    style={{ backgroundColor: AppConfig.themeColors.primary }}
                    onClick={async () => {
                      const { handleAppError, setLoading, showMessage } = props;
                      const { lang } = props.user;
                      if (currentLead.wpdevicecontactid) {
                        try {
                          setLoading(true);
                          await axios.get(
                            "whatsapp/get-state/" + currentLead.wpdeviceid
                          );
                          setWpdeviceUserId(currentLead.wpdeviceuserid);
                          setWpcontactid(currentLead.wpdevicecontactid);
                          setWpDevicePhoneNo(currentLead.wpdevicephoneno);
                          setTimeout(() => {
                            setShowChatModal(true);
                          }, 300);
                          setLoading(false);
                        } catch (error) {
                          handleAppError(error);
                        }
                      } else {
                        showMessage({
                          type: "warning",
                          message: window.sys_app_translate(
                            "This lead has no Whatsapp contact",
                            lang
                          ),
                          title: window.sys_app_translate(
                            "No Whatsapp Contact",
                            lang
                          ),
                        });
                      }
                    }}
                  >
                    {window.sys_app_translate("Open User WB", lang)}
                  </Button>
                </div>
                <HistoryComponent
                  leadId={currentLead.id}
                  lang={lang}
                  handleAppError={handleAppError}
                  scrollableHeight={scrollableHeight}
                />
              </>
            );
          case 1:
            return (
              <NotesComponent
                handleAppError={handleAppError}
                lang={lang}
                leadId={currentLead.id}
                scrollableHeight={scrollableHeight}
              />
            );
          case 2:
            return (
              <CommentsComponent
                handleAppError={handleAppError}
                lang={lang}
                leadId={currentLead.id}
                scrollableHeight={scrollableHeight}
              />
            );
          case 3:
            return (
              <InternalChatComponent
                scrollableHeight={scrollableHeight}
                leadId={currentLead.id}
                handleAppError={handleAppError}
              />
            );
        }
    }
  };
  const getChatModal = () => {
    return (
      <ITQModal
        isOpen={showChatModal}
        isCenterd={true}
        cancelButtonText="x"
        cancelButtonClicked={() => {
          setShowChatModal(false);
        }}
        title={window.sys_app_translate("Whatsapp Chat", props.user.lang)}
        bodyContent={
          <ChatComponent
            leadName={currentLead.quickname}
            chatPhoneNo={wpDevicePhoneNo}
            currentUser={props.user}
            getContacts={(userId) => {
              return WhatsappHelper.getContacts(userId, props);
            }}
            getChats={(userId) => {
              return WhatsappHelper.getChats(
                userId,
                props,
                null,
                currentLead.wpdevicecontactid
              );
            }}
            getChat={(contactId, userId) => {
              return WhatsappHelper.getChat(contactId, userId, props);
            }}
            userId={wpdeviceUserId}
            showMessage={showMessage}
          />
        }
        size="xl"
      />
    );
  };
  return (
    <div className={`sliding-dialog ${isOpen ? "open" : ""}`}>
      <div className="dialog-header">
        <h3>
          <div className="d-flex align-items-center gap-4 inter inter-medium">
            <Icon
              icon={"mdi:account-plus-outline"}
              fontSize={"35px"}
              color="white"
            />
            <span>{window.sys_app_translate("Edit Lead", lang)}</span>
          </div>
        </h3>
        <Icon
          className="close-btn"
          icon={"cuida:x-circle-outline"}
          fontSize={"2.8rem"}
          color="white"
          role="button"
          onClick={toggleDialog}
        />
      </div>
      <div className="container-fluid dialog-content">
        <div className="row popup-phases-row">
          <div className="col-12">
            <div className="my-3">
              <PhaseSteps
                selectedPhaseId={selectedPhase}
                setSelectedPhaseId={setSelectedPhase}
                phases={phases}
                leadPhaseId={currentLead?.phaseid}
                lead_id={currentLead?.id}
                props={props}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            {/* First Tab */}
            <LeadTabs
              activeTab={activeTab.firstTabs}
              setActiveTab={(index) => {
                setActiveTab({ ...activeTab, firstTabs: index });
              }}
              tabs={firstTabs}
              lang={lang}
              leadId={currentLead.id}
              fbid={props.user.fbid}
            >
              <div
                className="bg-white p-4 rounded-2 my-4"
                style={{
                  height: scrollableHeight - 25,
                  overflowY: "auto",
                  position: "relative",
                }}
              >
                {tabContent(0)}
                <div
                  className="d-flex align-items-center justify-content-center gap-4"
                  style={{ padding: "5px 12rem" }}
                ></div>
              </div>
            </LeadTabs>
          </div>
          <div className="col-6">
            <LeadTabs
              activeTab={activeTab.secondTabs}
              setActiveTab={(index) => {
                setActiveTab({ ...activeTab, secondTabs: index });
              }}
              tabs={secondTabs}
              lang={lang}
              leadId={currentLead.id}
              fbid={props.user.fbid}
            >
              {tabContent(1)}
            </LeadTabs>
          </div>
        </div>
      </div>
      {getChatModal()}
    </div>
  );
};

export default EditLeadComponent;
