import React, { useCallback, useState, useEffect } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import { Icon } from "@iconify/react";
import moment from "moment";
import axios from "../../../axios";
import "../index.scss";
import { ListItemText, Menu, MenuItem } from "@material-ui/core";
import { GeneralHelper, ITQModal } from "../../../libs";
import TaskFormComponent from "./TaskFormComponent";
import { Task } from "../../../models/CRM";

// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const localizer = momentLocalizer(moment);

export default function MyCalendar({ events, setEvents, refresh, props }) {
  const [calendarHeight, setCalendarHeight] = useState(0);
  const [state, setState] = useState({
    showTaskFormModal: false,
    isUpdate: false,
    startDate: null,
    endDate: null,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentView, setCurrentView] = useState("month");
  const [date, setDate] = useState(new Date());
  const [currentTask, setCurrentTask] = useState(new Task());

  // when click on task open menu and set current task to make action on it
  const handleTaskClick = (task, event) => {
    console.log("task", task);
    setAnchorEl(event.currentTarget);
    setCurrentTask(task);
  };

  // close menu
  const handleClose = () => {
    setAnchorEl(null);
    //setCurrentTask(null);
  };

  const navigateToThisDay = () => {
    setCurrentView("day");
    setDate(currentTask.start);
    handleClose();
  };

  // one click on slot (day) or time you can open popup to add new task in this date
  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      setCurrentTask(new Task());
      setState({
        ...state,
        showTaskFormModal: true,
        isUpdate: false,
        startDate: start,
        endDate: end,
      });
    },
    [setEvents]
  );

  const handleSelectEvent = useCallback(
    (task, e) => handleTaskClick(task, e),
    []
  );

  const titleAccessor = useCallback(
    (event) => (
      <div className="d-flex gap-3">
        <span>#{event.id}</span> {event.title}
      </div>
    ),
    []
  );
  const handleNavigate = (date) => {
    // Update the date when navigating
    setDate(date);
  };
  const handleViewChange = (newView) => {
    setCurrentView(newView); // Update the current view on tab change
  };
  //get task form modal
  const getTaskFormModal = () => {
    const { handleAppError, formErrors } = props;
    const { lang, isRootAgency, setLoading } = props.user;
    return (
      <ITQModal
        isOpen={state.showTaskFormModal}
        isCenterd={true}
        cancelButtonText="x"
        cancelButtonClicked={() => {
          setState({
            ...state,
            showTaskFormModal: false,
          });
        }}
        title={window.sys_app_translate("Task", props.user.lang)}
        bodyContent={
          <>
            <TaskFormComponent
              handleAppError={handleAppError}
              errorObj={formErrors}
              lang={lang}
              submit={(record) => {
                if (state.isUpdate) {
                  editTask(record);
                } else {
                  addTask(record);
                }
              }}
              isRootAgency={isRootAgency}
              setLoading={setLoading}
              record={currentTask}
              isUpdate={state.isUpdate}
            />
          </>
        }
        size="md"
      />
    );
  };
  //async functions
  const addTask = async (record) => {
    const { handleAppError } = props;
    record.date = moment(state.startDate).format("YYYY-MM-DD");
    try {
      await axios.post(
        "tasks/create",
        GeneralHelper.getFormDataFromModelObject(record)
      );
      setEvents((prev) => [
        ...prev,
        {
          id: prev.length + 1,
          start: state.startDate,
          end: state.endDate,
          title: record.title,
          description: record.description,
        },
      ]);
      setState({
        ...state,
        showTaskFormModal: false,
      });
    } catch (error) {
      handleAppError(error);
    }
  };
  const editTask = async (record) => {
    const { handleAppError } = props;
    try {
      await axios.post(
        `tasks/${record.id}`,
        GeneralHelper.getFormDataFromModelObject(record, true)
      );
      setState({
        ...state,
        showTaskFormModal: false,
      });
      refresh();
    } catch (error) {
      handleAppError(error);
    }
  };
  const deleteTask = async () => {
    const { handleAppError } = props;
    try {
      await axios.delete(`tasks/${currentTask.id}`);
      setEvents((prev) => prev.filter((t) => t.id !== currentTask.id));
      handleClose();
    } catch (error) {
      handleAppError(error);
    }
  };

  const calculateScrollHeight = () => {
    console.log("calculating kanban scroll height ...");
    const pageHeaderElement = document.getElementsByClassName("header")[0];
    const kanbanHeader1Element =
      document.getElementsByClassName("kanban-header-1")[0];
    const kanbanHeader2Element =
      document.getElementsByClassName("kanban-header-2")[0];
    let totalPageHeaderHeight = 0;
    if (pageHeaderElement) {
      const pageHeaderStyles = window.getComputedStyle(pageHeaderElement);
      const pageHeaderHeight = pageHeaderElement.clientHeight;
      totalPageHeaderHeight = pageHeaderHeight;
    }
    let totalKanbanHeader1Height = 0;
    if (kanbanHeader1Element) {
      const kanbanHeader1Styles = window.getComputedStyle(kanbanHeader1Element);
      const kanbanHeader1Height = kanbanHeader1Element.clientHeight;
      const kanbanHeader1MarginTop = parseInt(
        kanbanHeader1Styles.marginTop,
        10
      );
      const kanbanHeader1MarginBottom = parseInt(
        kanbanHeader1Styles.marginBottom,
        10
      );
      totalKanbanHeader1Height =
        kanbanHeader1Height +
        kanbanHeader1MarginTop +
        kanbanHeader1MarginBottom;
    }
    let totalKanbanHeader2Height = 0;
    if (kanbanHeader2Element) {
      const kanbanHeader2Styles = window.getComputedStyle(kanbanHeader2Element);
      const kanbanHeader2Height = kanbanHeader2Element.clientHeight;
      const kanbanHeader2MarginTop = parseInt(
        kanbanHeader2Styles.marginTop,
        10
      );
      const kanbanHeader2MarginBottom = parseInt(
        kanbanHeader2Styles.marginBottom,
        10
      );
      totalKanbanHeader2Height =
        kanbanHeader2Height +
        kanbanHeader2MarginTop +
        kanbanHeader2MarginBottom;
    }
    let kanbanBoardElement = document.getElementsByClassName("calendar-con")[0];
    let totalKanbanBoardMargins = 0;
    if (kanbanBoardElement) {
      const kanbanBoardStyles = window.getComputedStyle(kanbanBoardElement);
      const kanbanBoardPaddingTop = parseInt(kanbanBoardStyles.paddingTop, 10);
      const kanbanBoardPaddingBottom = parseInt(
        kanbanBoardStyles.paddingBottom,
        10
      );
      const kanbanBoardMarginTop = parseInt(kanbanBoardStyles.marginTop, 10);
      totalKanbanBoardMargins +=
        kanbanBoardPaddingTop + kanbanBoardPaddingBottom;
    }
    setCalendarHeight(
      window.innerHeight -
        totalPageHeaderHeight -
        totalKanbanHeader1Height -
        totalKanbanHeader2Height -
        totalKanbanBoardMargins
    );
  };

  useEffect(() => {
    if (
      document.readyState === "complete" ||
      document.readyState === "interactive"
    ) {
      calculateScrollHeight();
      window.addEventListener("resize", calculateScrollHeight);
    } else {
      document.addEventListener("DOMContentLoaded", function () {
        calculateScrollHeight();
        window.addEventListener("resize", calculateScrollHeight);
      });
    }
    return () => {
      window.removeEventListener("resize", calculateScrollHeight);
    };
  }, []);
  return (
    <div className="calendarClass" style={{ height: calendarHeight - 60 }}>
      <Calendar
        defaultView={Views.MONTH}
        date={date}
        events={events}
        titleAccessor={titleAccessor}
        localizer={localizer}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        onNavigate={handleNavigate}
        view={currentView}
        onView={handleViewChange}
        selectable
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          className="d-flex gap-2"
          onClick={() => {
            if (currentTask.leadid) {
              window.open(
                "http://" +
                  window.location.host +
                  `/app/kanban?leadid=${currentTask.leadid}`,
                "_blank"
              );
            }
          }}
        >
          <Icon icon={"mdi:trash-can-outline"} />
          <ListItemText
            primary={window.sys_app_translate("View Lead", props.user.lang)}
          />
        </MenuItem>
        <MenuItem className="d-flex gap-2" onClick={deleteTask}>
          <Icon icon={"mdi:trash-can-outline"} />
          <ListItemText
            primary={window.sys_app_translate("Delete Task", props.user.lang)}
          />
        </MenuItem>
        <MenuItem
          className="d-flex gap-2"
          onClick={() => {
            setState({
              ...state,
              showTaskFormModal: true,
              isUpdate: true,
            });
          }}
        >
          <Icon icon={"fa6-regular:pen-to-square"} />
          <ListItemText
            primary={window.sys_app_translate("Edit Task", props.user.lang)}
          />
        </MenuItem>
        <MenuItem
          className="d-flex gap-2"
          onClick={async () => {
            try {
              await axios.post(`tasks/set-completed/${currentTask.id}`);
            } catch (e) {
              console.log(e);
            }
          }}
        >
          <Icon icon={"material-symbols-light:arrows-more-up-rounded"} />
          <ListItemText
            primary={window.sys_app_translate("Set Completed", props.user.lang)}
          />
        </MenuItem>
      </Menu>
      {state.showTaskFormModal && getTaskFormModal()}
    </div>
  );
}
