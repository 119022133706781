import { Button } from "@material-ui/core";
import { QuickLeadFormComponent } from ".";
import { AppConfig } from "../../../constants";

export default function Tab1Component({
  permissions,
  history,
  handleAppError,
  lang,
  record,
  errorObj,
  showMessage,
  isRootAgency,
  setLoading,
  leadSourcesForDropdownGet,
  leadSourcesForDropdownSet,
  usersForDropDownGet,
  usersForDropDownSet,
  wpcontactsForDropdownGet,
  wpcontactsForDropdownSet,
  studentsForDropdownGet,
  studentsForDropdownSet,
  setCurrentRecord,
}) {
  return (
    <>
      <div className="row">
        <div className="col-12 inter inter-bold">
          {window.sys_app_translate("Quick Info", lang)}
        </div>
      </div>
      {leadSourcesForDropdownGet &&
        usersForDropDownGet &&
        wpcontactsForDropdownGet &&
        studentsForDropdownGet && (
          <QuickLeadFormComponent
            handleAppError={handleAppError}
            lang={lang}
            record={record}
            errorObj={errorObj}
            showMessage={showMessage}
            isRootAgency={isRootAgency}
            setLoading={setLoading}
            leadSourcesForDropdownGet={leadSourcesForDropdownGet}
            leadSourcesForDropdownSet={leadSourcesForDropdownSet}
            usersForDropDownGet={usersForDropDownGet}
            usersForDropDownSet={usersForDropDownSet}
            wpContactsForDropdownGet={wpcontactsForDropdownGet}
            wpContactsForDropdownSet={wpcontactsForDropdownSet}
            studentsForDropdownGet={studentsForDropdownGet}
            studentsForDropdownSet={studentsForDropdownSet}
            permissions={permissions}
          />
        )}
      {record.datafromleadsource && (
        <>
          <div className="row mt-4">
            <div className="col-12 inter inter-bold">
              {window.sys_app_translate("Landing Page Form Data", lang)}
            </div>
          </div>
          <div className="row">
            <div className="col-12">{record.datafromleadsource}</div>
          </div>
        </>
      )}
      <div className="row mt-4">
        <div className="col-12 inter inter-bold">
          {window.sys_app_translate("Application", lang)}
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <Button
            variant="contained"
            type="submit"
            fullWidth
            className="text-white"
            style={{ backgroundColor: AppConfig.themeColors.primary }}
            onClick={() => {
              setCurrentRecord(record);
              history.replace("/app/applications/create");
            }}
          >
            {window.sys_app_translate("Create Application", lang)}
          </Button>
        </div>
      </div>
    </>
  );
}
