/**
 * Roles Routes
 */
/* eslint-disable */
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// async components
import {
  AsyncManageRolesComponent,
  AsyncAddRoleComponent,
  AsyncModifyRoleComponent,
} from "../../components/AsyncComponent/AsyncComponent";

const Forms = ({ match }) => (
  <React.Fragment>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/manage`} />
      <Route
        path={`${match.url}/view-all`}
        component={AsyncManageRolesComponent}
      />
      <Route path={`${match.url}/create`} component={AsyncAddRoleComponent} />
      <Route
        path={`${match.url}/modify`}
        component={AsyncModifyRoleComponent}
      />
    </Switch>
  </React.Fragment>
);

export default Forms;
