import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import "../libs/styles/signin.css";
import OfflineStorage from "./estrylib/OfflineStorage";

import { signinUserIn, setLoading } from "../actions";

class ForFallBack extends Component {
  state = {};
  constructor() {
    super();
  }
  componentDidMount() {
    this.props.setLoading(false);
  }
  render() {
    return (
      <div
        className="container-fluid login-page"
        style={{ backgroundColor: "white" }}
      >
        <div
          className="row d-flex align-content-center"
          style={{ height: "100vh" }}
        >
          <div className="col-12 text-center">
            <img src={require("../assets/404-image.jpg")} alt="" />
            <div className="inter inter-bold" style={{ fontWeight: "bold" }}>
              {this.props.error ? this.props.error.message : "Not Found"}
            </div>
            <button
              className="btn btn-secondary"
              onClick={() => {
                OfflineStorage.instance.removeAll();
                window.location.reload();
              }}
            >
              {window.sys_app_translate("Clear Browser Cache & Try")}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

// map state to props
const mapStateToProps = ({ authUser, appReducer }) => {
  const { user } = authUser;
  const { loading } = appReducer;
  return { user, loading };
};

export default connect(mapStateToProps, {
  signinUserIn,
  setLoading,
})(ForFallBack);
