import React, { useState, useEffect } from "react";
import axios from "../../../axios"; // Make sure to create and import a CSS file for styling
import { Skeleton } from "@material-ui/lab";
import { AppConfig } from "../../../constants";
import { Button } from "@material-ui/core";
const HistoryComponent = ({
  leadId,
  lang,
  handleAppError,
  scrollableHeight,
}) => {
  const [loading, setLoading] = useState(true);
  const [historyItems, setHistoryItems] = useState([]);
  const getLeadHistory = async () => {
    try {
      const response = await axios.get("leads/get-history/" + leadId);
      setHistoryItems(response.data.data);
      setLoading(false);
    } catch (error) {
      handleAppError(error);
    }
  };
  useEffect(() => {
    getLeadHistory();
  }, []);
  return (
    <div
      className="rounded-2"
      style={{ height: scrollableHeight, overflowY: "auto" }}
    >
      <div className="timeline">
        <div className=""></div>
        {!loading &&
          historyItems.map((historyItem, index) => (
            <div key={index} className="timeline-item">
              <div className="timeline-content">
                <span
                  style={{ color: "black", fontSize: "1.3rem" }}
                  className="inter inter-medium"
                >
                  {historyItem.actiontext}
                </span>
                <p className="inter inter-medium">
                  {historyItem.relateditemnote}
                </p>
                <span className="inter inter-medium">
                  {historyItem.created_at}
                </span>
              </div>
            </div>
          ))}
        {loading && historyItems.length === 0 && (
          <>
            <Skeleton variant="rect" height={scrollableHeight} />{" "}
          </>
        )}
      </div>
    </div>
  );
};

export default HistoryComponent;
