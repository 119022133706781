import React, { useEffect, useState, useRef } from "react";
import { Icon } from "@iconify/react";
import "./carousel.css";

const Carousel = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(children.length);
  const [show, setShow] = useState(1);
  const [touchPosition, setTouchPosition] = useState(null);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const carouselRef = useRef(null);

  const margin = 10 * (length + 1); // Match this margin to your CSS margin setup

  //bootstrap breakpoints
  const responsive = {
    desktop: { max: 3000, min: 1441, items: 6 },
    tablet: { max: 1440, min: 992, items: 4 },
    tablet2: { max: 991, min: 768, items: 3 },
    tablet3: { max: 767, min: 576, items: 2 },
    mobile: { max: 575, items: 1 },
  };

  const updateShowByWidth = () => {
    const width = window.innerWidth;
    let newShow = 1;
    if (width >= responsive.desktop.min) {
      newShow = responsive.desktop.items;
    } else if (width >= responsive.tablet.min) {
      newShow = responsive.tablet.items;
    } else if (width >= responsive.tablet2.min) {
      newShow = responsive.tablet2.items;
    } else if (width >= responsive.tablet3.min) {
      newShow = responsive.tablet3.items;
    } else {
      newShow = responsive.mobile.items;
    }

    setShow(newShow);
  };

  const calculateTranslateX = () => {
    const effectiveWidth = carouselWidth / show - (margin * (show - 1)) / show;
    return -currentIndex * (effectiveWidth + margin);
  };

  useEffect(() => {
    setLength(children.length);
    updateShowByWidth(); // Initial value update

    const handleResize = () => {
      updateShowByWidth();
      if (carouselRef.current) {
        setCarouselWidth(carouselRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    // Initial setup
    if (carouselRef.current) {
      setCarouselWidth(carouselRef.current.offsetWidth);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  const next = () => {
    if (currentIndex < length - show) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 7) {
      next();
    }

    if (diff < -7) {
      prev();
    }

    setTouchPosition(null);
  };

  return (
    <div className="carousel-container">
      <div className="carousel-wrapper" ref={carouselRef}>
        {currentIndex > 0 && (
          <button onClick={prev} className="left-arrow">
            <Icon icon="octicon:chevron-left-12" fontSize={"30px"} />
          </button>
        )}
        <div
          className="carousel-content-wrapper"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onMouseMove={(event) => {
            //if mouse is moving to the max right
            if (event.clientX >= window.innerWidth - 30) {
              next();
            }
            //if mouse moving to the max left
            if (event.clientX <= 300) {
              prev();
            }
          }}
        >
          <div
            className={`carousel-content show-${show}`}
            style={{
              transform: `translateX(${calculateTranslateX()}px)`,
            }}
          >
            {children}
          </div>
        </div>
        {currentIndex < length - show && (
          <button onClick={next} className="right-arrow">
            <Icon icon="octicon:chevron-right-12" fontSize={"30px"} />
          </button>
        )}
      </div>
    </div>
  );
};

export default Carousel;
