import React, { useState, useEffect } from "react";
import axios from "../../../axios";
import { _getNormalInput } from "../../../libs";
import { Skeleton } from "@material-ui/lab";
import { ChatEmptyAvatar } from "../../../constants";
import { CommentFormComponent } from "./CommentFormComponent";

const CommentsComponent = ({
  handleAppError,
  lang,
  leadId,
  errorObj,
  scrollableHeight,
}) => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const getComments = async () => {
    try {
      const response = await axios.get(`leads/${leadId}/comments/get-all`);
      setComments(response.data.data);
      setLoading(false);
    } catch (error) {
      handleAppError(error);
    }
  };
  useEffect(() => {
    getComments();
  }, []);
  return (
    <>
      <div className="py-2" style={{ backgroundColor: "white" }}>
        <CommentFormComponent
          errorObj={errorObj}
          lang={lang}
          leadId={leadId}
          handleAppError={handleAppError}
          refresh={getComments}
        />
      </div>
      <div
        className="rounded-2"
        style={{
          height: (scrollableHeight - 220) / 16 + "rem",
          overflowY: "auto",
        }}
      >
        <div className="container-fluid my-2">
          <div className="row">
            <div className="col-12 px-0">
              <div className="comments-list">
                {!loading &&
                  comments.map((comment, index) => (
                    <div key={index} className="comment-item">
                      <div className="comment-header">
                        <ChatEmptyAvatar className="avatar" />
                        <div className="comment-info">
                          <h4>{comment.createdby}</h4>
                          <span>
                            {new Date(comment.created_at).toLocaleString()}
                          </span>
                        </div>
                      </div>
                      <div className="comment-content">
                        <p>{comment.comment}</p>
                      </div>
                    </div>
                  ))}
                {loading && (
                  <>
                    <Skeleton variant="rect" height={120} />
                    <Skeleton variant="rect" height={120} className="mt-4" />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentsComponent;
