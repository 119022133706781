import { type } from "jquery";

class GeneralHelper {
  static getFormDataFromModelObject(object, isPutMethod = false) {
    console.log("creating form data ....");
    const formData = new FormData();
    Object.keys(object).forEach((property) => {
      if (
        object[property] !== null &&
        typeof object[property] !== "undefined"
      ) {
        if (Array.isArray(object[property])) {
          if (property.indexOf("file") > -1) {
            object[property].forEach((file, index) => {
              console.log("appending multiple files to form data ...");
              formData.append(`${property}[${index}]`, file);
            });
          } else {
            formData.append(property, JSON.stringify(object[property]));
          }
        } else {
          switch (typeof object[property]) {
            case "boolean":
              formData.append(property, object[property] ? "true" : "false");
              break;
            default:
              formData.append(property, object[property]);
              break;
          }
        }
      }
    });
    if (isPutMethod) {
      formData.append("_method", "PUT");
    }
    return formData;
  }
  static getGetANDSetDataForDropdownFromResponse(
    response,
    lang,
    translateLabels = false
  ) {
    const dropdownGet = [],
      dropdownSet = {};
    response.data.data.forEach((item) => {
      if (item.cities) {
        dropdownGet.push({
          label: translateLabels
            ? window.sys_app_translate(item.name, lang)
            : item.name,
          value: item.id,
          cities: item.cities,
        });
      } else {
        dropdownGet.push({
          label: translateLabels
            ? window.sys_app_translate(item.name, lang)
            : item.name,
          value: item.id,
        });
      }

      item.label = translateLabels
        ? window.sys_app_translate(item.name, lang)
        : item.name;
      item.value = item.id;
      dropdownSet["item" + item.id] = item;
    });
    return { get: dropdownGet, set: dropdownSet };
  }
  //process.env.NODE_ENV === "production"
  static decideDomain = () => {
    if (
      window.location.host.includes(".com") ||
      window.location.host.includes(".test")
    ) {
      return "https://" + window.location.host + "/";
    } else {
      return "https://itqan-education-system-laravel.test/";
    }
  };
  static isOnline = () => {
    return window.location.host.includes(".com") ||
      window.location.host.includes(".test")
      ? true
      : false;
  };
  static isFullWidthContent = (history) => {
    return (
      history.location.pathname === "/app/kanban" ||
      history.location.pathname === "/app/calendar" ||
      history.location.pathname === "/app/kanban-list" ||
      history.location.pathname === "/app/hr/log"
    );
  };
}
export default GeneralHelper;
