import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useFilters,
  useGroupBy,
} from "react-table";
import { TextField } from "@material-ui/core";
import OfflineStorage from "../estrylib/OfflineStorage";
import $ from "jquery";
import { TableSorterDownArrow, TableSorterUpArrow } from "../../constants";
import Select from "react-select";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
  Last30Days,
  Last7Days,
  LastMonth,
  ThisMonth,
  Today,
  Yesterday,
} from "../DateQueryRelated";
import ITQANDropDown from "../ITQANDropdown";
import { filter } from "lodash";

export const Portal = React.memo((props) => {
  let firstTime = true;
  const el = useRef(document.createElement("div"));
  useEffect(() => {
    if (firstTime) {
      console.log("loading portal ...");
      const portal = document.getElementById(
        "itqan_table_search_field_container"
      );
      if (portal) {
        portal.appendChild(el.current);
        firstTime = false;
      }
    }
    return () => {
      console.log("unloading portal ...");
    };
  }, []);

  return ReactDOM.createPortal(props.children, el.current);
});
export const FiltersPortal = React.memo((props) => {
  let firstTime = true;
  const el = useRef(document.createElement("div"));
  useEffect(() => {
    if (firstTime) {
      console.log("loading portal ...");
      const portal = document.getElementById("itqan_table_filters_container");
      if (portal) {
        portal.appendChild(el.current);
        firstTime = false;
      }
    }
    return () => {
      console.log("unloading portal ...");
    };
  }, []);

  return ReactDOM.createPortal(props.children, el.current);
});
const GlobalFilter = React.memo(
  ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
    const [value, setValue] = React.useState(globalFilter),
      onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
      }, 200);
    return (
      <Portal>
        <TextField
          id="outlined-basic"
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          label={window.sys_app_translate(
            "Search here ...",
            OfflineStorage.instance.get("esitqappedu-lang")
          )}
          variant="outlined"
          type="search"
          size="small"
          className="table-search-field"
          value={value || ""}
        />
      </Portal>
    );
  }
);
const BootstrapTable = ({
  columns,
  hiddenColumns,
  data,
  fetchData,
  setEFilters,
  filters,
  setESorters,
  loading,
  pageCount: controlledPageCount,
  lang,
  isRemoteFetch = false,
  isRemoteSearch = false,
  initialPageSize,
  savePageSize = null,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    toggleSortBy,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: initialPageSize,
        hiddenColumns: hiddenColumns ? hiddenColumns : [],
      },
      autoResetPage: true,
      manualPagination: isRemoteFetch,
      pageCount: controlledPageCount,
    },
    !isRemoteFetch && useFilters,
    useGlobalFilter,
    usePagination
  );
  const [columnAttributes, setColumnAttributes] = useState({});
  const [columnFilters, setColumnFilters] = useState({});
  useEffect(() => {
    if (isRemoteFetch) {
      console.log("fetching inside table .." + pageIndex + "," + pageSize);
      fetchData(pageIndex, pageSize);
    }
  }, [pageIndex, pageSize]);
  const customSelectFilterStyles = {
    menu: (provided) => ({
      ...provided,
      width: "auto",
    }),
    menuList: (provided) => ({
      ...provided,
      width: "auto",
    }),
  };
  const hiddenColumnsObj = {};
  $(document).ready(function () {
    let done = false;
    $(".itqan-table-column-filter").each(function () {
      console.log("setting width 1 ...");
      $(this).css("width", $(this).attr("placeholder").length + 2 + "ch");
    });
    $(".itqdatepicker").val("");
  });
  return (
    <>
      {!isRemoteSearch && (
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}
      <div className="table-container">
        <div className="table-responsive estry-table-scroll">
          <table
            {...getTableProps()}
            className="table table-borderless"
            id="ItqanPageTable"
          >
            <thead
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: "white",
              }}
            >
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    console.log("logging state");
                    if (column.show === false) {
                      hiddenColumnsObj[column.Header] = false;
                      return null;
                    }
                    return (
                      <th
                        {...column.getHeaderProps()}
                        className={
                          "inter inter-semi-bold "
                          // (column.id === "activeForTable"
                          //   ? "estry-hide-column"
                          //   : "")
                        }
                      >
                        {!column.remoteFilter && column.render("Header")}
                        {!isRemoteFetch && (
                          <FiltersPortal>
                            {column.canFilter ? column.render("Filter") : null}
                          </FiltersPortal>
                        )}
                        {column.remoteFilter &&
                          !column.remoteFilterSelect &&
                          !column.remoteFilterITQANDropDown &&
                          !column.remoteFilterDateSelector &&
                          !column.remoteFilterInputsMinMax && (
                            <input
                              key={column.id}
                              type="search"
                              className="itqan-table-column-filter inter inter-medium"
                              onKeyDown={(e) => {
                                console.log("filter triggered ...");
                                if (e.key === "Enter") {
                                  setEFilters(column.id, e.target.value, true);
                                } else {
                                  setEFilters(column.id, e.target.value);
                                }
                              }}
                              placeholder={column.Header}
                            />
                          )}
                        {column.remoteFilter &&
                          column.remoteFilterITQANDropDown && (
                            <div
                              style={{
                                display: "inline-block",
                                position: "relative",
                              }}
                              className="mx-1"
                            >
                              <ITQANDropDown
                                placeholder={column.Header}
                                optionsP={column.remoteFilterITQANDropDownData}
                                lang={lang}
                                optionsSelected={(selectedOptions) => {
                                  let strValue = selectedOptions.join(",");
                                  if (!strValue && !filters) {
                                    //do nothing
                                  } else {
                                    try {
                                      if (filters[column.id] !== strValue) {
                                        setEFilters(column.id, strValue, true);
                                      }
                                    } catch (error) {
                                      //if filters[column.id] is not set
                                      setEFilters(column.id, strValue, true);
                                    }
                                  }
                                }}
                                fixedMenuPosition={
                                  data.length === pageSize ? false : true
                                }
                              />
                            </div>
                          )}
                        {column.remoteFilter && column.remoteFilterSelect && (
                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                            }}
                            className="mx-1"
                          >
                            <Select
                              className="itqan-react-select-field-filter"
                              classNamePrefix="itqan-table-filter-select"
                              options={column.remoteFilterSelectData}
                              menuPortalTarget={document.body}
                              placeholder={column.Header}
                              isMulti={column.remoteFilterSelectMultiple}
                              styles={customSelectFilterStyles}
                              onChange={(e) => {
                                if (column.remoteFilterSelectMultiple) {
                                  let valuesArray = e,
                                    valuesString = "";
                                  valuesArray.forEach((item) => {
                                    if (item.value) {
                                      valuesString =
                                        valuesString + item.value + ",";
                                    }
                                  });
                                  setEFilters(
                                    column.id,
                                    e.value === "All" || !e ? "" : valuesString,
                                    true
                                  );
                                } else {
                                  setEFilters(
                                    column.id,
                                    e.value === "All" || !e.value
                                      ? ""
                                      : e.value,
                                    true
                                  );
                                }
                              }}
                            />
                          </div>
                        )}
                        {column.remoteFilter &&
                          column.remoteFilterDateSelector && (
                            <div
                              style={{ display: "inline-block" }}
                              className="mx-1"
                            >
                              <DateRangePicker
                                onCallback={(start, end, label) => {
                                  console.log("date range changed ...");
                                  // setState({
                                  //   ...state,
                                  //   fromDate: start.format("DD/MM/YYYY"),
                                  //   toDate: end.format("DD/MM/YYYY"),
                                  // });
                                }}
                                onApply={(event, picker) => {
                                  const start = picker.startDate;
                                  const end = picker.endDate;
                                  setEFilters(
                                    column.id,
                                    start.format("YYYY-MM-DD") +
                                      ";" +
                                      end.format("YYYY-MM-DD"),
                                    true
                                  );
                                  // setState({
                                  //   ...state,
                                  //   fromDate: start.format("DD/MM/YYYY"),
                                  //   toDate: end.format("DD/MM/YYYY"),
                                  // });
                                }}
                                initialSettings={{
                                  alwaysShowCalendars: true,
                                  showDropdowns: true,
                                  ranges: {
                                    [Today]: [moment(), moment()],
                                    [Yesterday]: [
                                      moment().subtract(1, "days"),
                                      moment().subtract(1, "days"),
                                    ],
                                    [Last7Days]: [
                                      moment().subtract(6, "days"),
                                      moment(),
                                    ],
                                    [Last30Days]: [
                                      moment().subtract(29, "days"),
                                      moment(),
                                    ],
                                    [ThisMonth]: [
                                      moment().startOf("month"),
                                      moment().endOf("month"),
                                    ],
                                    [LastMonth]: [
                                      moment()
                                        .subtract(1, "month")
                                        .startOf("month"),
                                      moment()
                                        .subtract(1, "month")
                                        .endOf("month"),
                                    ],
                                  },
                                }}
                              >
                                <input
                                  type="text"
                                  className="form-control itqdatepicker itqan-table-column-filter"
                                  placeholder={window.sys_app_translate(
                                    column.Header,
                                    lang
                                  )}
                                />
                              </DateRangePicker>
                            </div>
                          )}
                        {column.remoteFilter &&
                          column.remoteFilterInputsMinMax && (
                            <div style={{ display: "inline-block" }}>
                              <label style={{ display: "block" }}>
                                {column.Header}
                              </label>
                              <div style={{ display: "inline-block" }}>
                                <input
                                  className="mx-1 itqan-table-filter-min-input inter inter-medium"
                                  type="search"
                                  onChange={(e) => {
                                    console.log("filter triggered ...");
                                    let values = [e.target.value, null];
                                    if (
                                      columnFilters &&
                                      columnFilters[column.id]
                                    ) {
                                      values =
                                        columnFilters[column.id].split(";");
                                    }
                                    values[0] = e.target.value;
                                    let finalValue = values.join(";"),
                                      columnFiltersCopy = { ...columnFilters };
                                    columnFiltersCopy[column.id] = finalValue;
                                    setColumnFilters(columnFiltersCopy);
                                  }}
                                  onKeyDown={(e) => {
                                    if (columnFilters[column.id]) {
                                      if (e.key === "Enter") {
                                        setEFilters(
                                          column.id,
                                          columnFilters[column.id],
                                          true
                                        );
                                      } else {
                                        setEFilters(
                                          column.id,
                                          columnFilters[column.id]
                                        );
                                      }
                                    }
                                  }}
                                  placeholder={window.sys_app_translate(
                                    "Min",
                                    lang
                                  )}
                                />
                                <input
                                  className="mx-1 itqan-table-filter-max-input inter inter-medium"
                                  type="search"
                                  placeholder={window.sys_app_translate(
                                    "Max",
                                    lang
                                  )}
                                  onChange={(e) => {
                                    let values = [null, e.target.value];
                                    if (
                                      columnFilters &&
                                      columnFilters[column.id]
                                    ) {
                                      values =
                                        columnFilters[column.id].split(";");
                                    }
                                    values[1] = e.target.value;
                                    let finalValue = values.join(";"),
                                      columnFiltersCopy = { ...columnFilters };
                                    columnFiltersCopy[column.id] = finalValue;
                                    setColumnFilters(columnFiltersCopy);
                                  }}
                                  onKeyDown={(e) => {
                                    if (columnFilters[column.id]) {
                                      if (e.key === "Enter") {
                                        setEFilters(
                                          column.id,
                                          columnFilters[column.id],
                                          true
                                        );
                                      } else {
                                        setEFilters(
                                          column.id,
                                          columnFilters[column.id]
                                        );
                                      }
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        <div style={{ display: "inline-block" }}>
                          {column.remoteSort && (
                            <span
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                //column.toggleSortBy(!column.isSortedDesc);
                                let newDirection = "asc";
                                if (
                                  columnAttributes.columnid &&
                                  columnAttributes.direction === "asc"
                                ) {
                                  newDirection = "desc";
                                }
                                setColumnAttributes({
                                  columnid: column.id,
                                  direction: newDirection,
                                });
                                setESorters(column.id, newDirection);
                              }}
                            >
                              {columnAttributes.columnid === column.id &&
                              columnAttributes.direction === "asc" ? (
                                <TableSorterDownArrow />
                              ) : (
                                <TableSorterUpArrow />
                              )}
                            </span>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      if (hiddenColumnsObj[cell.column.Header] === false) {
                        return null;
                      }
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={
                            "inter inter-medium " +
                            (cell.column.id === "companyForTable"
                              ? "estry-text-left"
                              : "estry-text-center")
                            // (cell.column.id === "activeForTable"
                            //   ? " estry-hide-column"
                            //   : "")
                          }
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              {data.length === 0 && (
                <tr>
                  <td colSpan="10000">{window.sys_app_translate("-", lang)}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* navigation */}
        <div className="pagination-navs row justify-content-between align-items-center">
          <div className="col-2 col-lg-1 col-xl-1">
            <select
              value={pageSize}
              className="form-select"
              onChange={(e) => {
                let pageSize = Number(e.target.value);
                setPageSize(Number(e.target.value));
                if (savePageSize) {
                  savePageSize(pageSize);
                }
              }}
            >
              {[10, 15, 25, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {window.sys_app_translate(pageSize, lang)}
                </option>
              ))}
            </select>
          </div>
          <div
            style={{
              width: "fit-content",
              display: "flex",
              paddingLeft: "18px",
              paddingRight: "18px",
              backgroundColor: "#ffffff",
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid #ECECEE",
              paddingTop: "5px",
              paddingBottom: "5px",
              borderRadius: "4px",
            }}
          >
            {canPreviousPage && (
              <a
                href="#"
                onClick={() => {
                  gotoPage(0);
                }}
                className={
                  canPreviousPage ? "nav-button" : "nav-button disabled"
                }
              >
                <span
                  className="inter inter-regular px-3"
                  style={{ fontSize: "14px" }}
                >
                  {"<<"}
                </span>
              </a>
            )}
            {canPreviousPage && (
              <a
                href="#"
                onClick={() => {
                  previousPage();
                }}
                className={
                  canPreviousPage ? "nav-button" : "nav-button disabled"
                }
              >
                <span
                  className="inter inter-regular"
                  style={{ fontSize: "12px" }}
                >
                  {window.sys_app_translate("Previous", lang)}
                </span>
              </a>
            )}
            {canPreviousPage && (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  previousPage();
                }}
                className={"button-link disabled"}
              >
                <span className="inter inter-bold">{pageIndex}</span>
              </a>
            )}
            <input
              type="number"
              className="inter inter-bold button-input"
              placeholder={pageIndex + 1}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  gotoPage(parseInt(e.target.value) - 1);
                  $(e.target).val("");
                }
              }}
            />
            {/* <a href="#" onClick={(e) => {}} className={"button-link"}>
              <span className="inter inter-bold">{pageIndex + 1}</span>
            </a> */}
            {canNextPage && (
              <a
                href="#"
                onClick={(e) => {
                  console.log("go to next page ...");
                  e.preventDefault();
                  nextPage();
                }}
                className={"button-link disabled"}
              >
                <span className="inter inter-bold">{pageIndex + 2}</span>
              </a>
            )}
            {canNextPage && (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  nextPage();
                }}
                className={
                  canNextPage ? "nav-button" : "nav-button button-disabled"
                }
              >
                <span
                  className="inter inter-regular"
                  style={{ fontSize: "12px" }}
                >
                  {window.sys_app_translate("Next", lang)}
                </span>
              </a>
            )}
            {canNextPage && (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  gotoPage(pageCount - 1);
                }}
                className={
                  canNextPage ? "nav-button" : "nav-button button-disabled"
                }
              >
                <span
                  className="inter inter-regular px-3"
                  style={{ fontSize: "14px" }}
                >
                  {">>"}
                </span>
              </a>
            )}
          </div>
          <div className="col-1 col-lg-2 col-xl-2"></div>
        </div>
      </div>
    </>
  );
};

export default BootstrapTable;
