import React, { useEffect, useState } from "react";
import { database } from "../../../libs/estrylib/Firebase";
import { AppConfig } from "../../../constants";
import { FirebaseNotificationsBySectionKey } from "../../../libs";

const TabButton = ({
  index,
  activeTab,
  setActiveTab,
  tabTitle,
  leadId,
  fbid,
}) => {
  const firebaseLeadRef = database
    .ref()
    .child(FirebaseNotificationsBySectionKey)
    .child("users")
    .child(fbid)
    .child("leads")
    .child(leadId);
  const [notificationsCount, setNotificationsCount] = useState(0);
  useEffect(() => {
    firebaseLeadRef.child(tabTitle.toLowerCase()).on("value", (snapshot) => {
      const obj = snapshot.val();
      //get children count
      let count = 0;
      for (let key in obj) {
        count++;
      }
      setNotificationsCount(count > 0 ? count : null);
    });
    return () => {
      firebaseLeadRef.off("value");
    };
  }, []);
  return (
    <div
      className={`inter inter-medium ${
        index === activeTab ? "tab-act  ive tab-t" : "tab-t"
      }`}
      key={index}
      role="button"
      onClick={() => {
        firebaseLeadRef.child(tabTitle.toLowerCase()).set({});
        setActiveTab(index);
      }}
    >
      {window.sys_app_translate(tabTitle)}{" "}
      {notificationsCount ? (
        <span
          style={{
            backgroundColor: AppConfig.themeColors.danger,
            padding: "2px 6px",
            borderRadius: "50%",
            fontSize: ".7rem",
            color: "#fff",
          }}
          className="inter inter-medium"
        >
          {notificationsCount}
        </span>
      ) : null}
    </div>
  );
};

export default TabButton;
