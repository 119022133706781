import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  setLoading,
  showMessage,
  setCurrentRecord,
  handleAppError,
  clearFormErrors,
} from "../../actions";

import axios from "../../axios";
import { DropdownDataFetcher, ITQModal } from "../../libs";
import { Helmet } from "react-helmet";
import {
  AppConfig,
  KanbanListActionsIcon,
  KanbanListDateIcon,
  KanbanListEmailIcon,
  KanbanListLabelIcon,
  KanbanListLeadSourceIcon,
  KanbanListNameIcon,
  KanbanListPhoneIcon,
  KanbanListResponsibleUserIcon,
} from "../../constants";
import { NoRecordsFoundComponent } from "../../components";
import KanbanListItem from "./components/KanbanListItem";
import Pagination from "../../libs/Pagination";
import { ActionsBar, Header } from "../../components/CRM";
import FiltersFormComponent from "../kanban/components/FiltersFormComponent";
import { Button } from "@material-ui/core";
import Select from "react-select";

const KanbanList = (props) => {
  const [searchNeedle, setSearchNeedle] = useState("");
  const [usersForDropDown, setUsersForDropDown] = useState([]);
  const [queryResponsibleUserId, setQueryResponsibleUserId] = useState(null);
  const [refreshCount, setRefreshCount] = useState(0);
  const [leads, setLeads] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [params, setParams] = useState({
    all: false,
    pagenumber: 1,
  });
  const [paginationParams, setPaginationParams] = useState({
    pagesCount: 0,
    total: 0,
  });
  const [kanbanListContainerHeight, setKanbanListContainerHeight] = useState(0);
  const [leadSourcesForDropDown, setLeadSourcesForDropDown] = useState(null);
  const [state, setState] = useState({
    showPhaseFormModal: false,
    phaseRecord: null,
    isPhaseUpdate: false,
    //related to lead phase change
    showLeadsPhaseChangeModal: false,
    selectedPhaseId: null,
    //filters
    showFiltersModal: false,
    filters: {},
  });
  const [phasesForDropdown, setPhasesForDropdown] = useState([]);
  // Function to fetch leads by stage
  const fetchLeads = async () => {
    console.log("fetching leads ...");
    const { handleAppError } = props;
    const paramsCopy = { ...params };
    if (queryResponsibleUserId) {
      paramsCopy.responsibleuserid = queryResponsibleUserId;
    }
    if (searchNeedle) {
      paramsCopy.searchneedle = searchNeedle;
    }
    if (state.filters) {
      paramsCopy.filters = state.filters;
    }
    try {
      let responseData = await axios.get("leads/get-all", {
        params: paramsCopy,
      });
      responseData = responseData.data.data;
      setLeads(responseData.data);
      setPaginationParams({
        pagesCount: responseData.last_page,
        total: parseFloat(responseData.total),
      });
    } catch (error) {
      handleAppError(error);
    }
  };
  const refresh = () => {
    setRefreshCount((val) => val + 1);
  };
  const onPageChange = (page) => {
    setParams((prevState) => ({
      ...prevState,
      pagenumber: page,
    }));
  };
  const calculateScrollHeight = () => {
    console.log("calculating kanban scroll height ...");
    const pageHeaderElement = document.getElementsByClassName("header")[0];
    const kanbanHeader1Element =
      document.getElementsByClassName("kanban-header-1")[0];
    const kanbanHeader2Element =
      document.getElementsByClassName("kanban-header-2")[0];
    let totalPageHeaderHeight = 0;
    if (pageHeaderElement) {
      const pageHeaderStyles = window.getComputedStyle(pageHeaderElement);
      const pageHeaderHeight = pageHeaderElement.clientHeight;
      totalPageHeaderHeight = pageHeaderHeight;
      console.log("totalPageHeaderHeight", totalPageHeaderHeight);
    }
    let totalKanbanHeader1Height = 0;
    if (kanbanHeader1Element) {
      const kanbanHeader1Styles = window.getComputedStyle(kanbanHeader1Element);
      const kanbanHeader1Height = kanbanHeader1Element.clientHeight;
      const kanbanHeader1MarginTop = parseInt(
        kanbanHeader1Styles.paddingTop,
        0
      );
      console.log("kanbanHeader1Element", kanbanHeader1Height);
      const kanbanHeader1MarginBottom = parseInt(
        kanbanHeader1Styles.paddingBottom,
        0
      );
      totalKanbanHeader1Height =
        kanbanHeader1Height +
        kanbanHeader1MarginTop +
        kanbanHeader1MarginBottom;
      console.log("totalKanbanHeader1Height", totalKanbanHeader1Height);
    }
    let totalKanbanHeader2Height = 0;
    if (kanbanHeader2Element) {
      const kanbanHeader2Styles = window.getComputedStyle(kanbanHeader2Element);
      const kanbanHeader2Height = kanbanHeader2Element.clientHeight;
      const kanbanHeader2MarginTop = parseInt(kanbanHeader2Styles.marginTop, 0);
      const kanbanHeader2MarginBottom = parseInt(
        kanbanHeader2Styles.marginBottom,
        0
      );
      totalKanbanHeader2Height =
        kanbanHeader2Height +
        kanbanHeader2MarginTop +
        kanbanHeader2MarginBottom;
      console.log("totalKanbanHeader2Height", totalKanbanHeader2Height);
    }
    setKanbanListContainerHeight(
      window.innerHeight - totalPageHeaderHeight - 1
    );
  };
  const getFiltersModal = () => {
    const { showFiltersModal } = state,
      { lang } = props.user;
    return (
      leadSourcesForDropDown && (
        <ITQModal
          isOpen={showFiltersModal}
          isCenterd={true}
          size="small"
          cancelButtonText="x"
          cancelButtonClicked={async () => {
            setState({
              ...state,
              showFiltersModal: false,
            });
          }}
          title={window.sys_app_translate("Filters", lang)}
          bodyContent={
            <>
              <FiltersFormComponent
                showMessage={props.showMessage}
                handleAppError={props.handleAppError}
                lang={props.user.lang}
                submit={(filters) => {
                  setState({
                    ...state,
                    showFiltersModal: false,
                    filters: filters,
                  });
                }}
                isRootAgency={props.user.isRootAgency}
                setLoading={props.setLoading}
                props={props}
                filters={state.filters}
                leadSourcesForDropdownGet={leadSourcesForDropDown.get}
              />
            </>
          }
        />
      )
    );
  };
  const getLeadPhaseChangeModal = () => {
    const { showLeadsPhaseChangeModal } = state,
      { lang } = props.user;
    return (
      showLeadsPhaseChangeModal && (
        <ITQModal
          isOpen={showLeadsPhaseChangeModal}
          isCenterd={true}
          size="small"
          cancelButtonText="x"
          cancelButtonClicked={async () => {
            setState({
              ...state,
              showLeadsPhaseChangeModal: false,
            });
          }}
          title={window.sys_app_translate("Change Selected Leads Phase", lang)}
          bodyContent={
            <div className="row d-flex justify-content-center">
              <div className="col-11">
                <div className="container-fluid">
                  <div
                    className="row justify-content-center"
                    style={{ marginBottom: "1rem" }}
                  >
                    <div className="col-12">
                      <Select
                        className="itqan-react-select-field itqan-react-select-field-top-margin"
                        options={phasesForDropdown.get}
                        placeholder={window.sys_app_translate("To Phase", lang)}
                        onChange={(e) => {
                          setState({
                            ...state,
                            selectedPhaseId: e.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row my-1">
                    <div className="col-12 d-flex align-content-center justify-content-center">
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: AppConfig.themeColors.secondary,
                          color: "white",
                          display: "inline",
                          width: "13rem",
                        }}
                        className="inter inter-bold"
                        type="submit"
                        onClick={(e) => {
                          changeSelectedLeadsPhase();
                        }}
                      >
                        {window.sys_app_translate("Submit", lang)}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      )
    );
  };
  const changeSelectedLeadsPhase = async () => {
    try {
      const { showMessage, setLoading, handleAppError } = props;
      setLoading(true);
      await axios.post("leads/bulk-change-phase", {
        phaseid: state.selectedPhaseId,
        leadsids: selectedLeads.join(","),
      });
      showMessage({
        type: "success",
        title: window.sys_app_translate(
          "Successfull Operation",
          props.user.lang
        ),
        message: window.sys_app_translate(
          "Leads Phase Changed Successfully",
          props.user.lang
        ),
      });
      setState({
        ...state,
        showLeadsPhaseChangeModal: false,
      });
      setLoading(false);
    } catch (e) {
      handleAppError(e);
    }
  };
  useEffect(() => {
    fetchLeads();
  }, [
    refreshCount,
    params,
    searchNeedle,
    queryResponsibleUserId,
    state.filters,
  ]);
  useEffect(() => {
    DropdownDataFetcher.getUsersForDropdown(props).then((data) => {
      setUsersForDropDown(data);
    });
    DropdownDataFetcher.getLeadSourcesForDropdown(props).then((data) => {
      setLeadSourcesForDropDown(data);
    });
    DropdownDataFetcher.getPhasesForDropdown(props).then((data) => {
      setPhasesForDropdown(data);
    });
    if (
      document.readyState === "complete" ||
      document.readyState === "interactive"
    ) {
      console.log("Kanban: document is already loaded ...");
      calculateScrollHeight();
      window.addEventListener("resize", calculateScrollHeight);
    } else {
      console.log(
        "Kanban: document is not loaded yet ... adding 'DOMContentLoaded' event listener ..."
      );
      document.addEventListener("DOMContentLoaded", function () {
        calculateScrollHeight();
        window.addEventListener("resize", calculateScrollHeight);
      });
    }
    window.addEventListener("resize", calculateScrollHeight);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{window.sys_app_translate("Leads List", props.user.lang)}</title>
      </Helmet>
      <div
        className="kanban-con"
        style={{
          paddingBottom: "1rem",
          minHeight: kanbanListContainerHeight + "px",
        }}
      >
        {usersForDropDown && (
          <Header
            props={props}
            usersForDropDown={usersForDropDown.get}
            setQueryResponsibleUserId={setQueryResponsibleUserId}
            setSearchNeedle={setSearchNeedle}
            isSuperAdmin={props.user.is_super_admin}
            showFiltersModal={() => {
              setState({
                ...state,
                showFiltersModal: true,
              });
            }}
          />
        )}
        <ActionsBar
          selectedLeads={selectedLeads}
          setSelectedLeads={setSelectedLeads}
          refresh={refresh}
          props={props}
          changeLeadsPhase={() => {
            setState({
              ...state,
              showLeadsPhaseChangeModal: true,
            });
          }}
          activeTabId={3}
        />
        {leads && leads.length > 0 ? (
          <>
            <div className="mb-4" style={{ width: "97%" }}>
              <div className="itqan-table2">
                <div
                  className="itqan-table2-row"
                  style={{
                    backgroundColor: AppConfig.themeColors.primary,
                    color: "white",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                  }}
                >
                  {[
                    "#",
                    "Phase",
                    "Name",
                    "Phone",
                    "Email",
                    "Lead Source",
                    "Date",
                    "Responsible User",
                    "Label",
                  ].map((propertyName) => {
                    let icon = null;
                    switch (propertyName) {
                      case "Name":
                        icon = <KanbanListNameIcon />;
                        break;
                      case "Phone":
                        icon = <KanbanListPhoneIcon />;
                        break;
                      case "Email":
                        icon = <KanbanListEmailIcon />;
                        break;
                      case "Lead Source":
                        icon = <KanbanListLeadSourceIcon />;
                        break;
                      case "Date":
                        icon = <KanbanListDateIcon />;
                        break;
                      case "Responsible User":
                        icon = <KanbanListResponsibleUserIcon />;
                        break;
                      case "Label":
                        icon = <KanbanListLabelIcon />;
                        break;
                    }
                    return (
                      <div
                        className="inter inter-medium itqan-table2-heading itqan-table2-td"
                        style={{ fontWeight: "bold" }}
                        key={propertyName}
                      >
                        {propertyName === "#" ? (
                          <>
                            <span className="mx-1"></span>
                            <input
                              type="checkbox"
                              checked={selectedLeads.length === leads.length}
                              onChange={(e) => {
                                //select or unselect all leads
                                if (e.target.checked) {
                                  setSelectedLeads(
                                    leads.map((lead) => lead.id)
                                  );
                                } else {
                                  setSelectedLeads([]);
                                }
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                        {icon && (
                          <>
                            {icon}
                            <span className="mx-1"></span>
                          </>
                        )}

                        {window.sys_app_translate(
                          propertyName,
                          props.user.lang
                        )}
                      </div>
                    );
                  })}
                  <div className="inter inter-medium itqan-table2-heading itqan-table2-td">
                    <KanbanListActionsIcon />
                    <span className="mx-1"></span>
                    {window.sys_app_translate("Actions", props.user.lang)}
                  </div>
                </div>
                {leads.map((lead, index) => {
                  return (
                    <KanbanListItem
                      key={`lead-${lead.id}`}
                      greyRow={index % 2 !== 0}
                      lead={lead}
                      isLeadSelected={selectedLeads.includes(lead.id)}
                      selectLead={() => {
                        setSelectedLeads((prevState) => {
                          if (prevState.includes(lead.id)) {
                            return prevState.filter((id) => id !== lead.id);
                          } else {
                            return [...prevState, lead.id];
                          }
                        });
                      }}
                      unSelectLead={() => {
                        setSelectedLeads((prevState) => {
                          return prevState.filter((id) => id !== lead.id);
                        });
                      }}
                      openLead={() => {
                        window.open(
                          "http://" +
                            window.location.host +
                            `/app/kanban?leadid=${lead.id}`,
                          "_blank"
                        );
                      }}
                    />
                  );
                })}
              </div>
            </div>
            <div className="d-flex flex-column" style={{ gap: "1.11rem" }}>
              <Pagination
                totalPages={paginationParams.pagesCount}
                currentPage={params.pagenumber}
                onPageChange={onPageChange}
              />
            </div>
          </>
        ) : (
          <>
            {leads && leads.length === 0 && firstLoad && (
              <>
                {window.sys_app_translate(
                  "No Leads Available",
                  props.user.lang
                )}
              </>
            )}
            {leads && leads.length === 0 && !firstLoad && (
              <>
                <NoRecordsFoundComponent lang={props.user.lang} />
              </>
            )}
          </>
        )}
        {getFiltersModal()}
        {getLeadPhaseChangeModal()}
      </div>
    </>
  );
};

const mapStateToProps = ({ authUser, appReducer }) => {
  const { user } = authUser;
  const { formErrors, currentRecord, loading } = appReducer;
  return { user, formErrors, currentRecord, loading };
};

export default connect(mapStateToProps, {
  setCurrentRecord,
  setLoading,
  showMessage,
  handleAppError,
  clearFormErrors,
})(KanbanList);
