import React from "react";
import {
  AppConfig,
  KanbanListAvatarIcon,
  KanbanListDeleteIcon,
  KanbanListEditIcon,
  KanbanListNameIcon,
} from "../../../constants";

export const KanbanListItem = ({
  greyRow,
  lead,
  selectLead = () => {},
  unSelectLead = () => {},
  openLead = () => {},
  isLeadSelected = false,
}) => {
  const dateString = lead.created_at;
  const [datePart, timePart, ampm] = dateString.split(" ");
  // Split time into its components
  const [hours, minutes] = timePart.split(":");
  const time = `${hours}:${minutes} ${ampm}`;
  const getInfoItem = ({ infoItemCssClass, propertyName }) => {
    let value = lead[propertyName];
    switch (propertyName) {
      case "phase":
        value = (
          <span style={{ color: lead.phasecolor, fontWeight: "bold" }}>
            {lead[propertyName]}
          </span>
        );
        break;
      case "quickname":
        value = (
          <>
            <span>
              <KanbanListAvatarIcon height={"2rem"} />
            </span>
            <span className="mx-1"></span>
            {lead[propertyName]}
          </>
        );
        break;
      case "responsibleuser":
        value = (
          <>
            <span className="mx-1"></span>
            <span style={{ color: AppConfig.themeColors.danger }}>
              {lead[propertyName]}
            </span>
          </>
        );
        break;
      case "id":
        value = (
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              checked={isLeadSelected}
              onChange={(e) => {
                if (e.target.checked) {
                  selectLead(lead.id);
                } else {
                  unSelectLead(lead.id);
                }
              }}
            />
            {lead[propertyName]}
          </div>
        );
        break;
      case "created_at":
        value = `${datePart} ${time}`;
        break;
      case "label":
        if (lead.label) {
          value = (
            <div
              className="d-flex align-items-center"
              style={{
                backgroundColor: lead.labelcolor,
                color: "black",
                padding: "0.2rem 0.5rem",
                borderRadius: "1rem",
              }}
            >
              {lead[propertyName]}
            </div>
          );
        } else {
          value = "-";
        }

        break;
    }
    return (
      <div
        style={{
          borderLeft:
            propertyName === "id" ? "5px solid " + lead.phasecolor : "none",
        }}
        key={`${lead.id}-${propertyName}`}
        className="itqan-table2-td inter inter-medium"
      >
        {value ?? "-"}
      </div>
    );
  };
  return (
    <div
      className="secondary-color-on-hover itqan-table2-row"
      style={{
        backgroundColor: greyRow ? AppConfig.themeColors.hoverGrey : "white",
        borderBottom: `1px solid ${AppConfig.themeColors.hoverGrey}`,
      }}
    >
      {[
        "id",
        "phase",
        "quickname",
        "phone",
        "email",
        "leadsourcename",
        "created_at",
        "responsibleuser",
        "label",
      ].map((propertyName) => {
        return getInfoItem({
          infoItemCssClass: "inter inter-medium",
          propertyName,
        });
      })}
      <div className="inter inter-medium itqan-table2-td">
        <span>
          <KanbanListEditIcon
            height={"1.2rem"}
            role="button"
            onClick={openLead}
          />
        </span>
      </div>
    </div>
  );
};

export default KanbanListItem;
