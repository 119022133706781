import { useState, useRef, useEffect } from "react";
import Select from "react-select";
import {
  AppConfig,
  PLeadsCanFilterByResponsibleUser,
  PSectionLeads,
} from "../../constants";
import { TextField } from "@material-ui/core";
import { Icon } from "@iconify/react";
import FiltersFormComponent from "../../routes/kanban/components/FiltersFormComponent";

const Header = ({
  props,
  usersForDropDown,
  setQueryResponsibleUserId,
  setSearchNeedle,
  isSuperAdmin,
  showFiltersModal,
  setFilters,
  filters,
  leadSourcesForDropdown,
  labelsForDropdownGet,
  leadLostLabelsForDropdownGet,
  dealLostLabelsForDropdownGet,
}) => {
  const { lang } = props.user;
  const [state, setState] = useState({});
  const [showSearchForm, setShowSearchForm] = useState(false);
  const searchRef = useRef(null);
  const searchFormContainerRef = useRef(null);
  const handleClickOutside = (event) => {
    console.log("clicked outside");
    //if the click is inside .MuiDialog-container, do nothing
    if (event.target.closest(".MuiDialog-container")) return;

    if (
      searchFormContainerRef.current &&
      !searchFormContainerRef.current.contains(event.target)
    ) {
      setShowSearchForm(false);
    }
  };
  useEffect(() => {
    if (showSearchForm) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSearchForm]);
  return (
    <div
      className="row d-flex justify-content-space-between align-items-center kanban-header-1"
      style={{ paddingTop: "1.25rem" }}
    >
      <div className="col-1" style={{ width: "fit-content" }}>
        <Icon
          fontSize={"4.37rem"}
          color={AppConfig.themeColors.white}
          icon={"ic:round-account-circle"}
        />
      </div>
      {props.user.permissions[PSectionLeads][
        PLeadsCanFilterByResponsibleUser
      ] && (
        <div className="col-10 col-sm-5 col-xl-3 px-0 px-md-2">
          <Select
            className=" itqan-react-select-field"
            options={usersForDropDown}
            isMulti={false}
            onChange={(e) => {
              if (e) {
                setState({
                  ...state,
                  userId: e.value,
                });
                setQueryResponsibleUserId(e.value);
              } else {
                setState({
                  ...state,
                  userId: null,
                });
                setQueryResponsibleUserId(null);
              }
            }}
            isClearable={true}
            placeholder={window.sys_app_translate(
              "Select Employee User",
              props.user.lang
            )}
          />
        </div>
      )}

      <div
        style={{ position: "relative" }}
        className={`${
          isSuperAdmin
            ? "col-12 col-sm-5 col-xl-8 px-sm-0 px-md-2 kanban-search-input"
            : "col-2 col-xl-11 kanban-search-input"
        }`}
      >
        <TextField
          id="outlined-basic"
          inputRef={searchRef}
          autoComplete="off"
          placeholder={window.sys_app_translate(
            "Write keyword and press Enter button, or you can use the filter icon",
            lang
          )}
          onKeyUp={(e) => {
            //if enter is pressed
            if (e.keyCode === 13) {
              setSearchNeedle(e.target.value);
            }
          }}
          onFocus={() => {
            setShowSearchForm(true);
          }}
          type="search"
          variant="outlined"
          InputProps={{
            className: "px-3 bg-white",
            startAdornment: (
              <Icon
                fontSize={"30px"}
                color={AppConfig.themeColors.greyLighten}
                icon={"heroicons:magnifying-glass-16-solid"}
              />
            ),
            endAdornment: (
              <Icon
                fontSize={"30px"}
                color={AppConfig.themeColors.primary}
                icon={"mdi:filter-outline"}
                role="button"
                onClick={() => {
                  setShowSearchForm(true);
                }}
              />
            ),
          }}
        />
        {showSearchForm && (
          <div
            style={{
              width: "100%",
              position: "absolute",
              zIndex: 100,
              backgroundColor: "white",
              borderRadius: "0.5rem",
              boxShadow: "0 0 10px rgba(0,0,0,0.1)",
            }}
            className="p-3"
            ref={searchFormContainerRef}
          >
            <FiltersFormComponent
              showMessage={props.showMessage}
              handleAppError={props.handleAppError}
              lang={props.user.lang}
              submit={(filters) => {
                setShowSearchForm(false);
                setFilters(filters);
              }}
              isRootAgency={props.user.isRootAgency}
              setLoading={props.setLoading}
              props={props}
              filters={filters}
              leadSourcesForDropdownGet={leadSourcesForDropdown.get}
              labelsForDropdownGet={labelsForDropdownGet}
              leadLostLabelsForDropdownGet={leadLostLabelsForDropdownGet}
              dealLostLabelsForDropdownGet={dealLostLabelsForDropdownGet}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
