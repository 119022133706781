/**
 * Applications
 */
/* eslint-disable */
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// async components
import { AsyncManageHRLogsComponent } from "../../components/AsyncComponent/AsyncComponent";

const Forms = ({ match }) => (
  <React.Fragment>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/log`} />
      <Route path={`${match.url}/log`} component={AsyncManageHRLogsComponent} />
    </Switch>
  </React.Fragment>
);

export default Forms;
