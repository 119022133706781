import React, { useState, useEffect } from "react";
import EmojiPicker from "emoji-picker-react";
import {
  ChatEmojiPickerIcon,
  ChatFileUploadIcon,
  InternalChatSendMessageIcon,
} from "../../../constants/SVGIcons";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function InternalChatFormComponent({
  sendMessage = (message) => {},
}) {
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [file, setFile] = useState(null);
  const inputRef = React.createRef();
  const fileRef = React.createRef();
  const hasFileStyle = file
    ? {
        // border: "1px solid " + AppConfig.themeColors.primary,
        boxShadow: "0px 0px 5px 0px black",

        padding: "1px",
        borderRadius: "50%",
      }
    : {};
  const submit = () => {
    if (inputRef.current.value || file) {
      sendMessage({
        message: inputRef.current.value,
        attachment: file,
      });
      inputRef.current.value = "";
      setFile(null);
    }
  };
  return (
    <>
      <div
        style={{
          width: "48%",
          position: "absolute",
          bottom: 1,
          background: "#e8e8e8",
        }}
        className="py-3 px-2 d-flex align-items-center"
      >
        <ChatEmojiPickerIcon
          className="clickable mx-2"
          onClick={() => {
            setIsEmojiPickerOpen(!isEmojiPickerOpen);
          }}
        />
        <input
          type="file"
          ref={fileRef}
          style={{ display: "none" }}
          onChange={(e) => {
            if (e.target.files.length > 0) {
              console.log(e.target.files[0]);
              setFile(e.target.files[0]);
            }
          }}
        />
        <ChatFileUploadIcon
          className="mx-3 clickable"
          style={hasFileStyle}
          onClick={() => {
            fileRef.current.value = null;
            fileRef.current.click();
          }}
        />
        <input
          type="text"
          ref={inputRef}
          placeholder="Type a message"
          className="form-control chat-text-input"
          style={{ width: "85%" }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              submit();
            }
          }}
        />
        <InternalChatSendMessageIcon
          className="clickable"
          style={{
            marginLeft: "-2.8rem",
            width: "1.5rem",
          }}
          onClick={() => {
            submit();
          }}
        />
      </div>
      <div style={{ position: "absolute", bottom: "3.5rem" }}>
        <EmojiPicker
          open={isEmojiPickerOpen}
          onEmojiClick={(emojiObj) => {
            inputRef.current.value += emojiObj.emoji;
          }}
        />
      </div>
    </>
  );
}
