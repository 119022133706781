import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  _getCheckbox,
  _getNormalInput,
  _getPhoneInput,
  _getPhoneInputV2,
  _getSelectInput,
} from "../../../libs/FormFields";
import Button from "@material-ui/core/Button";
import axios from "../../../axios";
import { DropdownDataFetcher, GeneralHelper } from "../../../libs";
import {
  AppConfig,
  PLeadsChangeResponsibleUser,
  PLeadsModify,
  PSectionLeads,
  RefreshButtonIcon,
} from "../../../constants";
import ColorSelector from "../../../components/color-selector/ColorSelector";
import { ColorPalette } from "../../../components/color-selector/ColorPalette";

const QuickLeadFormComponent = ({
  handleAppError,
  lang,
  record,
  errorObj,
  showMessage,
  submit,
  isRootAgency,
  setLoading,
  leadSourcesForDropdownGet,
  leadSourcesForDropdownSet,
  usersForDropDownGet,
  usersForDropDownSet,
  wpContactsForDropdownGet,
  wpContactsForDropdownSet,
  studentsForDropdownGet,
  studentsForDropdownSet,
  permissions,
}) => {
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: {
      quickname: record.quickname,
      phone: record.phone,
      leadsourceid: leadSourcesForDropdownSet
        ? leadSourcesForDropdownSet["item" + record.leadsourceid]
        : null,
      responsibleuserid: usersForDropDownSet
        ? usersForDropDownSet["item" + record.responsibleuserid]
        : null,
      label: record.label,
      // labelcolor: record.labelcolor,
      wpdevicecontactid: wpContactsForDropdownSet
        ? wpContactsForDropdownSet["item" + record.wpdevicecontactid]
        : null,
      studentid: studentsForDropdownSet
        ? studentsForDropdownSet["item" + record.studentid]
        : null,
    },
  });
  const getPhaseLabelsForDropdown = async (phaseId) => {
    console.log("getting phase labels ..." + phaseId);
    try {
      const response = await axios.get("phases/get-phase-labels-for-dropdown", {
        params: {
          phaseid: phaseId,
        },
      });
      const rawData = response.data.data;
      const labels = rawData.map((item) => {
        return { value: item.id, label: item.name };
      });
      console.log(labels);
      setPhasesLabelsForDropdownGet(labels);
    } catch (err) {
      handleAppError(err);
    }
  };
  useEffect(() => {
    if (errorObj) {
      console.log("setting errors ...");
      Object.keys(errorObj).forEach((key) => {
        setError(key, { type: "custom", message: errorObj[key][0] });
      });
    }
  }, [errorObj]);
  useEffect(() => {
    getPhaseLabelsForDropdown(record.phaseid);
  }, []);
  const [state, setState] = useState({
    phone: record.phone,
    showLabelSelect: true,
    labelColor: record.labelcolor
      ? record.labelcolor
      : AppConfig.themeColors.primary,
  });
  const [phasesLabelsForDropdownGet, setPhasesLabelsForDropdownGet] =
    useState(null);

  const onSubmit = async (values) => {
    console.log("updating lead ...");
    try {
      setLoading(true);
      record.quickname = values.quickname;
      //record.phone = values.phone;
      record.phone = state.phone;
      record.leadsourceid = values.leadsourceid.value;
      record.responsibleuserid = values.responsibleuserid.value;
      if (state.showLabelSelect && values.label) {
        record.label = values.label.value;
      } else {
        record.label = values.label;
      }
      record.labelcolor = state.labelColor;
      record.wpdevicecontactid = values.wpdevicecontactid
        ? values.wpdevicecontactid.value
        : null;
      record.studentid = values.studentid ? values.studentid.value : null;
      await axios.post(
        `leads/${record.id}`,
        GeneralHelper.getFormDataFromModelObject(record, true)
      );
      showMessage({
        type: "success",
        title: window.sys_app_translate("Successful Operation", lang),
        message: window.sys_app_translate("Lead Updated Successfully", lang),
      });
      setLoading(false);
    } catch (error) {
      handleAppError(error);
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="container-fluid"
          style={{ backgroundColor: "white", borderRadius: "25px" }}
        >
          <div className="row d-flex justify-content-center form-row">
            {_getNormalInput({
              control: control,
              inputName: "quickname",
              inputTitle: "Quick Name",
              inputId: "QuickName",
              errors: errors,
              lang: lang,
              width: "col-12",
              inputSize: "medium",
              required: true,
              type: "text",
              preventNonLatinLetters: false,
            })}
          </div>
          <div className="row d-flex justify-content-center form-row">
            {_getPhoneInputV2({
              international: true,
              defaultCountry: "TR",
              value: state.phone,
              onChange: (phone) => {
                setValue("phone", phone);
                setState({
                  ...state,
                  phone: phone,
                });
              },
              lang: lang,
              required: true,
              register: register,
              inputName: "phone",
              width: "col-12",
              errors: errors,
            })}
          </div>
          <div className="row d-flex justify-content-start form-row">
            {_getSelectInput({
              control: control,
              inputName: "leadsourceid",
              options: leadSourcesForDropdownGet,
              errors: errors,
              inputTitle: "Lead Source",
              width: "col-12",
              lang,
            })}
          </div>
          {permissions[PSectionLeads][PLeadsChangeResponsibleUser] && (
            <div className="row d-flex justify-content-start form-row">
              {_getSelectInput({
                control: control,
                inputName: "responsibleuserid",
                options: usersForDropDownGet,
                errors: errors,
                inputTitle: "Responsible User",
                width: "col-12",
                lang,
              })}
            </div>
          )}
          <div className="row d-flex justify-content-start form-row">
            {phasesLabelsForDropdownGet &&
              state.showLabelSelect &&
              _getSelectInput({
                control: control,
                inputName: "label",
                options: phasesLabelsForDropdownGet,
                errors: errors,
                inputTitle: "Label",
                width: "col-6",
                lang,
                required: false,
              })}
            {!state.showLabelSelect &&
              _getNormalInput({
                control: control,
                inputName: "label",
                inputTitle: "Label",
                inputId: "Label",
                errors: errors,
                lang: lang,
                width: "col-6",
                inputSize: "medium",
                required: false,
                type: "text",
                preventNonLatinLetters: false,
              })}
            {/* <div className="col-1 d-flex justify-content-center align-items-center">
              <RefreshButtonIcon
                className="clickable"
                onClick={() => {
                  setState({
                    ...state,
                    showLabelSelect: !state.showLabelSelect,
                  });
                }}
              />
            </div> */}
            <div className="col-6">
              <ColorSelector
                color={state.labelColor}
                selectColor={(color) => {
                  setState({
                    ...state,
                    labelColor: color,
                  });
                }}
                colorPalette={ColorPalette}
              />
            </div>
          </div>
          <div className="row d-flex justify-content-start form-row">
            {_getSelectInput({
              control: control,
              inputName: "wpdevicecontactid",
              options: wpContactsForDropdownGet,
              errors: errors,
              inputTitle: "WP Contact",
              width: "col-12",
              lang,
              required: false,
            })}
          </div>
          <div className="row d-flex justify-content-start form-row">
            {_getSelectInput({
              control: control,
              inputName: "studentid",
              options: studentsForDropdownGet,
              errors: errors,
              inputTitle: "Student",
              width: "col-12",
              lang,
              required: false,
              isClearable: true,
            })}
          </div>
          <div className="row form-row" style={{ marginTop: "1rem" }}>
            <div className="col-2 offset-1 col-sm-3 offset-sm-0">
              <Button
                variant="contained"
                type="submit"
                fullWidth
                className="text-white"
                style={{ backgroundColor: AppConfig.themeColors.primary }}
              >
                {window.sys_app_translate("Save", lang)}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default QuickLeadFormComponent;
