import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  _getCheckbox,
  _getDatePicker,
  _getNormalInput,
  _getSelectInput,
} from "../../../libs/FormFields";
import Button from "@material-ui/core/Button";
import { AppConfig } from "../../../constants";
import moment from "moment";
// import Block from "@uiw/react-color-block";

const TaskFormComponent = ({
  showMessage,
  handleAppError,
  lang,
  submit,
  isRootAgency,
  setLoading,
  record,
  errorObj,
  isUpdate,
}) => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: {
      id: record.id,
      title: record.title,
      description: record.description,
      date: record.date,
      //   leadid: record.leadid,
      //   status: record.status,
    },
  });
  // const [hex, setHex] = useState("#fff");
  useEffect(() => {
    if (errorObj) {
      console.log("setting errors ...");
      Object.keys(errorObj).forEach((key) => {
        setError(key, { type: "custom", message: errorObj[key][0] });
      });
    }
  }, [errorObj]);

  const onSubmit = (values) => {
    const recordToSubmit = {
      id: record.id,
      title: values.title,
      description: values.description,
    };
    console.log("recordToSubmit", recordToSubmit);
    submit(recordToSubmit);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="container-fluid"
          style={{ backgroundColor: "white", borderRadius: "25px" }}
        >
          <div className="row d-flex justify-content-center form-row">
            {_getNormalInput({
              control: control,
              inputName: "title",
              inputTitle: "Title",
              inputId: "Title",
              errors: errors,
              lang: lang,
              width: "col-12",
              inputSize: "medium",
              required: true,
              type: "text",
            })}
          </div>
          <div className="row d-flex justify-content-center form-row">
            {_getNormalInput({
              control: control,
              inputName: "description",
              inputTitle: "Description",
              inputId: "Description",
              errors: errors,
              lang: lang,
              width: "col-12",
              inputSize: "medium",
              required: true,
              type: "textarea",
            })}
          </div>
          <div
            className="row d-flex justify-content-center form-row"
            style={{ marginTop: "1rem" }}
          >
            <div className="col-10 offset-1 col-sm-6 offset-sm-0 d-flex justify-content-center">
              <Button
                variant="contained"
                style={{
                  backgroundColor: AppConfig.themeColors.secondary,
                  color: "white",
                  display: "inline",
                  width: "13rem",
                }}
                className="inter inter-bold"
                type="submit"
                onClick={(e) => {}}
              >
                {window.sys_app_translate("Save", lang)}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default TaskFormComponent;
