/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import { Icon } from "@iconify/react";
import { Button, ListItemText } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Draggable } from "react-beautiful-dnd";
import QuickLeadCard from "./QuickLeadCard";
import {
  KanbanEventType,
  PLeadsCanViewAllSystemLeads,
  PLeadsCreate,
  PPhasesCreate,
  PPhasesDelete,
  PPhasesModify,
  PSectionLeads,
  PSectionPhases,
  PusherConfig,
} from "../../../constants";
import axios from "../../../axios";
import { confirmAlert } from "react-confirm-alert";
import LeadComponent from "./LeadComponent";

export default function PhaseComponent({
  addPhase,
  updatePhase,
  phase,
  props,
  setCurrentLead,
  selectedLeads,
  refresh,
  setSelectedLeads,
  responsibleUserId,
  channel,
  leadsCount,
  hiddenRef,
  hiddenRef2,
  movement,
  showOnlyThis,
  selectedPhaseId,
  hideThis,
  showAll,
  filters,
}) {
  const [leads, setLeads] = useState(phase.leads);
  const [currentLeadsCount, setCurrentLeadsCount] = useState(leadsCount);
  const [params, setParams] = useState({
    page: 1,
  });
  const { lang } = props.user;
  const [anchorEl, setAnchorEl] = useState(null);
  const firstLoad = useRef(true);
  const [showQuickLeadForm, setShowQuickLeadForm] = useState(false);
  const openPhasesMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closePhasesMenu = () => {
    setAnchorEl(null);
  };
  const myDivRef = useRef(null);

  // Function to fetch leads by phase
  const fetchLeadsByPhase = async () => {
    console.log("fetching leads by phase ...");
    const { handleAppError } = props;
    try {
      const response = await axios.get(
        `leads/get-all?by=phaseid&byValue=${phase.id}`,
        {
          params: {
            all: true,
            page: params.page,
            perpage: 10,
            responsibleuserid: responsibleUserId,
            filters: filters,
          },
        }
      );
      setLeads((prevLeads) => [...prevLeads, ...response.data.data.data]);
      setCurrentLeadsCount(response.data.data.total);
    } catch (error) {
      handleAppError(error);
    }
  };
  useEffect(() => {
    channel.current.bind(
      PusherConfig.channels.kanbanChannel.eventName,
      (data) => {
        console.log("new event received");
        switch (data.message.type) {
          case KanbanEventType.LeadNew:
            if (
              parseInt(data.message.relatedObject.phaseid) !== phase.id &&
              data.message.relatedObject.phaseid !==
                data.message.relatedObject.oldphaseid
            )
              return;
            if (data.message.relatedObject.createdby !== props.user.name) {
              if (
                !props.user.permissions[PSectionLeads][
                  PLeadsCanViewAllSystemLeads
                ]
              ) {
                if (props.user.is_manager) {
                  if (
                    props.user.user_id !==
                    data.message.relatedObject.responsibleuserparentuserid
                  ) {
                    return;
                  }
                } else {
                  return;
                }
              }
            }
            setLeads((prevLeads) => [data.message.relatedObject, ...prevLeads]);
            break;
          case KanbanEventType.LeadPhaseChanged:
            if (data.message.relatedObject.createdby !== props.user.name) {
              if (
                !props.user.permissions[PSectionLeads][
                  PLeadsCanViewAllSystemLeads
                ]
              ) {
                if (props.user.is_manager) {
                  if (
                    props.user.user_id !==
                    data.message.relatedObject.responsibleuserparentuserid
                  ) {
                    return;
                  }
                } else {
                  return;
                }
              }
            }
            if (
              parseInt(data.message.relatedObject.oldphaseid) ===
              parseInt(phase.id)
            ) {
              if (
                data.message.relatedObject.updatedby === props.user.name &&
                !data.message.relatedObject.insideeditleadui
              )
                return;
              setLeads((prevLeads) => {
                const updatedLeads = prevLeads
                  .map((lead) =>
                    lead.orderno > data.message.relatedObject.oldorderno
                      ? { ...lead, orderno: lead.orderno - 1 }
                      : lead
                  )
                  .filter((lead) => lead.id !== data.message.relatedObject.id)
                  .sort((a, b) => b.orderno - a.orderno);
                return updatedLeads;
              });
              setCurrentLeadsCount((prevCount) => prevCount - 1);
            }
            if (
              parseInt(data.message.relatedObject.phaseid) ===
              parseInt(phase.id)
            ) {
              setLeads((prevLeads) => {
                const updatedLeads = prevLeads.map((lead) =>
                  lead.orderno >= data.message.relatedObject.orderno
                    ? { ...lead, orderno: lead.orderno + 1 }
                    : lead
                );
                return [data.message.relatedObject, ...updatedLeads].sort(
                  (a, b) => b.orderno - a.orderno
                );
              });
              setCurrentLeadsCount((prevCount) => prevCount + 1);
            }
            break;
          case KanbanEventType.LeadUpdated:
            if (parseInt(data.message.relatedObject.phaseid) !== phase.id)
              return;
            setLeads((prevLeads) => {
              const updatedLeads = prevLeads.map((lead) =>
                lead.id === data.message.relatedObject.id
                  ? data.message.relatedObject
                  : lead
              );
              return updatedLeads;
            });
            break;
        }

        //window.prompt("New event received");
        //setMessages((prevMessages) => [...prevMessages, data.message.message]);
      }
    );

    return () => {
      console.log("unsubscribing from pusher");
    };
  }, []);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      return;
    }
    fetchLeadsByPhase();
  }, [params]);
  useEffect(() => {
    console.log("leads changed");
  }, [leads]);
  const deletePhase = async () => {
    const { setLoading, handleAppError } = props;
    try {
      setLoading(true);
      await axios.delete(`phases/${phase.id}`);
      setLoading(false);
    } catch (error) {
      handleAppError(error);
    }
  };
  const permissions = props.user.permissions;
  return (
    <>
      <input
        type="hidden"
        ref={hiddenRef}
        value={phase.id}
        onClick={() => {
          console.log(`clicked hidden ref of phaseid: ${phase.id}`);
          if (movement.from.phaseid === phase.id) {
            //movement.from.leadid
            setLeads((prevLeads) => {
              const updatedLeads = prevLeads.filter(
                (lead) => lead.id !== parseInt(movement.from.leadid)
              );
              return updatedLeads;
            });
            setCurrentLeadsCount((prevCount) => prevCount - 1);
          }
          if (movement.to.phaseid === phase.id) {
          }
        }}
      />
      <input
        type="hidden"
        ref={hiddenRef2}
        value={phase.id}
        onClick={() => {
          console.log(`clicked hidden ref2 of phaseid: ${phase.id}`);
          setParams((prevParams) => {
            return { ...prevParams, page: prevParams.page + 1 };
          });
        }}
      />
      <div className="fixed-sec">
        <div
          className="container-fluid phase-header"
          style={{ color: phase.color }}
        >
          <div className="row">
            <div className="col-12 px-0">
              <div className="container-fluid">
                <div className="row">
                  <div
                    className="col-12 d-flex justify-content-between align-items-center py-1 px-2"
                    style={{
                      width: "100%",
                      textWrapMode: "nowrap",
                      backgroundColor: "white",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <Icon icon={phase.icon} />
                      <span className="mx-2" style={{ fontSize: "1rem" }}>
                        {phase.name}
                      </span>
                    </div>
                    <div className="d-flex align-items-center">
                      <span
                        style={{
                          backgroundColor: phase.color,
                          borderRadius: "3px",
                        }}
                        className="px-2 py-1 text-white"
                      >
                        {leads && currentLeadsCount}
                      </span>
                      <Icon
                        icon={"la:ellipsis-v"}
                        fontSize={"2.2rem"}
                        aria-controls="phase-menu-actions"
                        aria-haspopup="true"
                        color="grey"
                        role="button"
                        onClick={openPhasesMenu}
                      />
                      <Menu
                        id="phase-menu-actions"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={closePhasesMenu}
                      >
                        <MenuItem
                          className="d-flex gap-2"
                          onClick={() => {
                            showAll();
                            closePhasesMenu();
                          }}
                        >
                          <Icon icon={"ri:add-fill"} />
                          <ListItemText
                            primary={window.sys_app_translate(
                              window.sys_app_translate("Show All", lang),
                              props.user.lang
                            )}
                          />
                        </MenuItem>
                        <MenuItem
                          className="d-flex gap-2"
                          onClick={() => {
                            showOnlyThis();
                            closePhasesMenu();
                          }}
                        >
                          <Icon icon={"ri:add-fill"} />
                          <ListItemText
                            primary={window.sys_app_translate(
                              selectedPhaseId === phase.id
                                ? window.sys_app_translate("Show All", lang)
                                : window.sys_app_translate(
                                    "Show Only This",
                                    lang
                                  ),
                              props.user.lang
                            )}
                          />
                        </MenuItem>
                        <MenuItem
                          className="d-flex gap-2"
                          onClick={() => {
                            hideThis();
                            closePhasesMenu();
                          }}
                        >
                          <Icon icon={"ri:add-fill"} />
                          <ListItemText
                            primary={window.sys_app_translate(
                              window.sys_app_translate("Hide This"),
                              props.user.lang
                            )}
                          />
                        </MenuItem>
                        {permissions[PSectionPhases][PPhasesCreate] && (
                          <MenuItem
                            className="d-flex gap-2"
                            onClick={() => {
                              addPhase();
                              closePhasesMenu();
                            }}
                          >
                            <Icon icon={"ri:add-fill"} />
                            <ListItemText
                              primary={window.sys_app_translate(
                                "Add",
                                props.user.lang
                              )}
                            />
                          </MenuItem>
                        )}
                        {permissions[PSectionPhases][PPhasesModify] && (
                          <MenuItem
                            className="d-flex gap-2"
                            onClick={() => {
                              updatePhase(phase);
                              closePhasesMenu();
                            }}
                          >
                            <Icon icon={"flowbite:edit-outline"} />
                            <ListItemText
                              primary={window.sys_app_translate(
                                "Update",
                                props.user.lang
                              )}
                            />
                          </MenuItem>
                        )}
                        {permissions[PSectionPhases][PPhasesDelete] && (
                          <MenuItem
                            className="d-flex gap-2"
                            onClick={() => {
                              closePhasesMenu();
                              confirmAlert({
                                title: window.sys_app_translate(
                                  "Confirm Deletion",
                                  props.user.lang
                                ),
                                message: window.sys_app_translate(
                                  "Are you sure to delete this phase?",
                                  props.user.lang
                                ),
                                buttons: [
                                  {
                                    label: window.sys_app_translate(
                                      "Yes",
                                      props.user.lang
                                    ),
                                    onClick: () => {
                                      deletePhase();
                                    },
                                  },
                                  {
                                    label: window.sys_app_translate(
                                      "No",
                                      props.user.lang
                                    ),
                                    onClick: () => {},
                                  },
                                ],
                              });
                            }}
                          >
                            <Icon icon={"mdi:trash-can-outline"} />
                            <ListItemText
                              primary={window.sys_app_translate(
                                "Delete",
                                props.user.lang
                              )}
                            />
                          </MenuItem>
                        )}
                      </Menu>
                    </div>
                  </div>
                  {permissions[PSectionLeads][PLeadsCreate] && (
                    <div className="col-12 px-0 mt-2" style={{ width: "100%" }}>
                      <Button
                        variant="contained"
                        fullWidth
                        className="ql-btn py-2"
                        startIcon={<Icon icon={"mdi:account-plus-outline"} />}
                        onClick={() => {
                          setShowQuickLeadForm(true);
                        }}
                      >
                        {window.sys_app_translate("Quick Lead", lang)}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showQuickLeadForm && (
        <QuickLeadCard
          showMessage={props.showMessage}
          handleAppError={props.handleAppError}
          lang={props.user.lang}
          isRootAgency={props.user.isRootAgency}
          setLoading={props.setLoading}
          record={{
            name: "",
            phone: "",
          }}
          errorObj={props.formErrors}
          setShowQuickLeadForm={setShowQuickLeadForm}
          phase={phase}
        />
      )}
      <div
        className="leads-sec"
        ref={myDivRef}
        style={{ paddingTop: showQuickLeadForm ? "unset" : "115px" }}
      >
        {leads &&
          leads.map((lead, index) => (
            <Draggable
              draggableId={`${lead.id}`}
              index={index}
              key={lead.id + "leaddrag"}
            >
              {(provided, snapshot) => (
                <div
                  key={lead.id + "leadcontainer"}
                  ref={provided.innerRef}
                  {...provided.dragHandleProps}
                  {...provided.draggableProps}
                  style={{
                    ...provided.draggableProps.style,
                    position: snapshot.isDragging ? "fixed" : "relative",
                    zIndex: snapshot.isDragging ? 1000 : "initial", // Ensures it's above other elements when dragged
                    top: snapshot.isDragging
                      ? provided.draggableProps.style.offsetTop
                      : "initial",
                    left: snapshot.isDragging
                      ? provided.draggableProps.style.offsetLeft
                      : "initial",
                    margin: snapshot.isDragging
                      ? "0"
                      : provided.draggableProps.style.margin,
                  }}
                  className="lead-card"
                >
                  <LeadComponent
                    props={props}
                    color={phase.color}
                    lead={lead}
                    refresh={refresh}
                    setCurrentLead={(lead) => {
                      axios.post("leads/lead-is-opened/" + lead.id);
                      setCurrentLead(lead);
                    }}
                    selectedLeads={selectedLeads}
                    setSelectedLeads={setSelectedLeads}
                  />
                </div>
              )}
            </Draggable>
          ))}
      </div>
    </>
  );
}
