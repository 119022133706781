import React, { useState, useEffect } from "react";
import axios from "../../../axios";
import { AppConfig } from "../../../constants";
import { Skeleton } from "@material-ui/lab";

export default function LeadApplications({ handleAppError, studentId, lang }) {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const getApplications = async () => {
    try {
      const response = await axios.get("applications", {
        params: {
          studentid: studentId ? studentId : -1,
          all: true,
          pagenumber: 1,
          onlycurrentagency: true,
        },
      });
      setLoading(false);
      setApplications(response.data.data);
    } catch (error) {
      handleAppError(error);
    }
  };
  useEffect(() => {
    getApplications();
  }, []);
  return (
    <div className="container">
      {!loading &&
        applications.map((application) => {
          return (
            <div className="row">
              <div key={application.id} className="col-12">
                <div className="card mb-4">
                  <div
                    className="card-header inter inter-medium"
                    style={{
                      backgroundColor: AppConfig.themeColors.primary,
                      color: "white",
                    }}
                  >
                    <h5 className="card-title py-0 my-0">
                      #{application.id} - {application.studentname}
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-2">
                          <span
                            className="inter inter-medium"
                            style={{ fontWeight: "bold" }}
                          >
                            {window.sys_app_translate("Date", lang) + ": "}
                          </span>
                        </div>
                        <div className="col-4">
                          <span className="inter inter-medium">
                            {application.created_at}
                          </span>
                        </div>
                        <div className="col-2">
                          <span
                            className="inter inter-medium"
                            style={{ fontWeight: "bold" }}
                          >
                            {window.sys_app_translate("Status", lang) + ": "}
                          </span>
                        </div>
                        <div className="col-4">
                          <span className="inter inter-medium">
                            {window.sys_app_translate(application.status, lang)}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-2">
                          <span
                            className="inter inter-medium"
                            style={{ fontWeight: "bold" }}
                          >
                            {window.sys_app_translate("Program", lang) + ": "}
                          </span>
                        </div>
                        <div className="col-4">
                          <span className="inter inter-medium">
                            {application.fieldname}
                          </span>
                        </div>
                        <div className="col-2">
                          <span
                            className="inter inter-medium"
                            style={{ fontWeight: "bold" }}
                          >
                            {window.sys_app_translate("Degree", lang) + ": "}
                          </span>
                        </div>
                        <div className="col-4">
                          <span className="inter inter-medium">
                            {application.studentdegree}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-2">
                          <span
                            className="inter inter-medium"
                            style={{ fontWeight: "bold" }}
                          >
                            {window.sys_app_translate("University", lang) +
                              ": "}
                          </span>
                        </div>
                        <div className="col-4">
                          <span className="inter inter-medium">
                            {application.universityname}
                          </span>
                        </div>
                        <div className="col-2">
                          <span
                            className="inter inter-medium"
                            style={{ fontWeight: "bold" }}
                          >
                            {window.sys_app_translate("Semester", lang) + ": "}
                          </span>
                        </div>
                        <div className="col-4">
                          <span className="inter inter-medium">
                            {application.semester}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      {loading && (
        <>
          <div className="row my-2">
            <div className="col-12">
              <Skeleton variant="rect" height={44} />
              <div className="my-1"></div>
              <Skeleton variant="rect" height={90} />
            </div>
          </div>
          <div className="row my-2">
            <div className="col-12">
              <Skeleton variant="rect" height={44} />
              <div className="my-1"></div>
              <Skeleton variant="rect" height={90} />
            </div>
          </div>
          <div className="row my-2">
            <div className="col-12">
              <Skeleton variant="rect" height={44} />
              <div className="my-1"></div>
              <Skeleton variant="rect" height={90} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
