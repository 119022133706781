import React, { useState, useEffect } from "react";
import { AudioPlayer } from "react-audio-player-component";
import AppConfig from "../../../constants/AppConfig";
import { ChatMessageType } from "../../../constants/ChatMessageType";
import {
  ChatEmptyAvatar,
  ChatOptionsMenuIcon,
} from "../../../constants/SVGIcons";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import JsFileDownloader from "js-file-downloader";

export const MessageElement = ({
  type = ChatMessageType.Text,
  content,
  from,
  isFromUser = false,
  timestamp,
  attachment,
  attachmentType,
  chatPhone,
  contactPhone,
  repliedMessage,
  isThisTheRepliedMessage = false,
  isParentReplySet = false,
  isSelected = false,
  setParentReply = () => { },
  unsetParentReply = () => { },
}) => {
  let isDateSeparator = type === ChatMessageType.DateSeparator;
  const getContent = () => {
    switch (type) {
      case ChatMessageType.DateSeparator:
        return (
          <div
            style={{
              color: AppConfig.themeColors.primary,
              fontSize: "1rem",
              fontWeight: "bold",
            }}
          >
            <span
              className="px-2"
              style={{
                backgroundColor: "#F3F2F2",
                color: "#686868",
                borderRadius: "8px",
              }}
            >
              {content}
            </span>
          </div>
        );
      case ChatMessageType.Text:
      default:
        switch (attachmentType) {
          case "application/pdf":
            return (
              <>
                <p style={{ fontSize: "0.9rem" }}>{content}</p>
                <div style={{ height: "200px" }}>
                  <Worker
                    workerUrl={`https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js`}
                  >
                    <Viewer fileUrl={AppConfig.storagePath + attachment} />
                  </Worker>
                </div>
                <div className="d-flex justify-content-center my-2">
                  <Icon
                    icon={"tabler:download"}
                    color={AppConfig.themeColors.primary}
                    style={{ fontSize: "1.5rem" }}
                    type="button"
                    onClick={() => {
                      new JsFileDownloader({
                        url: AppConfig.storagePath + attachment,
                      });
                    }}
                  />
                </div>
              </>
            );
          case "image/jpeg":
          case "image/webp":
            return (
              <>
                <p style={{ fontSize: "0.9rem" }}>{content}</p>
                <div style={{ height: "5rem" }} className="my-2">
                  <img
                    src={AppConfig.storagePath + attachment}
                    style={{ height: "100%" }}
                  />
                </div>
              </>
            );
          case "audio/ogg; codecs=opus":
          case "audio/mpeg":
            return (
              <AudioPlayer
                src={AppConfig.storagePath + attachment}
                minimal={true}
                width={250}
                trackHeight={40}
                barWidth={3}
                gap={1}
                visualise={true}
                backgroundColor={"#fff"}
                barColor={AppConfig.themeColors.greyLighten}
                barPlayedColor={AppConfig.themeColors.primary}
                seekBarColor={AppConfig.themeColors.primary}
                skipDuration={2}
                showLoopOption={true}
                showVolumeControl={true}
                hideSeekBar={true}
                hideSeekKnobWhenPlaying={true}
              />
            );
        }
        return (
          <>
            <p style={{ fontSize: "0.9rem" }}>{content}</p>
          </>
        );
      case ChatMessageType.Call_Log:
        return <div
          style={{
            color: AppConfig.themeColors.primary,
            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          <span
            className="px-2"
            style={{
              backgroundColor: "#F3F2F2",
              color: "#686868",
              borderRadius: "8px",
            }}
          >
            {window.sys_app_translate("Call", "en")}
          </span>
        </div>;
      case ChatMessageType.Audio:
        return (
          <AudioPlayer
            src="https://actions.google.com/sounds/v1/alarms/digital_watch_alarm_long.ogg"
            minimal={true}
            width={250}
            trackHeight={40}
            barWidth={3}
            gap={1}
            visualise={true}
            backgroundColor={"#fff"}
            barColor={AppConfig.themeColors.greyLighten}
            barPlayedColor={AppConfig.themeColors.primary}
            seekBarColor={AppConfig.themeColors.primary}
            skipDuration={2}
            showLoopOption={true}
            showVolumeControl={true}
            hideSeekBar={true}
            hideSeekKnobWhenPlaying={true}
          />
        );
    }
  };
  return (
    <div
      className="row my-3"
      style={{
        direction: isFromUser ? "rtl" : "ltr",
      }}
    >
      {!isDateSeparator && (
        <>
          {!isThisTheRepliedMessage && (
            <div className="col-1">
              {/* <img
              style={{ width: "3rem" }}
              src={require("../../../assets/chat-sample-contact-avatar.png")}
            /> */}
              <ChatEmptyAvatar height={"3rem"} />
            </div>
          )}
          <div
            style={{
              width: "fit-content",
              color: "#686868",
              borderRadius: "10px",
              background: isThisTheRepliedMessage ? "#F3F2F2" : "white",
              position: "relative",
              boxShadow: "0px 0px 5px 0px #C9C9C9",
              border: isSelected ? "1px solid black" : "none",
              maxWidth: "70%",
            }}
          >
            <div style={{ fontSize: "1.1rem" }}>
              <div className="">
                <span
                  style={{
                    color: AppConfig.themeColors.primary,
                    fontWeight: "bold",
                  }}
                  className="inter inter-medium"
                >
                  {from}
                </span>
                <span
                  className="mx-2 inter inter"
                  style={{ color: AppConfig.themeColors.primary }}
                >
                  {isFromUser ? chatPhone : contactPhone}
                </span>
                <span
                  className={`inter inter-medium`}
                  style={{ fontSize: "0.8rem" }}
                >
                  {timestamp}
                </span>
              </div>
            </div>
            <div className="inter inter-medium">
              {repliedMessage && (
                <MessageElement
                  key={repliedMessage.id + "message"}
                  type={repliedMessage.type}
                  content={repliedMessage.messagebody}
                  from={repliedMessage.from}
                  isFromUser={repliedMessage.isfromuser}
                  attachment={repliedMessage.attachment}
                  attachmentType={repliedMessage.attachmenttype}
                  timestamp={repliedMessage.timestamp}
                  chatPhone={chatPhone}
                  repliedMessage={null}
                  isThisTheRepliedMessage={true}
                />
              )}
              {getContent()}
            </div>
          </div>
          {!isThisTheRepliedMessage && (
            <div className="col-1 d-flex align-items-center">
              <Menu
                menuButton={
                  <ChatOptionsMenuIcon
                    height={"1.8rem"}
                    className="clickable"
                  />
                }
              >
                <MenuItem
                  onClick={() => {
                    if (isParentReplySet) {
                      unsetParentReply();
                    } else {
                      setParentReply();
                    }
                  }}
                >
                  {window.sys_app_translate(
                    isSelected ? "UnReply" : "Reply",
                    "en"
                  )}
                </MenuItem>
              </Menu>
            </div>
          )}
        </>
      )}
      {isDateSeparator && (
        <div
          className="col-12 d-flex justify-content-center"
          style={{ direction: "ltr" }}
        >
          {getContent()}
        </div>
      )}
    </div>
  );
};
