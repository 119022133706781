import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  _getCheckbox,
  _getDateTimePicker,
  _getNormalInput,
  _getSelectInput,
} from "../../../libs/FormFields";
import Button from "@material-ui/core/Button";
import { AppConfig } from "../../../constants";
// import Block from "@uiw/react-color-block";

const TaskFormComponent = ({
  showMessage,
  handleAppError,
  lang,
  submit,
  isRootAgency,
  setLoading,
  record,
  errorObj,
  isUpdate,
}) => {
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: {
      name: record.name,
      color: record.color,
      icon: record.icon,
    },
  });
  const [state, setState] = useState({
    auto: record.auto,
    active: record.active,
  });
  // const [hex, setHex] = useState("#fff");
  useEffect(() => {
    if (errorObj) {
      console.log("setting errors ...");
      Object.keys(errorObj).forEach((key) => {
        setError(key, { type: "custom", message: errorObj[key][0] });
      });
    }
  }, [errorObj]);

  const onSubmit = (values) => {
    record.title = values.title;
    record.description = values.description;
    if (values.datevalue && values.datevalue !== "0") {
      record.date = values.datevalue;
    }
    console.log(record);
    submit(record);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="container-fluid"
          style={{ backgroundColor: "white", borderRadius: "25px" }}
        >
          <div className="row d-flex justify-content-center form-row">
            {_getNormalInput({
              control: control,
              inputName: "title",
              inputTitle: "Title",
              inputId: "Title",
              errors: errors,
              lang: lang,
              width: "col-12",
              inputSize: "medium",
              required: true,
              type: "text",
              preventNonLatinLetters: false,
            })}
          </div>
          <div className="row d-flex justify-content-center form-row">
            {_getNormalInput({
              control: control,
              inputName: "description",
              inputTitle: "Description",
              inputId: "Description",
              errors: errors,
              lang: lang,
              width: "col-12",
              inputSize: "medium",
              required: true,
              type: "textarea",
              preventNonLatinLetters: false,
            })}
          </div>
          <div className="row d-flex justify-content-center form-row">
            <div className="col-12">
              {_getDateTimePicker({
                defaultValue: record.date,
                setValue: setValue,
                dateTimeName: "datevalue",
                required: true,
                lang: lang,
              })}
            </div>
          </div>
          <div
            className="row d-flex justify-content-center form-row"
            style={{ marginTop: "1rem" }}
          >
            <div className="col-10 offset-1 col-sm-6 offset-sm-0 d-flex justify-content-center">
              <Button
                variant="contained"
                style={{
                  backgroundColor: AppConfig.themeColors.secondary,
                  color: "white",
                  display: "inline",
                  width: "13rem",
                }}
                className="inter inter-bold"
                type="submit"
                onClick={(e) => {}}
              >
                {window.sys_app_translate("Save", lang)}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default TaskFormComponent;
