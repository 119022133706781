import { Icon } from "@iconify/react";
import { Button } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "../../../axios";
import {
  FirebaseNotificationsBySectionKey,
  GeneralHelper,
  ITQModal,
} from "../../../libs";
import { useEffect, useState } from "react";
import { TaskFormComponent } from ".";
import { Task } from "../../../models/CRM";
import {
  AppConfig,
  PLeadsDelete,
  PLeadsModify,
  PLeadsSendRedirectionMessage,
  PSectionLeads,
  PSectionTasks,
  PTasksCreate,
} from "../../../constants";
import { database } from "../../../libs/estrylib/Firebase";

export default function LeadComponent({
  lead,
  color,
  setCurrentLead,
  props,
  refresh,
  selectedLeads,
  setSelectedLeads,
}) {
  const firebaseLeadRef = database
    .ref()
    .child(FirebaseNotificationsBySectionKey)
    .child("users")
    .child(props.user.fbid)
    .child("leads");
  const [notificationsExists, setNotificationsExists] = useState(false);
  //initialize needed vars
  const { handleAppError } = props;
  const isSelected = selectedLeads.includes(lead.id);
  const dateString = lead.created_at;

  //states
  const [state, setState] = useState({
    showTaskFormModal: false,
  });
  // Split the date and time parts
  const [datePart, timePart, ampm] = dateString.split(" ");

  // Split time into its components
  const [hours, minutes] = timePart.split(":");
  const time = `${hours}:${minutes} ${ampm}`;
  const checkboxItem = () => {
    if (selectedLeads.length <= 0) {
      return null;
    }
    if (isSelected) {
      return (
        <Icon
          icon={"ph:check-square-bold"}
          color="#3CCB00"
          role="button"
          onClick={() =>
            setSelectedLeads((arr) => arr.filter((id) => id !== lead.id))
          }
        />
      );
    } else {
      return (
        <Icon
          icon={"ph:square-bold"}
          role="button"
          onClick={() => setSelectedLeads((arr) => [...arr, lead.id])}
        />
      );
    }
  };
  const removeLead = async (lead_id) => {
    await axios
      .delete(`leads/${lead_id}`)
      .then(() => {
        refresh();
      })
      .catch((error) => {
        handleAppError(error);
      });
  };
  //add task
  const getTaskFormPopup = () => {
    const { showMessage, setLoading, handleAppError } = props;
    const { formErrors, user } = props;
    const { showTaskFormModal } = state;
    return (
      <ITQModal
        isOpen={showTaskFormModal}
        isCenterd={true}
        cancelButtonText="x"
        cancelButtonClicked={() => {
          setState({
            ...state,
            showTaskFormModal: false,
          });
        }}
        title={window.sys_app_translate("Task Info", props.user.lang)}
        bodyContent={
          <TaskFormComponent
            showMessage={showMessage}
            handleAppError={handleAppError}
            lang={user.lang}
            submit={async (record) => {
              const { setLoading, showMessage, handleAppError } = props;
              try {
                setLoading(true);
                record.leadid = lead.id;
                await axios.post(
                  "tasks/create",
                  GeneralHelper.getFormDataFromModelObject(record, false)
                );
                showMessage({
                  type: "success",
                  title: window.sys_app_translate(
                    "Successful Operation",
                    props.user.lang
                  ),
                  message: window.sys_app_translate(
                    "Task Added Successfully",
                    props.user.lang
                  ),
                });
                setState({
                  ...state,
                  showTaskFormModal: false,
                });
              } catch (error) {
                handleAppError(error);
              }
            }}
            isRootAgency={user.isRootAgency}
            setLoading={setLoading}
            record={new Task()}
            errorObj={formErrors}
          />
        }
        size="md"
      />
    );
  };
  const showTaskFormModal = () => {
    setState({
      ...state,
      showTaskFormModal: true,
    });
  };
  useEffect(() => {
    firebaseLeadRef.child(lead.id).on("value", (snapshot) => {
      const obj = snapshot.val();
      //get children count
      let count = 0;
      for (let key in obj) {
        count++;
      }
      setNotificationsExists(count > 0);
    });
    return () => {
      firebaseLeadRef.off("value");
    };
  }, []);
  return (
    <div className="lead-card">
      <div className="card-header">
        <div
          style={{ fontWeight: "500" }}
          onClick={() => {
            //open new tab is and leadid in param
            window.open(
              "http://" +
                window.location.host +
                `/app/kanban?leadid=${lead.id}`,
              "_blank"
            );
          }}
        >
          #{lead.id}
          {notificationsExists && (
            <span>
              <span className="glowing-point"></span>
              <style jsx>{`
                .glowing-point {
                  display: inline-block;
                  width: 8px;
                  height: 8px;
                  background-color: ${AppConfig.themeColors.warning};
                  border-radius: 50%;
                  box-shadow: 0 0 8px ${AppConfig.themeColors.warning};
                  margin-left: 5px;
                }
              `}</style>
            </span>
          )}
        </div>
        <div className="card-header-action">
          {props.user.permissions[PSectionLeads][
            PLeadsSendRedirectionMessage
          ] && (
            <Icon
              icon="fa6-regular:paper-plane"
              role="button"
              fontSize={"1rem"}
              onClick={() => {
                props.setLoading(true);
                props.showMessage({
                  type: "success",
                  title: window.sys_app_translate(
                    "Operation Started",
                    props.user.lang
                  ),
                  message: window.sys_app_translate(
                    "Sending redirection messages ...",
                    props.user.lang
                  ),
                });
                axios
                  .post(`leads/send-redirection-message/${lead.id}`)
                  .then((response) => {
                    props.showMessage({
                      type: "success",
                      title: window.sys_app_translate(
                        "Successful Operation",
                        props.user.lang
                      ),
                      message: window.sys_app_translate(
                        "Message sent successfully",
                        props.user.lang
                      ),
                    });
                  })
                  .catch((error) => {
                    props.handleAppError(error);
                  });
                props.setLoading(false);
              }}
            />
          )}
          {props.user.permissions[PSectionLeads][PLeadsModify] && (
            <Icon
              icon={"prime:pen-to-square"}
              role="button"
              onClick={() => {
                setCurrentLead(lead);
              }}
            />
          )}
          {props.user.permissions[PSectionLeads][PLeadsDelete] && (
            <Icon
              icon={"iconoir:xmark-circle"}
              role="button"
              onClick={() => {
                confirmAlert({
                  title: window.sys_app_translate(
                    "Confirm Deletion",
                    props.user.lang
                  ),
                  message: window.sys_app_translate(
                    "Are you sure to delete this lead?",
                    props.user.lang
                  ),
                  buttons: [
                    {
                      label: window.sys_app_translate("Yes", props.user.lang),
                      onClick: () => {
                        removeLead(lead.id);
                      },
                    },
                    {
                      label: window.sys_app_translate("No", props.user.lang),
                      onClick: () => {},
                    },
                  ],
                });
              }}
            />
          )}

          {checkboxItem()}
        </div>
      </div>
      <div
        className="card-body"
        onClick={() =>
          setSelectedLeads((arr) => {
            if (!isSelected) {
              return [...arr, lead.id];
            }
            return arr.filter((id) => id !== lead.id);
          })
        }
        onDoubleClick={() => {
          if (props.user.permissions[PSectionLeads][PLeadsModify]) {
            setCurrentLead(lead);
          }
        }}
        style={{
          borderLeftColor: color,
          borderLeftWidth: "6px",
          borderLeftStyle: "solid",
          borderRadius: "3px",
          backgroundColor: isSelected ? "#21827f83" : "unset",
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-2">
            <Icon
              icon={"ic:round-account-circle"}
              color="#C9C9C9"
              fontSize={"40px"}
              style={{ marginLeft: "-5px" }}
            />
            <span
              style={{ color: "#21827F", fontWeight: "bold" }}
              className="inter inter-medium"
            >
              {lead.quickname}
            </span>
          </div>
          <div
            style={{ backgroundColor: "#ED4C16", borderRadius: "3px" }}
            className="px-2 py-1 text-white inter inter-medium"
          >
            {lead.id}
          </div>
        </div>
        <div className="s-2">
          <Icon className="icon" icon={"mdi:phone-outline"} />
          <div className="inter inter-medium">{lead.phone}</div>
        </div>
        {lead.email && (
          <div className="s-2">
            <Icon className="icon" icon={"mdi:email-outline"} />
            <div>{lead.email}</div>
          </div>
        )}
        <div>
          <Icon
            className="icon"
            icon={"tabler:social"}
            color={AppConfig.themeColors.primary}
          />
          <span className="mx-1"></span>
          <div
            className="d-inline-flex gap-2 align-items-center inter inter-medium"
            style={{ maxWidth: "80%" }}
          >
            {lead.leadsourcename}
            {lead.phone && typeof lead.phone === "string" ? (
              <a
                href={`https://api.whatsapp.com/send?phone=${lead.phone.replace(
                  "+",
                  ""
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon={"logos:whatsapp-icon"} />
              </a>
            ) : null}
          </div>
        </div>
        <div className="s-2">
          <div className="s-2">
            <Icon className="icon" icon={"mdi:calendar-range"} />
            <div style={{ fontSize: "13px" }} className="inter inter-medium">
              {datePart}
            </div>
          </div>
          <div className="s-2">
            <Icon className="icon" icon={"mdi:clock-outline"} />
            <div style={{ fontSize: "13px" }} className="inter inter-medium">
              {time}
            </div>
          </div>
        </div>
        <div className="s-2" style={{ color: "#ED4C16" }}>
          <Icon className="icon" color="#ED4C16" icon={"la:user-tie-solid"} />
          <div style={{ fontWeight: 500 }} className="inter inter-medium">
            {lead.responsibleuser}
          </div>
        </div>
        {lead.label && (
          <div
            className="my-2 px-2 inter inter-medium label-color"
            style={{
              backgroundColor: lead.labelcolor ?? "#ABFC88",
              width: "100%",
              alignSelf: "flex-end",
              marginRight: "-10px",
              fontWeight: 500,
            }}
          >
            {lead.label}
          </div>
        )}

        {props.user.permissions[PSectionTasks][PTasksCreate] && (
          <div className="d-flex justify-content-center align-items-center">
            <Button
              variant="contained"
              className="py-1 px-4 my-1"
              style={{
                backgroundColor: AppConfig.themeColors.primary,
                color: AppConfig.themeColors.white,
              }}
              startIcon={
                <Icon icon={"mdi:checkbox-marked-circle-plus-outline"} />
              }
              onClick={(e) => {
                e.stopPropagation();
                setState({
                  ...state,
                  showTaskFormModal: true,
                });
              }}
              onDoubleClick={(e) => {}}
            >
              {window.sys_app_translate("Add Task", props.user.lang)}
            </Button>
          </div>
        )}
      </div>
      {getTaskFormPopup()}
    </div>
  );
}
