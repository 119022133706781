import React, { useState, useEffect } from "react";
import axios from "../../../axios";
import { _getNormalInput, FirebaseInternalChatMessagesKey } from "../../../libs";
import InternalChatFormComponent from "./InternalChatFormComponent";
import {
  AppConfig,
  ChatEmptyAvatar,
  InternalChatReplyIcon,
} from "../../../constants";
import moment from "moment";
import { Icon } from "@iconify/react/dist/iconify.js";
import { database } from "../../../libs/estrylib/Firebase";

export default function InternalChatComponent({
  handleAppError,
  lang,
  leadId,
  errorObj,
  scrollableHeight,
}) {

  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [replyToMessageId, setReplyToMessageId] = useState(null);
  const firebaseLeadRef = database
    .ref()
    .child(FirebaseInternalChatMessagesKey)

  // const getMessages = async () => {
  //   try {
  //     const response = await axios.get(`internal-chat-messages/get-messages`, {
  //       params: { leadid: leadId },
  //     });
  //     setMessages(response.data.data);
  //     setLoading(false);
  //   } catch (error) {
  //     handleAppError(error);
  //   }
  // };
  const getMessageElement = (
    message,
    isReplied = false,
    isSelectedToReply = false
  ) => {
    if (typeof message === "undefined") return null;
    let replyToMessageElement = null;
    if (message.replytomessageid) {
      let replyToMessageId = message.replytomessageid;
      let replyToMessage = messages.find(
        (message) => message.id === parseInt(replyToMessageId)
      );
      replyToMessageElement = getMessageElement(replyToMessage, true);
    }
    return (
      <div key={"message" + message.id} className="internal-chat-message-item">
        <div className="internal-chat-message-header">
          <div className="d-flex flex-column justify-content-between align-items-center">
            <ChatEmptyAvatar className="avatar" />
            {!isReplied && (
              <InternalChatReplyIcon
                icon="ion:arrow-undo-sharp"
                role="button"
                onClick={() => {
                  if (!replyToMessageId) {
                    setReplyToMessageId(message.id);
                  } else {
                    if (replyToMessageId === message.id) {
                      setReplyToMessageId(null);
                    }
                  }
                }}
              />
            )}
          </div>
          <div
            className="internal-chat-message-details"
            style={{
              backgroundColor: isReplied ? "#e8e8e8" : "#fff",
              border: isSelectedToReply
                ? "1px solid black"
                : "2px solid #e8e8e8",
            }}
          >
            <h4>{message.createdby}</h4>
            <span
              style={{
                position: "absolute",
                right: "1rem",
                top: "1rem",
              }}
              className="inter inter-medium"
            >
              {moment(message.created_at).format("d MMM")}
            </span>
            {replyToMessageElement && (
              <div className="my-4">{replyToMessageElement}</div>
            )}
            <p className="inter inter-medium">{message.message}</p>
          </div>
        </div>
      </div>
    );
  };
  const sendMessage = async (message) => {
    console.log("send message");
    const formData = new FormData();
    formData.append("message", message.message);
    if (message.file) {
      formData.append("file", message.file);
    }
    if (replyToMessageId) {
      formData.append("replytomessageid", replyToMessageId);
    }
    if (leadId) {
      formData.append("leadid", leadId);
    }
    try {
      await axios.post("internal-chat-messages/send-message", formData);
      //await getMessages();
    } catch (error) {
      handleAppError(error);
    }
  };
  useEffect(() => {
    //getMessages();
    firebaseLeadRef.child(leadId).on("value", (snapshot) => {
      if (snapshot.val()) {
        const result = [];

        Object.keys(snapshot.val()).forEach((key) => {
          result.push(snapshot.val()[key]);
        });
        console.log(result);
        setMessages(result);
      }
    });
  }, []);
  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          height: scrollableHeight - 50,
          overflowY: "scroll",
        }}
      >
        {!loading &&
          messages &&
          messages.length > 0 &&
          messages.map((message) => {
            return getMessageElement(
              message,
              false,
              message.id === replyToMessageId
            );
          })}
      </div>
      <InternalChatFormComponent sendMessage={sendMessage} />
    </>
  );
}
