import GeneralHelper from "../libs/GeneralHelper";

const AppConfig = {
  serverLink: window.location.origin,
  apiLink: GeneralHelper.decideDomain() + "itqanapi",
  storagePath: GeneralHelper.decideDomain() + "storage/",
  baseRoute: "eee",
  navCollapsed: false,
  darkMode: false,
  boxLayout: false,
  rtlLayout: false,
  miniSidebar: false,
  enableSidebarBackgroundImage: true,
  isDarkSidenav: true,
  enableThemeOptions: true,
  locale: {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "en",
  },
  themeColors: {
    primary: "#21827f",
    secondary: "#ED4C16",
    success: "#3CCB00",
    danger: "#FF3739",
    warning: "#FFB70F",
    info: "#00D0BD",
    dark: "#464D69",
    default: "#FAFAFA",
    greyLighten: "#A5A7B2",
    grey: "#686868",
    white: "#FFFFFF",
    purple: "#896BD6",
    yellow: "#D46B08",
    hoverGrey: "#F3F2F2",
  },
  darkThemeColors: {
    darkBgColor: "#424242",
  },
  pageTableSize: 10,
};

export default AppConfig;