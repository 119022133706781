import { NoRecordsFoundInfographic } from "../constants";
import AppConfig from "../constants/AppConfig";

export const NoRecordsFoundComponent = ({
  notFoundMessage = "No Items match your search!",
  lang,
}) => {
  return (
    <div className="row">
      <div
        className="col-12 d-flex align-items-center justify-content-center"
        style={{ minHeight: "80vh" }}
      >
        <div>
          <div className="d-flex justify-content-center mb-4">
            <NoRecordsFoundInfographic />
          </div>
          <div className="d-flex justify-content-center">
            <h3
              className="inter inter-medium"
              style={{ color: AppConfig.themeColors.primary }}
            >
              {window.sys_app_translate(notFoundMessage, lang)}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};
