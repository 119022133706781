import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  _getCheckbox,
  _getNormalInput,
  _getSelectInput,
} from "../../../libs/FormFields";
import Button from "@material-ui/core/Button";
import { AppConfig } from "../../../constants";
import ColorSelector from "../../../components/color-selector/ColorSelector";
import { ColorPalette } from "../../../components/color-selector/ColorPalette";
import IconsSelector from "../../../components/icon-selector/IconSelector";
import { IconsCollection } from "../../../components/icon-selector/IconsCollection";
// import Block from "@uiw/react-color-block";

const PhaseFormComponent = ({
  showMessage,
  handleAppError,
  lang,
  submit,
  isRootAgency,
  setLoading,
  record,
  errorObj,
  isUpdate,
}) => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: {
      name: record.name,
      color: record.color ? record.color : "#dddddd",
      // icon: record.icon,
    },
  });
  const [state, setState] = useState({
    auto: record.auto,
    active: record.active,
    color: record.color ? record.color : "#dddddd",
    icon: record.icon ? record.icon : IconsCollection[0],
  });
  useEffect(() => {
    if (errorObj) {
      console.log("setting errors ...");
      Object.keys(errorObj).forEach((key) => {
        setError(key, { type: "custom", message: errorObj[key][0] });
      });
    }
  }, [errorObj]);

  const onSubmit = (values) => {
    record.name = values.name;
    record.color = state.color;
    record.icon = state.icon;
    submit(record);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="container-fluid"
          style={{ backgroundColor: "white", borderRadius: "25px" }}
        >
          <div className="row d-flex justify-content-center form-row">
            {_getNormalInput({
              control: control,
              inputName: "name",
              inputTitle: "Name",
              inputId: "Name",
              errors: errors,
              lang: lang,
              width: "col-12",
              inputSize: "medium",
              required: true,
              type: "text",
            })}
          </div>
          <div className="row d-flex justify-content-center form-row">
            <div className="col-12">
              <IconsSelector
                selectIcon={(icon) => {
                  setState({
                    ...state,
                    icon: icon,
                  });
                }}
                iconsCollection={IconsCollection}
                icon={state.icon}
              />
            </div>
          </div>
          <div className="row d-flex justify-content-center form-row">
            <div className="col-12">
              <ColorSelector
                color={state.color}
                selectColor={(color) => {
                  setState({
                    ...state,
                    color: color,
                  });
                }}
                colorPalette={ColorPalette}
              />
            </div>
          </div>
          <div
            className="row d-flex justify-content-center form-row"
            style={{ marginTop: "1rem" }}
          >
            <div className="col-10 offset-1 col-sm-6 offset-sm-0 d-flex justify-content-center">
              <Button
                variant="contained"
                style={{
                  backgroundColor: AppConfig.themeColors.secondary,
                  color: "white",
                  display: "inline",
                  width: "13rem",
                }}
                className="inter inter-bold"
                type="submit"
                onClick={(e) => {}}
              >
                {window.sys_app_translate("Save", lang)}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default PhaseFormComponent;
