/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {
  setLoading,
  showMessage,
  setCurrentRecord,
  handleAppError,
  clearFormErrors,
} from "../../actions";
import "./index.scss";
import EditLeadComponent from "./components/EditLeadComponent";
import axios from "../../axios";
import { DropdownDataFetcher, GeneralHelper, ITQModal } from "../../libs";
import Carousel from "./components/Carousel";
import PhaseComponent from "./components/PhaseComponent";
import { Phase } from "../../models/CRM";
import {
  AppConfig,
  KanbanEventType,
  PLeadsChangeResponsibleUser,
  PSectionLeads,
  PusherConfig,
} from "../../constants";
import Pusher from "pusher-js";
import { PhaseFormComponent } from "./components";
import { Skeleton } from "@material-ui/lab";
import { Helmet } from "react-helmet";
import { Button } from "@material-ui/core";
import Select from "react-select";
import { ActionsBar, Header } from "../../components/CRM";
import LabelFormComponent from "./components/LabelFormComponent";

const Kanban = (props) => {
  const refs = Array.from({ length: 50 }, () => React.createRef());
  const refs2 = Array.from({ length: 50 }, () => React.createRef());
  const channel = useRef(null);
  const { handleAppError } = props;
  //use States
  const [state, setState] = useState({
    showPhaseFormModal: false,
    phaseRecord: null,
    isPhaseUpdate: false,
    //related to lead phase change
    showLeadsPhaseChangeModal: false,
    selectedPhaseId: null,
    label: null,
    showLeadResponsibleUserChangeModal: false,
    selectedResponsibleUserId: null,
    filters: {},
    //label form related
    showLabelFormModal: false,
    leadId: null,
    toPhaseId: null,
    newOrderId: null,
    selectedUserId: null,
  });
  const [scrollPhaseIndex, setScrollPhaseIndex] = useState(null);
  const [movement, setMovement] = useState({});
  const [searchNeedle, setSearchNeedle] = useState("");
  const [queryResponsibleUserId, setQueryResponsibleUserId] = useState(null);
  const [phaseHeight, setPhaseHeight] = useState(0);
  const [kanbanContainerHeight, setKanbanContainerHeight] = useState(0);
  const [usersForDropDown, setUsersForDropDown] = useState([]);
  const [phases, setPhases] = useState([]);
  const [currentLead, setCurrentLead] = useState();
  const [refreshCount, setRefreshCount] = useState(0);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [selectedPhaseId, setSelectedPhaseId] = useState(null);
  const [hiddenPhasesIDs, setHiddenPhasesIDs] = useState([]);
  const [phasesForDropdown, setPhasesForDropdown] = useState([]);
  const [leadSourcesForDropdown, setLeadSourcesForDropDown] = useState(null);
  const [labelsForDropdownGet, setLabelsForDropdownGet] = useState([]);
  const [leadLostLabelsForDropdownGet, setLeadLostLabelsForDropDown] =
    useState(null);
  const [dealLostLabelsForDropdownGet, setDealLostLabelsForDropDown] =
    useState(null);
  const [phaseLabelsForDropdown, setPhaseLabelsForDropdown] = useState(null);
  // Function to fetch leads by phase
  const fetchPhases = async () => {
    console.log("filtering ...");
    try {
      setPhases([]);
      const response = await axios.get("leads/get-phases-leads", {
        params: {
          responsibleuserid: queryResponsibleUserId,
          searchneedle: searchNeedle,
          filters: state.filters,
        },
      });
      setPhases((prev) => response.data.data);
      setTimeout(() => {
        calculateFixedSectionWidth();
      }, 100);
    } catch (error) {
      handleAppError(error);
    }
  };
  const refresh = () => {
    setRefreshCount((val) => val + 1);
  };
  const calculateScrollHeight = () => {
    console.log("calculating kanban scroll height ...");
    console.log("window.innerHeight", window.innerHeight);
    const pageHeaderElement = document.getElementsByClassName("header")[0];
    const kanbanHeader1Element =
      document.getElementsByClassName("kanban-header-1")[0];
    const kanbanHeader2Element =
      document.getElementsByClassName("kanban-header-2")[0];
    let totalPageHeaderHeight = 0;
    if (pageHeaderElement) {
      const pageHeaderStyles = window.getComputedStyle(pageHeaderElement);
      const pageHeaderHeight = pageHeaderElement.clientHeight;
      totalPageHeaderHeight = pageHeaderHeight;
      console.log("totalPageHeaderHeight", totalPageHeaderHeight);
    }
    let totalKanbanHeader1Height = 0;
    if (kanbanHeader1Element) {
      const kanbanHeader1Styles = window.getComputedStyle(kanbanHeader1Element);
      const kanbanHeader1Height = kanbanHeader1Element.clientHeight;
      const kanbanHeader1MarginTop = parseInt(
        kanbanHeader1Styles.paddingTop,
        0
      );
      console.log("kanbanHeader1Element", kanbanHeader1Height);
      const kanbanHeader1MarginBottom = parseInt(
        kanbanHeader1Styles.paddingBottom,
        0
      );
      totalKanbanHeader1Height =
        kanbanHeader1Height +
        kanbanHeader1MarginTop +
        kanbanHeader1MarginBottom;
      console.log("totalKanbanHeader1Height", totalKanbanHeader1Height);
    }
    let totalKanbanHeader2Height = 0;
    if (kanbanHeader2Element) {
      const kanbanHeader2Styles = window.getComputedStyle(kanbanHeader2Element);
      const kanbanHeader2Height = kanbanHeader2Element.clientHeight;
      const kanbanHeader2MarginTop = parseInt(kanbanHeader2Styles.marginTop, 0);
      const kanbanHeader2MarginBottom = parseInt(
        kanbanHeader2Styles.marginBottom,
        0
      );
      totalKanbanHeader2Height =
        kanbanHeader2Height +
        kanbanHeader2MarginTop +
        kanbanHeader2MarginBottom;
      console.log("totalKanbanHeader2Height", totalKanbanHeader2Height);
    }
    setPhaseHeight(
      window.innerHeight -
        totalPageHeaderHeight -
        totalKanbanHeader1Height -
        totalKanbanHeader2Height -
        1
    );
    setKanbanContainerHeight(window.innerHeight - totalPageHeaderHeight);
  };
  const calculateFixedSectionWidth = () => {
    const phaseHeader = document.querySelector(".phase");
    const fixedSections = document.getElementsByClassName("fixed-sec");
    for (let i = 0; i < fixedSections.length; i++) {
      fixedSections[i].style.width = phaseHeader.offsetWidth + "px";
    }
  };
  //useEffects
  useEffect(() => {
    fetchPhases();
  }, [refreshCount]);
  useEffect(() => {
    refresh();
  }, [queryResponsibleUserId, searchNeedle, state.filters]);
  useEffect(() => {
    if (
      leadLostLabelsForDropdownGet &&
      dealLostLabelsForDropdownGet &&
      labelsForDropdownGet &&
      phasesForDropdown &&
      usersForDropDown &&
      leadSourcesForDropdown
    ) {
      calculateScrollHeight();
    }
  }, [
    leadLostLabelsForDropdownGet,
    dealLostLabelsForDropdownGet,
    labelsForDropdownGet,
    leadSourcesForDropdown,
    phasesForDropdown,
    usersForDropDown,
  ]);
  const getQueryParam = (param) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  };
  useEffect(() => {
    const leadId = getQueryParam("leadid");
    if (leadId) {
      //fetch lead by id
      axios
        .get("leads/get-lead/" + leadId)
        .then((response) => {
          setCurrentLead(response.data.data);
        })
        .catch((error) => {});
    }
    const pusher = new Pusher(PusherConfig.key, {
      cluster: PusherConfig.cluster,
    });
    pusher.connection.bind("connected", () => console.log("connected"));
    pusher.connection.bind("connecting", () => console.log("connecting"));
    pusher.connection.bind("connecting_in", () => console.log("connecting_in"));
    pusher.connection.bind("disconnected", () => console.log("disconnected"));
    pusher.connection.bind("unavailable", () => console.log("unavailable"));
    pusher.connection.bind("error", (err) => console.log({ err }, "error"));
    pusher.connection.bind("failed", (err) => console.log({ err }, "failed"));
    channel.current = pusher.subscribe(
      PusherConfig.channels.kanbanChannel.name
    );
    channel.current.bind(
      PusherConfig.channels.kanbanChannel.eventName,
      (data) => {
        //Update messages state with new message
        console.log("new event received");
        switch (data.message.type) {
          case KanbanEventType.PhaseNew:
            refresh();
            break;
          case KanbanEventType.PhaseDeleted:
            const deletedPhaseId = data.message.relatedObject.id;
            setPhases((prev) =>
              prev.filter((phase) => phase.id !== deletedPhaseId)
            );
            break;
        }
      }
    );

    DropdownDataFetcher.getUsersForDropdown(props).then((data) => {
      setUsersForDropDown(data);
    });
    DropdownDataFetcher.getPhasesForDropdown(props).then((data) => {
      setPhasesForDropdown(data);
    });
    DropdownDataFetcher.getLeadSourcesForDropdown(props).then((data) => {
      setLeadSourcesForDropDown(data);
    });
    DropdownDataFetcher.getPhasesLabelsForDropdown(props).then((data) => {
      setLabelsForDropdownGet(data.get);
    });
    DropdownDataFetcher.getPhasesLabelsForDropdown(props, 15).then((data) => {
      setLeadLostLabelsForDropDown(data.get);
    });
    DropdownDataFetcher.getPhasesLabelsForDropdown(props, 16).then((data) => {
      setDealLostLabelsForDropDown(data.get);
    });
    if (
      document.readyState === "complete" ||
      document.readyState === "interactive"
    ) {
      console.log("Kanban: document is already loaded ...");
      calculateScrollHeight();
      window.addEventListener("resize", calculateScrollHeight);
    } else {
      console.log(
        "Kanban: document is not loaded yet ... adding 'DOMContentLoaded' event listener ..."
      );
      document.addEventListener("DOMContentLoaded", function () {
        calculateScrollHeight();
        window.addEventListener("resize", calculateScrollHeight);
      });
    }
    window.addEventListener("resize", calculateFixedSectionWidth);
    return () => {
      console.log("unloading kanban component ...");
      console.log("unsubscribing from pusher ...");
      channel.current.unbind_all();
      channel.current.unsubscribe();
      console.log("removing event listeners ...");
      window.removeEventListener("resize", calculateScrollHeight);
      window.removeEventListener("DOMContentLoaded", calculateScrollHeight);
    };
  }, []);
  const updateLead = async (
    lead_id,
    newPhaseId,
    newOrderNo,
    label = null,
    note = null,
    responsibleUserId = null
  ) => {
    console.log("updating lead phase ...");
    const { handleAppError } = props;
    try {
      await axios.post(
        `leads/change-phase/${lead_id}`,
        GeneralHelper.getFormDataFromModelObject(
          {
            phaseid: newPhaseId,
            orderno: newOrderNo,
            label: label,
            note: note,
            responsibleuserid: responsibleUserId,
          },
          false
        )
      );
    } catch (error) {
      handleAppError(error);
    }
  };
  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result; // draggableId is the lead id

    // If no destination, exit the function
    if (!destination) {
      return;
    }

    // Reorganize leads after moving from one phase to another
    const sourceStageIndex = phases.findIndex(
      (phase) => `phase-${phase.id}` === source.droppableId
    );
    const destinationStageIndex = phases.findIndex(
      (phase) => `phase-${phase.id}` === destination.droppableId
    );

    const sourceStage = phases[sourceStageIndex];
    const destinationStage = phases[destinationStageIndex];

    // If the lead is dropped in the same position, exit the function
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }
    setMovement({
      from: {
        phaseid: sourceStage.id,
        leadid: draggableId,
      },
      to: {
        phaseid: destinationStage.id,
        index: destination.index,
        leadid: draggableId,
      },
    });
    // updateLead(draggableId, destinationStage.id, destination.index);
    setState({
      ...state,
      showLabelFormModal: true,
      leadId: draggableId,
      toPhaseId: destinationStage.id,
      newOrderId: destination.index,
    });
  };
  useEffect(() => {
    try {
      const fromPhaseIndex = phases.findIndex(
        (phase) => phase.id === movement.from.phaseid
      );
      const toPhaseIndex = phases.findIndex(
        (phase) => phase.id === movement.to.phaseid
      );
      if (refs[fromPhaseIndex] && refs[toPhaseIndex]) {
        refs[fromPhaseIndex].current.click();
        refs[toPhaseIndex].current.click();
      }
    } catch (e) {
      console.log("error in movement effect", e);
    }
  }, [movement]);
  useEffect(() => {
    console.log("scrollPhaseIndex", scrollPhaseIndex);
    if (scrollPhaseIndex !== null && scrollPhaseIndex !== -1) {
      console.log("clicking refs 2 ...");
      refs2[scrollPhaseIndex].current.click();
      setScrollPhaseIndex(-1);
    }
  }, [scrollPhaseIndex]);
  //add phase related
  const getPhaseFormPopup = () => {
    const { showMessage, setLoading, handleAppError, formErrors, user } = props;
    const { showPhaseFormModal } = state;
    return (
      <ITQModal
        isOpen={showPhaseFormModal}
        isCenterd={true}
        cancelButtonText="x"
        cancelButtonClicked={() => {
          setState({
            ...state,
            showPhaseFormModal: false,
          });
        }}
        title={window.sys_app_translate("Phase Info", user.lang)}
        bodyContent={
          <PhaseFormComponent
            showMessage={showMessage}
            handleAppError={handleAppError}
            lang={user.lang}
            submit={state.isPhaseUpdate ? updatePhase : addPhase}
            isRootAgency={user.isRootAgency}
            setLoading={setLoading}
            record={state.phaseRecord}
            errorObj={formErrors}
            isUpdate={state.isPhaseUpdate}
          />
        }
        size="md"
      />
    );
  };
  const getLabelFormPopup = () => {
    const { showMessage, setLoading, handleAppError, formErrors, user } = props;
    const { showLabelFormModal, leadId, fromPhaseId, toPhaseId, newOrderId } =
      state;
    const { lang } = user;
    return (
      <ITQModal
        isOpen={showLabelFormModal}
        isCenterd={true}
        title={window.sys_app_translate("Please set a Label", user.lang)}
        cancelButtonText="x"
        cancelButtonClicked={() => {
          setState({
            ...state,
            showLabelFormModal: false,
          });
        }}
        bodyContent={
          <LabelFormComponent
            showMessage={showMessage}
            handleAppError={handleAppError}
            lang={lang}
            submit={(label, note, responsibleUserId) => {
              updateLead(
                leadId,
                toPhaseId,
                newOrderId,
                label,
                note,
                responsibleUserId
              );
              setState({
                ...state,
                showLabelFormModal: false,
              });
            }}
            errorObj={formErrors}
            leadId={leadId}
            toPhaseId={toPhaseId}
            props={props}
            usersForDropdown={usersForDropDown.get}
            permissions={props.user.permissions}
          />
        }
        size="md"
      />
    );
  };
  const addPhase = async (record) => {
    const { setLoading, showMessage, handleAppError } = props;
    try {
      setLoading(true);
      const response = await axios.post(
        "phases/create",
        GeneralHelper.getFormDataFromModelObject(record, false)
      );
      showMessage({
        type: "success",
        title: window.sys_app_translate(
          "Phase Added Successfully",
          props.user.lang
        ),
        message: window.sys_app_translate(
          "Phase Added Successfully",
          props.user.lang
        ),
      });
      setState({
        ...state,
        showPhaseFormModal: false,
      });
    } catch (error) {
      handleAppError(error);
    }
  };
  const updatePhase = async (record) => {
    const { setLoading, showMessage, handleAppError } = props;
    try {
      setLoading(true);
      const response = await axios.post(
        `phases/${record.id}`,
        GeneralHelper.getFormDataFromModelObject(record, true)
      );
      showMessage({
        type: "success",
        title: window.sys_app_translate(
          "Successfull Operation",
          props.user.lang
        ),
        message: window.sys_app_translate(
          "Phase Updated Successfully",
          props.user.lang
        ),
      });
      setState({
        ...state,
        showPhaseFormModal: false,
      });
    } catch (error) {
      handleAppError(error);
    }
  };
  const getLeadPhaseChangeModal = () => {
    const { showLeadsPhaseChangeModal } = state,
      { lang, permissions } = props.user;
    return (
      showLeadsPhaseChangeModal && (
        <ITQModal
          isOpen={showLeadsPhaseChangeModal}
          isCenterd={true}
          size="small"
          cancelButtonText="x"
          cancelButtonClicked={async () => {
            setState({
              ...state,
              showLeadsPhaseChangeModal: false,
            });
          }}
          title={window.sys_app_translate("Change Selected Leads Phase", lang)}
          bodyContent={
            <div className="row d-flex justify-content-center">
              <div className="col-11">
                <div className="container-fluid">
                  <div
                    className="row justify-content-center"
                    style={{ marginBottom: "1rem" }}
                  >
                    <div className="col-12">
                      <Select
                        className="itqan-react-select-field itqan-react-select-field-top-margin"
                        options={phasesForDropdown.get}
                        placeholder={window.sys_app_translate("To Phase", lang)}
                        onChange={(e) => {
                          setState({
                            ...state,
                            selectedPhaseId: e.value,
                          });
                          DropdownDataFetcher.getPhasesLabelsForDropdown(
                            props,
                            e.value
                          ).then((data) => {
                            setPhaseLabelsForDropdown(data);
                          });
                        }}
                      />
                    </div>
                  </div>
                  {permissions[PSectionLeads][PLeadsChangeResponsibleUser] && (
                    <div
                      className="row justify-content-center"
                      style={{ marginBottom: "1rem" }}
                    >
                      <div className="col-12">
                        <Select
                          className="itqan-react-select-field itqan-react-select-field-top-margin"
                          options={usersForDropDown.get}
                          placeholder={window.sys_app_translate(
                            "Responsible User ID",
                            lang
                          )}
                          onChange={(e) => {
                            setState({
                              ...state,
                              selectedResponsibleUserId: e.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {phaseLabelsForDropdown && (
                    <div
                      className="row justify-content-center"
                      style={{ marginBottom: "1rem" }}
                    >
                      <div className="col-12">
                        <Select
                          className="itqan-react-select-field itqan-react-select-field-top-margin"
                          options={phaseLabelsForDropdown.get}
                          placeholder={window.sys_app_translate("Label", lang)}
                          onChange={(e) => {
                            setState({
                              ...state,
                              label: e.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div className="row my-1">
                    <div className="col-12 d-flex align-content-center justify-content-center">
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: AppConfig.themeColors.secondary,
                          color: "white",
                          display: "inline",
                          width: "13rem",
                        }}
                        className="inter inter-bold"
                        type="submit"
                        onClick={(e) => {
                          changeSelectedLeadsPhase();
                        }}
                      >
                        {window.sys_app_translate("Submit", lang)}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      )
    );
  };
  const getLeadResponsibleUserChangeModal = () => {
    const { showLeadResponsibleUserChangeModal } = state,
      { lang } = props.user;
    return (
      showLeadResponsibleUserChangeModal && (
        <ITQModal
          isOpen={showLeadResponsibleUserChangeModal}
          isCenterd={true}
          size="small"
          cancelButtonText="x"
          cancelButtonClicked={async () => {
            setState({
              ...state,
              showLeadResponsibleUserChangeModal: false,
            });
          }}
          title={window.sys_app_translate(
            "Change Selected Leads Responsible User",
            lang
          )}
          bodyContent={
            <div className="row d-flex justify-content-center">
              <div className="col-11">
                <div className="container-fluid">
                  <div
                    className="row justify-content-center"
                    style={{ marginBottom: "1rem" }}
                  >
                    <div className="col-12">
                      <Select
                        className="itqan-react-select-field itqan-react-select-field-top-margin"
                        options={usersForDropDown.get}
                        placeholder={window.sys_app_translate(
                          "Responsible User",
                          lang
                        )}
                        onChange={(e) => {
                          setState({
                            ...state,
                            selectedResponsibleUserId: e.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row my-1">
                    <div className="col-12 d-flex align-content-center justify-content-center">
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: AppConfig.themeColors.secondary,
                          color: "white",
                          display: "inline",
                          width: "13rem",
                        }}
                        className="inter inter-bold"
                        type="submit"
                        onClick={async (e) => {
                          await changeSelectedLeadsResponsibleUserId();
                        }}
                      >
                        {window.sys_app_translate("Submit", lang)}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      )
    );
  };
  const changeSelectedLeadsPhase = async () => {
    const { showMessage, setLoading, handleAppError } = props;
    const { lang } = props.user;
    try {
      if (!state.label) {
        showMessage({
          type: "error",
          title: window.sys_app_translate("Warning", lang),
          message: window.sys_app_translate("Please select a label", lang),
        });
        return;
      }
      setLoading(true);
      await axios.post("leads/bulk-change-phase", {
        phaseid: state.selectedPhaseId,
        leadsids: selectedLeads.join(","),
        label: state.label,
        responsibleuserid: state.selectedResponsibleUserId,
      });
      showMessage({
        type: "success",
        title: window.sys_app_translate("Successfull Operation", lang),
        message: window.sys_app_translate(
          "Leads Phase Changed Successfully",
          lang
        ),
      });
      setState({
        ...state,
        showLeadsPhaseChangeModal: false,
      });
      setLoading(false);
    } catch (e) {
      handleAppError(e);
    }
  };
  const changeSelectedLeadsResponsibleUserId = async () => {
    const { showMessage, setLoading, handleAppError } = props;
    const { lang } = props.user;
    try {
      setLoading(true);
      await axios.post("leads/bulk-change-responsible-user", {
        phaseid: state.selectedResponsibleUserId,
        leadsids: selectedLeads.join(","),
        selectedresponsibleuserid: state.selectedResponsibleUserId,
      });
      showMessage({
        type: "success",
        title: window.sys_app_translate("Successfull Operation", lang),
        message: window.sys_app_translate(
          "Leads Responsible User Changed Successfully",
          lang
        ),
      });
      setState({
        ...state,
        showLeadResponsibleUserChangeModal: false,
      });
      refresh();
      setLoading(false);
    } catch (e) {
      handleAppError(e);
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{window.sys_app_translate("Kanban", props.user.lang)}</title>
      </Helmet>
      <div
        className="kanban-con"
        style={{ height: kanbanContainerHeight + "px" }}
      >
        {usersForDropDown &&
          labelsForDropdownGet &&
          leadLostLabelsForDropdownGet &&
          dealLostLabelsForDropdownGet &&
          leadSourcesForDropdown && (
            <Header
              props={props}
              usersForDropDown={usersForDropDown.get}
              phases={phases}
              setQueryResponsibleUserId={setQueryResponsibleUserId}
              setSearchNeedle={setSearchNeedle}
              isSuperAdmin={props.user.is_super_admin}
              setFilters={(filters) => {
                setState({
                  ...state,
                  filters: filters,
                });
              }}
              filters={state.filters}
              leadSourcesForDropdown={leadSourcesForDropdown}
              labelsForDropdownGet={labelsForDropdownGet}
              leadLostLabelsForDropdownGet={leadLostLabelsForDropdownGet}
              dealLostLabelsForDropdownGet={dealLostLabelsForDropdownGet}
            />
          )}
        <ActionsBar
          selectedLeads={selectedLeads}
          setSelectedLeads={setSelectedLeads}
          refresh={refresh}
          props={props}
          changeLeadsPhase={() => {
            setState({
              ...state,
              showLeadsPhaseChangeModal: true,
            });
          }}
          changeResponsibleUser={() => {
            setState({
              ...state,
              showLeadResponsibleUserChangeModal: true,
            });
          }}
          activeTabId={1}
        />
        {phases.length === 0 && (
          <Carousel>
            {[1, 2, 3, 4, 5, 6, 7].map((phase, index) => (
              <Skeleton
                key={index}
                variant="rect"
                className="phase"
                style={{ height: phaseHeight + "px" }}
              />
            ))}
          </Carousel>
        )}
        {phases.length > 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Carousel>
              {phases &&
                phases.length > 0 &&
                phases.map((phase) => {
                  if (hiddenPhasesIDs.includes(phase.id)) {
                    //return empty div to force phase width
                    return <div></div>;
                  }
                  return (
                    <Droppable
                      key={phase.id + "phase"}
                      droppableId={`phase-${phase.id}`}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={{ height: phaseHeight + "px" }}
                          className="phase"
                          onScroll={(e) => {
                            console.log("scrolling ...");
                            const div = e.target;
                            if (
                              div.scrollTop + div.clientHeight >=
                              div.scrollHeight - 20
                            ) {
                              console.log("scrolling to bottom");
                              setScrollPhaseIndex(phases.indexOf(phase));
                            }
                          }}
                        >
                          <PhaseComponent
                            key={phase.id + "phasecomponent"}
                            addPhase={(phase) => {
                              console.log("opening phase form");
                              setState({
                                ...state,
                                showPhaseFormModal: true,
                                phaseRecord: new Phase(),
                                isPhaseUpdate: false,
                              });
                            }}
                            updatePhase={() => {
                              setState({
                                ...state,
                                showPhaseFormModal: true,
                                phaseRecord: phase,
                                isPhaseUpdate: true,
                              });
                            }}
                            props={props}
                            phase={phase}
                            refresh={refresh}
                            setCurrentLead={setCurrentLead}
                            selectedLeads={selectedLeads}
                            setSelectedLeads={setSelectedLeads}
                            responsibleUserId={queryResponsibleUserId}
                            channel={channel}
                            leadsCount={phase.leadscount}
                            hiddenRef={refs[phases.indexOf(phase)]}
                            hiddenRef2={refs2[phases.indexOf(phase)]}
                            movement={movement}
                            showOnlyThis={() => {
                              const hiddenPhasesIDs = [];
                              phases.forEach((p) => {
                                if (p.id !== phase.id) {
                                  hiddenPhasesIDs.push(p.id);
                                }
                              });
                              setHiddenPhasesIDs(hiddenPhasesIDs);
                              setTimeout(() => {
                                calculateFixedSectionWidth();
                              }, 300);
                            }}
                            hideThis={() => {
                              setHiddenPhasesIDs([
                                ...hiddenPhasesIDs,
                                phase.id,
                              ]);
                              setTimeout(() => {
                                calculateFixedSectionWidth();
                              }, 300);
                            }}
                            showAll={() => {
                              setHiddenPhasesIDs([]);
                              setTimeout(() => {
                                calculateFixedSectionWidth();
                              }, 300);
                            }}
                            selectedPhaseId={selectedPhaseId}
                            filters={state.filters}
                          />
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  );
                })}
            </Carousel>
          </DragDropContext>
        )}
        {currentLead && (
          <EditLeadComponent
            currentLead={currentLead}
            setCurrentLead={setCurrentLead}
            phases={phases}
            props={props}
            errorObj={props.formErrors}
            handleAppError={props.handleAppError}
            setLoading={props.setLoading}
            showMessage={props.showMessage}
            lang={props.user.lang}
            isRootAgency={props.user.isRootAgency}
            usersForDropDown={usersForDropDown}
            setCurrentRecord={props.setCurrentRecord}
          />
        )}
        {getPhaseFormPopup()}
        {getLeadPhaseChangeModal()}
        {getLabelFormPopup()}
        {getLeadResponsibleUserChangeModal()}
      </div>
    </>
  );
};

const mapStateToProps = ({ authUser, appReducer }) => {
  const { user } = authUser;
  const { formErrors, currentRecord, loading } = appReducer;
  return { user, formErrors, currentRecord, loading };
};

export default connect(mapStateToProps, {
  setCurrentRecord,
  setLoading,
  showMessage,
  handleAppError,
  clearFormErrors,
})(Kanban);
