import { useState } from "react";

export const ColorPalette = [
  // Primary shades
  "#E3F2FD",
  "#90CAF9",
  "#42A5F5",
  "#1E88E5",
  "#1565C0",
  // Secondary shades
  "#FCE4EC",
  "#F8BBD0",
  "#F48FB1",
  "#EC407A",
  "#C2185B",
  // Success shades
  "#E8F5E9",
  "#A5D6A7",
  "#66BB6A",
  "#43A047",
  "#2E7D32",
  // Warning shades
  "#FFFDE7",
  "#FFF59D",
  "#FFEB3B",
  "#FDD835",
  "#F57F17",
  // Danger shades
  "#FFEBEE",
  "#EF9A9A",
  "#E57373",
  "#EF5350",
  "#C62828",
  // Info shades
  "#E0F7FA",
  "#80DEEA",
  "#4DD0E1",
  "#26C6DA",
  "#00838F",
  // Neutral shades
  "#FAFAFA",
  "#E0E0E0",
  "#BDBDBD",
  "#616161",
  "#212121",
  // Accent shades
  "#F3E5F5",
  "#CE93D8",
  "#AB47BC",
  "#8E24AA",
  "#6A1B9A",
];
